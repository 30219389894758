import React from "react";
import { Container, Box, Text, VStack, Button, Link } from "@chakra-ui/react";
import { CheckIcon } from "@chakra-ui/icons";
import { FiCheckCircle } from "react-icons/fi";
import { useNavigate } from "react-router-dom";

function Successful() {
  const nav = useNavigate();
  return (
    <>
      <Container my="20">
        <Box
          p="40px 30px 20px 30px"
          style={{ boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.2)" }}
        >
          <VStack textAlign="center">
            <FiCheckCircle color="#468c32" fontSize="80px" py="20px" />
            <Text fontSize="30px">Congratulations</Text>
            <Text fontSize="20px" color="gray" pb="20px">
              You have successfully registered! Thankyou for your participation.
            </Text>
            <Box
              w="150px"
              p="5px"
              color="white"
              bgColor="#468c32"
              borderRadius="10px"
            >
              <Link href="/">Go to Home</Link>
            </Box>
          </VStack>
        </Box>
      </Container>
    </>
    // <Container my="20">
    //   <Box>
    //     <div
    //       className="payment"
    //       style={{ boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.2)" }}
    //     >
    //       <div className="payment_header">
    //         <div className="check">
    //           <CheckIcon
    // w={6}
    // h={6}
    // color="#468c32"
    // style={{
    //   marginTop: "10px",
    //   verticalAlign: "middle",
    //   lineHeight: "50px",
    //   fontSize: "30px",
    // }}
    //           />
    //         </div>
    //       </div>
    //       <div className="content">
    //         <h1>Payment Success !</h1>
    //         <p style={{ paddingLeft: "40px", paddingRight: "40px" }}>
    //           You have successfully registered!
    //         </p>
    //         <br />
    //         <a href="/client/course">Go to Home</a>
    //       </div>
    //     </div>
    //   </Box>
    // </Container>
  );
}

export { Successful };
