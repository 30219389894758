import {
  Box,
  Container,
  Center,
  Text,
  Heading,
  Button,
  UnorderedList,
  ListItem,
  Stack,
  useDisclosure,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerCloseButton,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CRUDFunction from "../../../functions/CRUDFunction";
import { format } from "date-fns";
import { URL_LOCAL, URL_STAGING } from "../../../constant";

export default function Details() {
  const { training } = useParams();
  const { state } = useLocation();
  const nav = useNavigate();
  const drawerDetail = useDisclosure();
  const trainingModes = [
    "Full Time Online Inhouse",
    "Full Time Physical Inhouse",
    "Part Time Online Inhouse",
    "Part Time Physical Inhouse",
    "Full Time Online",
    "Full Time Physical",
    "Part Time Online",
    "Part Time Physical",
  ];
  const [course, setCourse] = useState([
    {
      course_about: "",
      course_eligibility: "",
      course_id: "",
      course_image: "",
      course_name: "",
      course_outline: "",
      trainings: [],
    },
  ]);
  const [trainingDetails, setTrainingDetails] = useState([]);
  const [session, setSession] = useState([]);

  const checkID = (training) => {
    return new Promise((resolve, reject) => {
      let temp;
      if (state === null) {
        if (training === "pche") {
          temp = 1;
        } else if (training === "pciha") {
          temp = 2;
        } else if (training === "awareness") {
          temp = 3;
        } else if (training === "mpphm") {
          temp = 4;
        } else if (training === "competency") {
          temp = 5;
        } else if (training === "pematuhan") {
          temp = 6;
        } else if (training === "mhms") {
          temp = 7;
        } else if (training === "halaloem") {
          temp = 8;
        } else if (training === "publiccompetency") {
          temp = 9;
        }
      } else {
        temp = state.id;
      }
      resolve(temp);
    });
  };

  useEffect(() => {
    fetchCourse();
  }, []);

  const fetchCourse = async () => {
    const TID = await checkID(training);
    if (TID) {
      const res = await CRUDFunction.get(`${URL_STAGING}coursedetails/${TID}`);
      if (res) {
        let trainings = [];
        if (res.length > 0) {
          if (res[0].training_id != null) {
            res.map(
              (i) =>
                new Date(JSON.parse(i.training_date)[0].startDate).getTime() >
                  new Date().getTime() &&
                trainings.push({
                  training_id: i.training_id,
                  training_name: i.training_name,
                  training_date: JSON.parse(i.training_date),
                  training_detail: i.training_detail,
                  training_fee: i.training_fee,
                  training_place: i.training_place,
                  training_mode: i.training_mode,
                })
            );
          }
          setCourse({
            course_about: res[0].course_about,
            course_id: res[0].course_id,
            course_image: res[0].course_image,
            course_name: res[0].course_name.toUpperCase(),
            course_outline: res[0].course_outline,
            trainings: trainings,
          });
        } else {
          const TID = await checkID(training);
          if (TID) {
            const res = await CRUDFunction.get(`${URL_STAGING}course/${TID}`);
            if (res) {
              setCourse({
                course_name: res.course_name.toUpperCase(),
                course_about: res.course_about,
                course_outline: res.course_outline,
              });
            }
          }
        }
      }
    }
  };

  return (
    <>
      <Container
        display="grid"
        maxW={{ base: "90%", md: "85%", lg: "80%" }}
        my={{ base: "20%", md: "10%", lg: "0" }}
        px={0}
        bg="white"
        boxShadow="lg"
        borderRadius={10}
      >
        <Center w="100%" bg="#4B85FA" color="white" borderTopRadius={10}>
          <Heading
            p={{ base: 4, md: 10 }}
            fontSize={{ base: "18px", md: "36px" }}
            textAlign={{ base: "center" }}
            letterSpacing={2}
          >
            {course.course_name}
          </Heading>
        </Center>
        <Box
          w="90%"
          h="auto"
          my={"30px"}
          mx={"auto"}
          px={4}
          textAlign="justify"
          // overflowY={{ md: "scroll" }}
        >
          <Box id="courseAbout">
            <Heading size="lg">About Course</Heading>
            <Text pt="3">
              <div dangerouslySetInnerHTML={{ __html: course.course_about }} />
            </Text>
          </Box>
          <Box mt="5" id="courseOutlines">
            <Heading size="lg">Course Outlines</Heading>
            <Text pt="3">
              <div
                dangerouslySetInnerHTML={{ __html: course.course_outline }}
              />
            </Text>
          </Box>

          <Heading mt="5" size="lg">
            Training Details
          </Heading>
          {course.trainings ? (
            course.trainings.length > 0 ? (
              <Box>
                <Box>
                  <Text py="5">
                    Click to view training details based on sessions:
                  </Text>
                  {course.trainings &&
                    course.trainings.map((item) =>
                      new Date(item.training_date[0].startDate).getTime() >
                      new Date().getTime() ? (
                        <>
                          <Button
                            ml="40px"
                            w="300px"
                            p="35px"
                            m="2"
                            bg="blue.100"
                            _hover={{ bgColor: "blue.200" }}
                            onClick={async () => {
                              const res = await CRUDFunction.get(
                                `${URL_STAGING}training/${item.training_id}`
                              );
                              setTrainingDetails(res);
                              setSession(item.training_date);
                              drawerDetail.onOpen();
                            }}
                          >
                            {item.training_date[0].startDate !=
                            item.training_date[0].endDate ? (
                              <Text fontWeight="bold" letterSpacing="1px">
                                Start Date:{" "}
                                {format(
                                  new Date(item.training_date[0].startDate),
                                  "dd LLLL y"
                                )}
                                <br />
                                End Date:{" "}
                                {format(
                                  new Date(
                                    item.training_date[
                                      item.training_date.length - 1
                                    ].endDate
                                  ),
                                  "dd LLLL y"
                                )}
                              </Text>
                            ) : (
                              <Text fontWeight="bold" letterSpacing="1px">
                                One Day:
                                <br />
                                {format(
                                  new Date(item.training_date[0].startDate),
                                  "dd LLLL y"
                                )}
                              </Text>
                            )}
                          </Button>
                        </>
                      ) : (
                        ""
                      )
                    )}
                </Box>
                <Stack
                  direction={{ base: "column", md: "row" }}
                  mt={5}
                  float={{ base: "center", md: "right" }}
                >
                  <Text my="auto">Enroll as:</Text>
                  <Button
                    px={20}
                    bg="#F9C611"
                    boxShadow="md"
                    onClick={() => {
                      nav("../enroll/individu", {
                        replace: false,
                        state: { id: state.id },
                      });
                    }}
                  >
                    Individual
                  </Button>
                  <Button
                    px={20}
                    bg="#F9C611"
                    boxShadow="md"
                    onClick={() => {
                      nav("../enroll/company", {
                        replace: false,
                        state: { id: state.id },
                      });
                    }}
                  >
                    Company
                  </Button>
                </Stack>
              </Box>
            ) : (
              <Text py="5">No upcoming trainings available.</Text>
            )
          ) : (
            <Text py="5">
              No trainings registered currently for this course.
            </Text>
          )}
        </Box>
      </Container>

      <Drawer
        isOpen={drawerDetail.isOpen}
        placement="right"
        onClose={drawerDetail.onClose}
        size="lg"
        fontSize="14px"
      >
        <DrawerOverlay />
        <DrawerContent overflowY="auto">
          <DrawerCloseButton />
          <DrawerHeader textAlign="center" pt="7%" fontSize="25px">
            TRAINING DETAILS
          </DrawerHeader>
          <DrawerBody>
            <Box mb="50px" borderWidth="0px 0px 3px 0px" borderColor="gray.500">
              <b>TRAINING NAME</b>
              <br />
              <br />
              {course.trainings && (
                <Text pl="30px">{trainingDetails.training_name}</Text>
              )}
              <br />
            </Box>
            <Box mb="50px" borderWidth="0px 0px 3px 0px" borderColor="gray.500">
              <b>TRAINING DATE</b>
              <br />
              <br />
              {course.trainings &&
                session.map((item, index) => (
                  <>
                    {item.endDate === item.startDate ? (
                      <Text pl="30px">
                        {format(new Date(item.startDate), "dd LLLL y")}
                      </Text>
                    ) : (
                      <Text pl="30px">
                        Week {index + 1}:{" "}
                        {format(new Date(item.startDate), "dd LLLL y")} -{" "}
                        {format(new Date(item.endDate), "dd LLLL y")}
                      </Text>
                    )}
                  </>
                ))}
              <br />
            </Box>
            <Box mb="50px" borderWidth="0px 0px 3px 0px" borderColor="gray.500">
              <b>TRAINING TIME</b>
              <br />
              <br />
              <Text pl="30px">
                {trainingDetails.training_time && (
                  <Text>
                    {Number(
                      JSON.parse(trainingDetails.training_time)[0][0] * 10
                    ) +
                      Number(JSON.parse(trainingDetails.training_time)[0][1]) <
                    11 ? (
                      <>
                        {Number(
                          JSON.parse(trainingDetails.training_time)[0][0] * 10
                        ) +
                          Number(
                            JSON.parse(trainingDetails.training_time)[0][1]
                          )}
                        {JSON.parse(trainingDetails.training_time)[0].slice(2)}{" "}
                        AM
                      </>
                    ) : Number(
                        JSON.parse(trainingDetails.training_time)[0][0] * 10
                      ) +
                        Number(
                          JSON.parse(trainingDetails.training_time)[0][1]
                        ) >
                      12 ? (
                      <>
                        {Number(
                          JSON.parse(trainingDetails.training_time)[0][0] * 10
                        ) +
                          Number(
                            JSON.parse(trainingDetails.training_time)[0][1]
                          ) -
                          12}
                        {JSON.parse(trainingDetails.training_time)[0].slice(2)}{" "}
                        PM
                      </>
                    ) : (
                      <>
                        {Number(
                          JSON.parse(trainingDetails.training_time)[0][0] * 10
                        ) +
                          Number(
                            JSON.parse(trainingDetails.training_time)[0][1]
                          )}
                        {JSON.parse(trainingDetails.training_time)[0].slice(2)}{" "}
                        PM
                      </>
                    )}{" "}
                    -{" "}
                    {Number(
                      JSON.parse(trainingDetails.training_time)[1][0] * 10
                    ) +
                      Number(JSON.parse(trainingDetails.training_time)[1][1]) <
                    11 ? (
                      <>
                        {Number(
                          JSON.parse(trainingDetails.training_time)[1][0] * 10
                        ) +
                          Number(
                            JSON.parse(trainingDetails.training_time)[1][1]
                          )}
                        {JSON.parse(trainingDetails.training_time)[1].slice(2)}{" "}
                        AM
                      </>
                    ) : Number(
                        JSON.parse(trainingDetails.training_time)[1][0] * 10
                      ) +
                        Number(
                          JSON.parse(trainingDetails.training_time)[1][1]
                        ) >
                      12 ? (
                      <>
                        {Number(
                          JSON.parse(trainingDetails.training_time)[1][0] * 10
                        ) +
                          Number(
                            JSON.parse(trainingDetails.training_time)[1][1]
                          ) -
                          12}
                        {JSON.parse(trainingDetails.training_time)[1].slice(2)}{" "}
                        PM
                      </>
                    ) : (
                      <>
                        {Number(
                          JSON.parse(trainingDetails.training_time)[1][0] * 10
                        ) +
                          Number(
                            JSON.parse(trainingDetails.training_time)[1][1]
                          )}
                        {JSON.parse(trainingDetails.training_time)[1].slice(2)}{" "}
                        PM
                      </>
                    )}
                  </Text>
                )}
                <br />
              </Text>
            </Box>
            <Box mb="50px" borderWidth="0px 0px 3px 0px" borderColor="gray.500">
              <b>TRAINING PLACE</b>
              <br />
              <br />
              {course.trainings && (
                <Text pl="30px">{trainingDetails.training_place}</Text>
              )}
              <br />
            </Box>
            <Box mb="50px" borderWidth="0px 0px 3px 0px" borderColor="gray.500">
              <b>TRAINING MODE</b>
              <br />
              <br />
              {course.trainings && (
                <Text pl="30px">
                  {trainingModes[trainingDetails.training_mode]}
                </Text>
              )}
              <br />
            </Box>
            <Box mb="50px" borderWidth="0px 0px 3px 0px" borderColor="gray.500">
              <b>TRAINING DETAILS</b>
              <br />
              <br />
              {course.trainings && (
                <Text pl="30px">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: trainingDetails.training_detail,
                    }}
                  />
                </Text>
              )}
              <br />
            </Box>
            <Box mb="50px" borderWidth="0px 0px 3px 0px" borderColor="gray.500">
              <b>FEE</b>
              <br />
              <br />
              {course.trainings && (
                <Text pl="30px">RM {trainingDetails.training_fee}</Text>
              )}
              <br />
            </Box>
          </DrawerBody>

          <Center>
            <DrawerFooter></DrawerFooter>
          </Center>
        </DrawerContent>
      </Drawer>
    </>
  );
}
