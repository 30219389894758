import React, { useEffect, useState } from "react";
import {
  Box,
  Input,
  Image,
  Container,
  Button,
  Text,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Heading,
  Center,
  FormControl,
  FormLabel,
  AspectRatio,
  Stack,
  HStack,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverHeader,
  PopoverCloseButton,
  PopoverBody,
  FormErrorMessage,
} from "@chakra-ui/react";
import styled from "styled-components";
import {
  AddIcon,
  EditIcon,
  DeleteIcon,
  InfoOutlineIcon,
} from "@chakra-ui/icons";
import { BiDotsVerticalRounded } from "react-icons/bi";
import {
  ReactTable,
  AlertPop,
  ChakraAlertDialog,
  ErrorsDialog,
} from "../../../components";
import { useForm, Controller } from "react-hook-form";
import CRUDFunction from "../../../functions/CRUDFunction";
import "../../../assets/css/custom.css";
import { URL_LOCAL, URL_STAGING } from "../../../constant";
import { WYSIWYGEditor } from "../../../components/rich-text-editor";

const StylesCourse = styled.div`
  padding-y: 1rem;

  table {
    min-width: 100%;
    border-spacing: 0;
    text-align: center;

    tr {
    }

    th {
      font-weight: bold;
      margin: 0;
      padding: 0.5rem;
      // background-color: #ffba00;
      background-color: white;
      border-bottom: 1px solid #bdbdbd;
      // border-right: 1px solid black;
      :nth-child(1) {
        width: 100px;
      }
      :nth-child(3) {
        width: 300px;
      }
      :last-child {
        width: 150px;
      }
    }

    td {
      // font-weight: bold;
      margin: 0;
      padding: 0.5rem;
      :nth-child(2) {
        text-align: left;
      }
    }
  }
`;

function CourseAdmin() {
  const addCourse = useDisclosure();
  const editCourse = useDisclosure();
  const dialogProps = useDisclosure();
  const errorProps = useDisclosure();
  const modalProps = useDisclosure();
  const [data, setData] = useState([]);
  const [courseID, setCourseId] = useState("");
  const [editData, setEditData] = useState([]);
  const [selectedImage, setSelectedImage] = useState();
  const [editImage, setEditImage] = useState(false);
  const [alert, setAlert] = useState({
    class: "hide",
    status: "",
    message: "",
  });
  const [errName, setErrName] = useState(false);
  const [errAbout, setErrAbout] = useState(false);
  const [errOutline, setErrOutline] = useState(false);
  const [errorAlert, setErrorAlert] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const columns = React.useMemo(
    () => [
      {
        Header: "#",
        id: "rows",
        // accessor: "course_id",
        Cell: (row) => (
          <>
            <Text>{parseInt(row.row.id) + 1}</Text>
          </>
        ),
      },
      {
        Header: "COURSE NAME",
        accessor: "course_name",
      },
      {
        id: "expander",
        Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
          <span {...getToggleAllRowsExpandedProps()}>
            {isAllRowsExpanded ? (
              // <InfoOutlineIcon boxsize={5} color="grey" />
              <b>HIDE DETAILS</b>
            ) : (
              // <InfoOutlineIcon boxsize={5} />
              <b>SHOW DETAILS</b>
            )}
          </span>
        ),

        Cell: ({ row }) => (
          // Use Cell to render an expander for each row.
          // We can use the getToggleRowExpandedProps prop-getter
          // to build the expander.
          <span {...row.getToggleRowExpandedProps()}>
            {row.isExpanded ? (
              <InfoOutlineIcon boxsize={5} color="grey" />
            ) : (
              <InfoOutlineIcon boxsize={5} />
            )}
          </span>
        ),
      },
      {
        Header: "ACTIONS",
        id: "ref",
        accessor: "course_id",
        disableSortBy: true,
        Cell: ({ cell }) => (
          <>
            <Menu>
              <MenuButton
                as={Button}
                bgColor="white"
                rightIcon={<BiDotsVerticalRounded boxsize={5} />}
              ></MenuButton>
              <MenuList minW="100px">
                <MenuItem
                  fontSize="15px"
                  icon={<EditIcon boxsize={5} color="#14804a" />}
                  onClick={async () => {
                    const res = await CRUDFunction.get(
                      `${URL_STAGING}course/${cell.value}`
                    );
                    if (res) {
                      setEditData(res);
                      setCourseId(cell.value);
                      editCourse.onOpen();
                    }
                  }}
                >
                  Edit
                </MenuItem>
                <MenuItem
                  fontSize="15px"
                  icon={<DeleteIcon boxsize={5} color="#d12953" />}
                  onClick={() => {
                    setCourseId(cell.value);
                    dialogProps.onOpen();
                  }}
                >
                  Delete
                </MenuItem>
              </MenuList>
            </Menu>
          </>
        ),
      },
    ],
    []
  );

  const [imageModal, setImageModal] = useState();
  const renderRowSubComponent = React.useCallback(({ row }) => {
    return (
      <div>
        <Container
          align="left"
          maxW="100%"
          bgColor="white"
          py="10px"
          borderBottom="1px"
          borderColor="#bdbdbd"
        >
          <Container align="left" maxW="80%" m="0px 0px 0px 100px">
            {data[row.id].course_image ? (
              <HStack>
                <b>Course Image:</b>
                <Button
                  variant="link"
                  fontSize="14px"
                  onClick={() => {
                    setImageModal(data[row.id].course_image);
                    modalProps.onOpen();
                  }}
                >
                  Show Image
                </Button>
              </HStack>
            ) : (
              ""
            )}
            <b>Course About:</b>
            <Text fontSize="14px" mb="17px">
              <div
                dangerouslySetInnerHTML={{ __html: data[row.id].course_about }}
              />
              {/* {data[row.id].course_about} */}
            </Text>
            <b>Course Outline:</b>
            <Text fontSize="14px" mb="17px">
              <div
                dangerouslySetInnerHTML={{
                  __html: data[row.id].course_outline,
                }}
              />
              {/* {data[row.id].course_outline} */}
            </Text>
          </Container>
        </Container>
      </div>
    );
  });

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async (pageNo = 1) => {
    const res = await CRUDFunction.get(`${URL_STAGING}course`);
    if (res) {
      setData(res);
    }
  };

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm();

  const timer = () => {
    setTimeout(function () {
      setAlert({ ...alert, class: "hide" });
      errorProps.onClose();
    }, 5000);
  };

  const resetErr = () => {
    setErrName(false);
    setErrAbout(false);
    setErrOutline(false);
  };

  const handleChange = (event) => {
    if (event.target.value !== "") {
      if (event.target.id === "name") {
        setErrName(false);
      }
    }
  };

  const onSubmit = async (data) => {
    if (data) {
      if (data.courseName === "") {
        setErrName(true);
      } else {
        setErrName(false);
      }

      const formData = new FormData();
      formData.append("course_name", data.courseName);
      formData.append("course_about", data.courseAbout);
      formData.append("course_outline", data.courseOutline);

      if (data.courseImage.length !== 0) {
        formData.append("course_image", data.courseImage[0]);
      }

      if (addCourse.isOpen) {
        const res = await CRUDFunction.create(`${URL_STAGING}course`, formData);
        if (res.errors) {
          setErrorAlert(res.message);
          errorProps.onOpen();
          timer();
        } else {
          setData(res);
          reset();
          resetErr();
          setAlert({
            ...alert,
            class: "show",
            status: "success",
            message: "Data is successfully created.",
          });
          timer();
          addCourse.onClose();
        }
      } else if (editCourse.isOpen) {
        if (editData.course_image) {
          formData.append("course_image", editData.course_image);
        }

        const res = await CRUDFunction.update(
          `${URL_STAGING}course/update/${courseID}`,
          formData
        );

        if (res.errors) {
          setErrorAlert(res.message);
          errorProps.onOpen();
          timer();
        } else {
          setData(res);
          reset();
          setEditImage(false);
          setAlert({
            ...alert,
            class: "show",
            status: "success",
            message: "Data is successfully updated.",
          });
          timer();
          editCourse.onClose();
        }
      }
    }
  };

  const imageChange = (e) => {
    if (editCourse.isOpen) {
      setEditImage(true);
    }

    if (e.target.files && e.target.files.length > 0) {
      setSelectedImage(e.target.files[0]);
    }
  };

  const removeSelectedImage = () => {
    setSelectedImage();
  };

  return (
    <div>
      {alert.message ? (
        <AlertPop
          width="30%"
          className={alert.class}
          status={alert.status}
          title={alert.message}
        />
      ) : (
        ""
      )}
      <Heading m="25px 0px 25px 100px">COURSE</Heading>
      <Container
        maxW={"90%"}
        py="20px"
        style={{
          backgroundColor: "white",
          borderRadius: "10px",
        }}
      >
        <div style={{ position: "relative" }}>
          <Button
            leftIcon={<AddIcon />}
            pos="absolute"
            right="2"
            top="25"
            _hover={{
              bg: "green.800 !important",
            }}
            style={{
              backgroundColor: "#33945F",
              color: "#ffffff",
              padding: "14px",
              fontSize: "14px",
            }}
            width="200px"
            onClick={() => {
              addCourse.onOpen();
              removeSelectedImage();
            }}
          >
            Add Course
          </Button>
        </div>
        <StylesCourse>
          <div key={1}>
            <ReactTable
              columns={columns}
              data={data}
              renderRowSubComponent={renderRowSubComponent}
              modulePage="course"
            />
          </div>
        </StylesCourse>
      </Container>
      {/* ------------------------------------ADD DRAWER----------------------------------- */}
      <Drawer
        isOpen={addCourse.isOpen}
        placement="right"
        onClose={() => {
          reset();
          resetErr();
          setEditImage(false);
          removeSelectedImage();
          addCourse.onClose();
        }}
        size="lg"
      >
        <DrawerOverlay />
        <DrawerContent overflowY="auto">
          <form onSubmit={handleSubmit(onSubmit)}>
            <DrawerCloseButton />
            <DrawerHeader textAlign="center" pt="7%" fontSize="25px">
              ADD COURSE
            </DrawerHeader>

            <DrawerBody>
              <b>Upload Course Image</b>
              <Container>
                <AspectRatio ratio={2 / 1}>
                  <Box
                    w="430px"
                    h="250px"
                    bgColor="gray.100"
                    borderColor="gray.300"
                    borderStyle="dashed"
                    borderWidth="2px"
                    rounded="md"
                    role="group"
                  >
                    <Stack
                      // height="100%"
                      // width="100%"
                      display="flex"
                      alignItems="center"
                      justify="center"
                    >
                      {selectedImage && (
                        <div
                          style={{
                            mt: "50",
                            display: "flex",
                            flexDirection: "column",
                          }}
                        >
                          <Image
                            width="430px"
                            height="245px"
                            src={URL.createObjectURL(selectedImage)}
                            style={{ maxWidth: "100%", maxHeight: "30%" }}
                            alt="Thumb"
                          />
                        </div>
                      )}
                    </Stack>
                  </Box>
                </AspectRatio>
                <Text fontSize="14px" textAlign="right">
                  Recommended resolution: 430x240
                </Text>
                <input
                  type={"file"}
                  class="custom-image-input"
                  accept={"image/*"}
                  {...register("courseImage")}
                  onChange={imageChange}
                />
              </Container>

              <FormControl isInvalid={errName} pt="20px">
                <FormLabel fontWeight="bold">
                  COURSE NAME <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Input
                  id="name"
                  placeholder="Course name"
                  {...register("courseName")}
                  onBlur={handleChange}
                />
              </FormControl>
              {errName && (
                <span style={{ color: "red" }}>This field is required</span>
              )}

              <FormControl
                id="courseAbout"
                isInvalid={errors.courseAbout}
                pt="20px"
              >
                <FormLabel fontWeight="bold">
                  COURSE ABOUT <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Controller
                  name="courseAbout"
                  control={control}
                  render={({ field }) => <WYSIWYGEditor {...field} />}
                  rules={{
                    required: { value: true, message: "This is required." },
                  }}
                />
                <FormErrorMessage>
                  {errors.courseAbout?.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl
                id="courseOutline"
                isInvalid={errors.courseOutline}
                pt="20px"
              >
                <FormLabel fontWeight="bold">
                  COURSE OUTLINES <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Controller
                  name="courseOutline"
                  control={control}
                  render={({ field }) => <WYSIWYGEditor {...field} />}
                  rules={{
                    required: { value: true, message: "This is required." },
                  }}
                />
                <FormErrorMessage>
                  {errors.courseOutline?.message}
                </FormErrorMessage>
              </FormControl>

              {/* <FormControl isInvalid={errAbout} pt="20px">
                <FormLabel fontWeight="bold">
                  COURSE ABOUT <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Controller
                  render={({ field }) => <WYSIWYGEditor {...field} />}
                  name="courseAbout"
                  control={control}
                  defaultValue=""
                  {...register("courseAbout")}
                  onBlur={handleChange}
                />
              </FormControl> */}
              {/* {errAbout && (
                <span style={{ color: "red" }}>This field is required</span>
              )}
              <FormControl isInvalid={errOutline} pt="20px">
                <FormLabel fontWeight="bold">
                  COURSE OUTLINES <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Controller
                  render={({ field }) => <WYSIWYGEditor {...field} />}
                  name="courseOutline"
                  control={control}
                  defaultValue=""
                  {...register("courseOutline")}
                />
              </FormControl>
              {errOutline && (
                <span style={{ color: "red" }}>This field is required</span>
              )} */}
            </DrawerBody>

            <Center>
              <DrawerFooter>
                <Button
                  fontWeight="medium"
                  w="200px"
                  color="white"
                  bg="#33945f"
                  _hover={{ bg: "green.700" }}
                  type="submit"
                >
                  Add
                </Button>
                <Button
                  fontWeight="medium"
                  w="200px"
                  variant="outline"
                  ml={3}
                  onClick={() => {
                    reset();
                    resetErr();
                    removeSelectedImage();
                    addCourse.onClose();
                  }}
                >
                  Cancel
                </Button>
              </DrawerFooter>
            </Center>
          </form>
        </DrawerContent>
      </Drawer>
      {/* ------------------------------------ADD DRAWER----------------------------------- */}

      {/* ------------------------------------EDIT DRAWER----------------------------------- */}
      <Drawer
        isOpen={editCourse.isOpen}
        placement="right"
        onClose={() => {
          reset();
          resetErr();
          removeSelectedImage();
          setEditImage(false);
          editCourse.onClose();
        }}
        size="lg"
      >
        <DrawerOverlay />
        <DrawerContent overflowY="auto">
          <form onSubmit={handleSubmit(onSubmit)}>
            <DrawerCloseButton />
            <DrawerHeader textAlign="center" pt="7%" fontSize="25px">
              EDIT COURSE
            </DrawerHeader>

            <DrawerBody>
              <b>Upload Course Name</b>
              <Container my="10px">
                <Text>Current Image</Text>
                <AspectRatio ratio={2 / 1}>
                  <Box
                    bgColor="gray.100"
                    borderColor="gray.300"
                    borderStyle="dashed"
                    borderWidth="2px"
                    rounded="md"
                    role="group"
                  >
                    <Stack display="flex" alignItems="center" justify="center">
                      <Image
                        w="430px"
                        h="250px"
                        src={
                          editImage
                            ? URL.createObjectURL(selectedImage)
                            : `http://localhost:8000/uploads/${editData.course_image}`
                        }
                        // style={{ maxWidth: "100%", maxHeight: "30%" }}
                      />
                    </Stack>
                  </Box>
                </AspectRatio>
                <Text fontSize="14px" textAlign="right">
                  Recommended resolution: 430x240
                </Text>
              </Container>
              <HStack>
                <Box w="60%" align="left">
                  <Text mt="10px" color="green">
                    &nbsp;&nbsp;&nbsp;&nbsp;Upload new course image:
                  </Text>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <input
                    type={"file"}
                    class="custom-image-input"
                    accept={"image/*"}
                    {...register("courseImage")}
                    onChange={imageChange}
                  />
                </Box>
                {editData.course_image ? (
                  <Box w="40%" pb="35px">
                    <Popover size="sm">
                      <PopoverTrigger>
                        <Text
                          mt="10px"
                          color="red"
                          _hover={{ cursor: "pointer" }}
                        >
                          Remove course image
                        </Text>
                      </PopoverTrigger>
                      <PopoverContent p="5px" textAlign="center">
                        <PopoverArrow />
                        <PopoverCloseButton />
                        <PopoverHeader>Confirmation!</PopoverHeader>
                        <PopoverBody>
                          Are you sure? You can't undo this action afterwards.
                        </PopoverBody>
                        <Center>
                          <Button
                            w="50%"
                            bgColor="red"
                            _hover={{ bgColor: "red.500" }}
                            color="white"
                            size="sm"
                            onClick={() => {
                              setEditData({
                                ...editData,
                                course_image: false,
                              });
                              setSelectedImage("");
                            }}
                          >
                            Remove
                          </Button>
                        </Center>
                      </PopoverContent>
                    </Popover>
                  </Box>
                ) : (
                  ""
                )}
              </HStack>

              <FormControl isInvalid={errName} pt="20px">
                <FormLabel fontWeight="bold">
                  COURSE NAME <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Input
                  placeholder="Course name"
                  defaultValue={editData.course_name}
                  {...register("courseName")}
                ></Input>
              </FormControl>
              {errName && (
                <span style={{ color: "red" }}>This field is required</span>
              )}

              <FormControl
                id="courseAbout"
                isInvalid={errors.courseAbout}
                pt="20px"
              >
                <FormLabel fontWeight="bold">
                  COURSE ABOUT <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Controller
                  name="courseAbout"
                  control={control}
                  defaultValue={editData.course_about}
                  render={({ field }) => <WYSIWYGEditor {...field} />}
                  rules={{
                    required: { value: true, message: "This is required." },
                  }}
                />
                <FormErrorMessage>
                  {errors.courseAbout?.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl
                id="courseOutline"
                isInvalid={errors.courseOutline}
                pt="20px"
              >
                <FormLabel fontWeight="bold">
                  COURSE OUTLINES <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Controller
                  name="courseOutline"
                  control={control}
                  defaultValue={editData.course_outline}
                  render={({ field }) => <WYSIWYGEditor {...field} />}
                  rules={{
                    required: { value: true, message: "This is required." },
                  }}
                />
                <FormErrorMessage>
                  {errors.courseOutline?.message}
                </FormErrorMessage>
              </FormControl>
            </DrawerBody>

            <Center>
              <DrawerFooter>
                <Button
                  fontWeight="medium"
                  w="200px"
                  color="white"
                  bg="#33945f"
                  _hover={{ bg: "green.700" }}
                  type="submit"
                >
                  Save
                </Button>
                <Button
                  fontWeight="medium"
                  w="200px"
                  variant="outline"
                  ml={3}
                  onClick={() => {
                    reset();
                    // reset({
                    //   courseAbout: "",
                    //   courseOutline: "",
                    // });
                    resetErr();
                    removeSelectedImage();
                    setEditImage(false);
                    editCourse.onClose();
                  }}
                >
                  Cancel
                </Button>
              </DrawerFooter>
            </Center>
          </form>
        </DrawerContent>
      </Drawer>
      {/* ------------------------------------EDIT DRAWER----------------------------------- */}
      {/* ------------------------------------DELETE DIALOG----------------------------------- */}
      <ChakraAlertDialog
        title={"Delete Course"}
        dialogProps={dialogProps}
        url={[`${URL_STAGING}course/${courseID}`]}
        alert={alert}
        setAlert={setAlert}
        setData={setData}
        body={"Are you sure? You can't undo this action afterwards."}
        button={"Delete"}
      />
      {/* ------------------------------------DELETE DIALOG----------------------------------- */}
      {/* ------------------------------------IMAGE PREVIEW MODAL----------------------------------- */}
      <Modal isOpen={modalProps.isOpen} onClose={modalProps.onClose} size="3xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Course Image</ModalHeader>
          <ModalCloseButton />
          <ModalBody align="center">
            <Container>
              {/* <AspectRatio> */}
              <Image
                src={`http://localhost:8000/uploads/${imageModal}`}
                style={{ maxWidth: "100%", maxHeight: "100%" }}
              />
              {/* </AspectRatio> */}
            </Container>
          </ModalBody>
          <ModalFooter />
        </ModalContent>
      </Modal>
      {/* ------------------------------------IMAGE PREVIEW MODAL----------------------------------- */}
      <ErrorsDialog
        title={"Errors"}
        dialogProps={errorProps}
        body={errorAlert}
      />
    </div>
  );
}

export { CourseAdmin };
