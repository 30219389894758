import {
  Modal as ModalChakra,
  ModalOverlay,
  ModalContent,
  Spinner,
  Select,
  Text,
  ModalBody,
  Stack,
  Box,
  FormControl,
  Input,
  FormLabel,
  ModalFooter,
  Button,
  ModalHeader,
  ModalCloseButton,
  Heading,
  Image,
} from "@chakra-ui/react";

function Modal({
  trigger,
  loader,
  onSubmit,
  register,
  handleSubmit,
  inputArr,
  buttonName,
  buttonName2,
  modalHeader,
  viewArr,
  viewName,
  viewData,
  setButtonType,
}) {
  return (
    <ModalChakra isOpen={trigger.isOpen} onClose={trigger.onClose} size="4xl">
      <ModalOverlay />
      <ModalContent>
        {loader && (
          <div style={{ position: "absolute", top: "130%", right: "50%" }}>
            <Spinner
              thickness="4px"
              speed="0.65s"
              emptyColor="gray.200"
              color="#33945f"
              size="xl"
              zIndex="1"
              background="white"
              display="flex"
              margin="auto"
            />
            <Text position="relative" zIndex="1" color="white" py="5px">
              Loading
            </Text>
          </div>
        )}
        <ModalHeader bg="#f1f1f1" borderTopRadius={10}>
          {modalHeader}
        </ModalHeader>
        <ModalCloseButton />
        {inputArr && (
          <form onSubmit={handleSubmit(onSubmit)}>
            <ModalBody>
              <Stack spacing={4} direction="row">
                <Box width={"100%"} height={"100%"}>
                  {inputArr.map((item) => (
                    <FormControl mt={4}>
                      <FormLabel fontWeight="bold">{item.label}</FormLabel>
                      {item.select.length <= 0 && (
                        <Input
                          type={`${item.type}`}
                          placeholder={`Insert ${item.label}`}
                          {...register(`${item.param}`)}
                        />
                      )}
                      {item.select.length > 0 && (
                        <Select
                          placeholder={`Select ${item.label}`}
                          {...register(`${item.param}`)}
                        >
                          {item.select.length > 0 &&
                            item.select.map((elem) => (
                              <option value={elem.training_id}>
                                {elem.training_name}
                              </option>
                            ))}
                        </Select>
                      )}
                    </FormControl>
                  ))}
                </Box>
              </Stack>
            </ModalBody>

            <ModalFooter>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                {inputArr && (
                  <Button
                    // bg="black"
                    variant="ghost"
                    color="black"
                    mr={3}
                    fontSize={"14px"}
                    w={"10%"}
                    type="submit"
                    disabled={loader}
                    onClick={() => {
                      setButtonType(buttonName2);
                    }}
                  >
                    {buttonName2}
                  </Button>
                )}
                <Button
                  bg="#33945f"
                  color="white"
                  mr={3}
                  fontSize={"14px"}
                  w={"10%"}
                  type="submit"
                  disabled={loader}
                  onClick={() => {
                    setButtonType(buttonName);
                  }}
                >
                  {buttonName}
                </Button>
                <Button
                  variant="outline"
                  onClick={trigger.onClose}
                  fontSize={"14px"}
                  w={"10%"}
                >
                  Cancel
                </Button>
              </div>
            </ModalFooter>
          </form>
        )}
        <ModalBody>
          {viewName && (
            <Stack direction="row" p="5">
              <Box
                p="5"
                my="auto"
                borderWidth={1}
                maxW="350px"
                maxH="350px"
                style={{ display: "flex" }}
              >
                <Image
                  src={
                    viewData.company_image
                      ? `http://localhost:8000/uploads/${viewData.company_image}`
                      : "https://dummyimage.com/600x400/000/fff"
                  }
                />
              </Box>

              <Box pl="15px" color="#959ba3">
                {viewName.map((item) => (
                  <>
                    <Heading
                      size="sm"
                      style={{
                        textTransform: "capitalize",
                        textDecoration: "underline",
                      }}
                    >
                      {item.charAt(10) === "c"
                        ? item.replace("company_pic", "PIC ")
                        : item.charAt(8) !== "f"
                        ? item.replace("_", " ")
                        : item.replace("_finance_email", " Finance Email")}
                    </Heading>
                    <Text mb="10px" color="black">
                      {item === "company_address" ? (
                        <Text>
                          {viewData &&
                            JSON.parse(viewData.company_address)[0]
                              .address}{" "}
                          {viewData &&
                            JSON.parse(viewData.company_address)[0].postcode}
                          ,{" "}
                          {viewData &&
                            JSON.parse(viewData.company_address)[0].state}
                        </Text>
                      ) : (
                        viewData[item]
                      )}
                      {/* {viewData[item]} */}
                    </Text>
                  </>
                ))}
              </Box>
            </Stack>
          )}
        </ModalBody>
      </ModalContent>
    </ModalChakra>
  );
}

export default Modal;
