import { Button, Flex, Select, Spacer, Text } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

const Pagination = ({
  pageChangeHandler,
  data,
  totalRows,
  rowsPerPage,
  setData,
  currentRows,
  setCurrentRows,
  fetchData,
  modulePage,
}) => {
  // Calculating max number of pages
  const noOfPages = Math.ceil(totalRows / rowsPerPage);

  // Creating an array with length equal to no.of pages
  //   const pagesArr = [...new Array(noOfPages)];

  // State variable to hold the current page. This value is
  // passed to the callback provided by the parent
  const [currentPage, setCurrentPage] = useState(1);

  // Navigation arrows enable/disable state
  const [canGoBack, setCanGoBack] = useState(false);
  const [canGoNext, setCanGoNext] = useState(true);

  // Onclick handlers for the butons
  const onNextPage = () => setCurrentPage(currentPage + 1);
  const onPrevPage = () => setCurrentPage(currentPage - 1);
  const onPageSelect = (pageNo) => setCurrentPage(pageNo);

  // Disable previous and next buttons in the first and last page
  // respectively
  useEffect(() => {
    if (noOfPages === currentPage) {
      setCanGoNext(false);
    } else {
      setCanGoNext(true);
    }
    if (currentPage === 1) {
      setCanGoBack(false);
    } else {
      setCanGoBack(true);
    }
    if (totalRows === 0) {
      setCanGoNext(false);
    }
  }, [noOfPages, currentPage]);

  // To set the starting index of the page
  useEffect(() => {
    // const skipFactor = (currentPage - 1) * rowsPerPage;
    // Some APIs require skip for paginaiton. If needed use that instead
    // pageChangeHandler(skipFactor);

    pageChangeHandler(currentPage);
  }, [currentPage]);

  return (
    <>
      <Flex>
        {modulePage === "training" && (
          <Select
            w="10%"
            mr="2"
            borderRadius={0}
            borderColor="#33945f"
            borderWidth={3}
            color="#33945f"
            fontWeight="bold"
            value={currentRows}
            onChange={(e) => {
              setData((prevState) => ({
                ...prevState,
                rowData: [],
                tableLoader: true,
              }));
              setCurrentPage(1);
              setCurrentRows(e.target.value);
              fetchData(currentRows);
            }}
          >
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </Select>
        )}
        <Text my="auto" fontSize="sm" fontWeight="bold" color="#c4c4c4">
          Showing {data.length} results of {totalRows} rows
        </Text>
        <Spacer />
        <Button
          borderRadius="100px"
          bg="#d9d9d9"
          color="white"
          mr="2"
          onClick={() => {
            onPageSelect(1);
          }}
          disabled={!canGoBack}
        >
          {"<<"}
        </Button>
        <Button
          mr="2"
          borderRadius="100px"
          bg="#d9d9d9"
          color="white"
          onClick={onPrevPage}
          disabled={!canGoBack}
        >
          {"<"}
        </Button>
        <Text my="auto" mr="2" color="#33945f">
          Page{" "}
          <strong>
            {currentPage} of {noOfPages}
          </strong>{" "}
        </Text>
        <Button
          mr="2"
          borderRadius="100px"
          bg="#d9d9d9"
          color="white"
          onClick={onNextPage}
          disabled={!canGoNext}
        >
          {">"}
        </Button>
        <Button
          borderRadius="100px"
          bg="#d9d9d9"
          color="white"
          onClick={() => {
            onPageSelect(noOfPages);
          }}
          disabled={!canGoNext}
        >
          {">>"}
        </Button>
        <Spacer />
        <Text>
          <strong style={{ color: "#c4c4c4" }}>Go to page: </strong>
          <input
            type="number"
            defaultValue={currentPage}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) : 0;
              onPageSelect(page);
            }}
            style={{
              padding: "5px",
              width: "80px",
              fontWeight: "bold",
              textAlign: "center",
              color: "#33945f",
              border: "3px solid #33945f",
            }}
          />
        </Text>{" "}
      </Flex>
    </>
  );
};

export default Pagination;
