import React, { useState, useEffect } from "react";
import {
  Box,
  SimpleGrid,
  Container,
  Text,
  Heading,
  Center,
  Image,
  Button,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import CRUDFunction from "../../../functions/CRUDFunction";
import { URL_LOCAL, URL_STAGING } from "../../../constant";

export default function CourseReg() {
  const [data, setData] = useState([]);
  const nav = useNavigate();
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const res = await CRUDFunction.get(`${URL_STAGING}course`);
    if (res) {
      setData(res);
    }
  };

  const checkLowestFees = (arr) => {
    let startingPrice = 0;
    arr.map((e, index) => {
      if (index == 0) {
        startingPrice = parseInt(e.training_fee);
      }
      if (startingPrice > parseInt(e.training_fee)) {
        startingPrice = parseInt(e.training_fee);
      }
    });
    return startingPrice;
  };

  return (
    <>
      <Container
        maxW={{ base: "100%", md: "90%" }}
        my={{ base: "20%", md: "6%", lg: 0 }}
      >
        <Center>
          <Heading size="2xl">COURSES</Heading>
        </Center>
        <SimpleGrid
          columns={{ sm: 1, lg: 2, xl: 3 }}
          spacing={10}
          mt="5"
          maxH="100%"
        >
          {data.map((item) => (
            <Box
              maxW="600px"
              maxH="100%"
              bg="white"
              boxShadow="lg"
              display="grid"
              pb="10px"
              borderRadius={25}
            >
              <Image
                w="100%"
                h="250px"
                borderTopRadius={25}
                src={
                  item.course_image
                    ? `https://myhalalgig.com/htms-api/public/uploads/${item.course_image}`
                    : "https://dummyimage.com/600x200/000/fff.jpg"
                }
              ></Image>
              <div
                style={{
                  padding: "15px",
                  textAlign: "center",
                }}
              >
                <Heading
                  fontSize={{ base: 20, sm: 30, xl: 22 }}
                  textAlign="center"
                  noOfLines={{ md: 2 }}
                >
                  {item.course_name}
                </Heading>
                <Text
                  fontSize={{ base: 15, md: 18 }}
                  pt="10"
                  px="2"
                  textAlign="justify"
                  noOfLines={4}
                >
                  {item.course_about.replace(/<[^>]+>/g, "")}
                </Text>
                <Text fontSize={18} pt="10" fontWeight="bold">
                  Starting Price: RM {checkLowestFees(item.trainings)}
                </Text>
                <Center pt="10">
                  <Button
                    color="green"
                    variant="link"
                    onClick={() => {
                      nav(`../details`, {
                        replace: false,
                        state: { id: item.course_id },
                      });
                    }}
                  >
                    More details
                  </Button>
                </Center>
              </div>
            </Box>
          ))}
        </SimpleGrid>
      </Container>
    </>
  );
}
