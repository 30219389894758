import { useState } from "react";
import { Outlet } from "react-router-dom";
import { Grid, GridItem } from "@chakra-ui/react";
import { Sidebar, Footer } from "../../components";

const LayoutAdmin = () => {
  const [show, setShow] = useState(true);
  return (
    <Grid
      templateAreas={`"nav main" 
                      "nav footer"`}
      gridTemplateRows={"1fr 20px"}
      gridTemplateColumns={"220px 1fr"}
      w="100%"
      minH="100vh"
    >
      {/* <GridItem colSpan={9} area={"header"} zIndex="1">
      <Header />
    </GridItem> */}
      <GridItem area={"nav"} colSpan={show ? 1 : 0}>
        <Sidebar toggleNav={setShow} nav={show} />
      </GridItem>
      <GridItem
        area={"main"}
        colSpan={9}
        ml={show ? "0" : "-125px"}
        pb="3%"
        bgColor="#f1f1f1"
        zIndex="1"
        style={{ transition: "0.3s ease" }}
      >
        <Outlet />
      </GridItem>
      <GridItem
        colSpan={9}
        ml={show ? "0" : "-125px"}
        area={"footer"}
        zIndex="1"
        style={{ transition: "0.3s ease" }}
      >
        <Footer view="admin" />
      </GridItem>
    </Grid>
  );
};

export { LayoutAdmin };
