import React, { useEffect, useState } from "react";
import {
  Text,
  Stack,
  Box,
  Image,
  Heading,
  Link,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Input,
  ModalFooter,
  Button,
  Select,
  Skeleton,
} from "@chakra-ui/react";
import styled from "styled-components";
import hl_logo from "../../assets/logo.png";

import { format } from "date-fns";
import { useForm } from "react-hook-form";
import { URL_LOCAL, URL_STAGING } from "../../constant";
import CRUDFunction from "../../functions/CRUDFunction";

const Styles = styled.div`
  padding: 1rem;

  #header {
    text-align: center;
    border: 1px solid;
  }
  #header img {
    margin-left: auto;
    margin-right: auto;
    border: 1px solid;
  }

  table {
    min-width: 100%;
    border-spacing: 0;
    text-align: center;

    tr {
      :hover {
        background-color: #ebebeb;
      }
    }

    th {
      font-weight: bold;
      margin: 0;
      padding: 0.5rem;
      background-color: white;
      border-bottom: 1px solid #bdbdbd;
      :first-child {
        width: 5%;
      }
      :nth-child(2) {
        width: 350px;
      }
    }

    td {
      padding: 0.5rem;
      :last-child {
        margin-top: 20px;
      }
    }
  }
`;

export default function TraineeSummaryTable({
  date,
  data,
  setData,
  print,
  course,
  training,
  tableLoader,
}) {
  const editModal = useDisclosure();
  const [edit, setEdit] = useState({
    id: "",
    trainee_id: "",
    time: "--:--",
    date: "",
    punch: "",
    status: "",
  });
  const [isActive, setActive] = useState(false);

  const { register, reset, handleSubmit } = useForm();

  const dataAttendance = async (sumdata) => {
    if (sumdata) {
      await sumdata.trainees.map((item) => {
        //if trainee's attendance is zero
        if (item.attendance.length === 0) {
          for (let i = 0; i < date.length; i++) {
            if (!item.attendance[i]) {
              item.attendance.push({
                attendance_punchin: "--:--",
              });
            }

            item.attendance[i].attendance_punchout = "--:--";
            if (item.trainee_status === "3") {
              item.attendance[i].attendance_status = 3;
            }
          }
        } else {
          for (let i = 0; i < date.length; i++) {
            var temp = "--:--";
            var temp2 = "--:--";

            if (item.attendance[i] !== undefined) {
              if (item.attendance[i].attendance_punchin !== null) {
                temp = item.attendance[i].attendance_punchin;
              }
              if (item.attendance[i].attendance_punchout !== null) {
                temp2 = item.attendance[i].attendance_punchout;
              }
              if (item.attendance[i].attendance_status === 1) {
              }

              if (!temp.includes(date[i]) && !temp2.includes(date[i])) {
                if (item.trainee_status === "3") {
                  item.attendance.splice(i, 0, {
                    attendance_punchin: "--:--",
                    attendance_punchout: "--:--",
                    attendance_status: 3,
                  });
                } else {
                  item.attendance.splice(i, 0, {
                    attendance_punchin: "--:--",
                    attendance_punchout: "--:--",
                  });
                }
              } else if (!temp.includes(date[i])) {
                item.attendance[i].attendance_punchin = "--:--";
              } else if (!temp2.includes(date[i])) {
                item.attendance[i].attendance_punchout = "--:--";
              }
            } else {
              if (item.trainee_status === "3") {
                item.attendance.splice(i, 0, {
                  attendance_punchin: "--:--",
                  attendance_punchout: "--:--",
                  attendance_status: 3,
                });
              } else {
                item.attendance.splice(i, 0, {
                  attendance_punchin: "--:--",
                  attendance_punchout: "--:--",
                });
              }
            }
          }
        }
      });
      setData(sumdata);
    }
  };

  const onSubmit = async (datas) => {
    if (datas) {
      const formData = new FormData();

      if (datas.attd_id) {
        formData.append("attendance_id", datas.attd_id);
      }

      if (datas.time === "") {
        datas.time = "00:00";
        formData.append("attendance_status", 1);
      } else {
        setActive(false);
        formData.append("attendance_status", 2);
      }

      if (datas.punch === "in") {
        formData.append("attendance_punchin", edit.date + " " + datas.time);
      } else if (datas.punch === "out" && datas.time !== "") {
        formData.append("attendance_punchout", edit.date + " " + datas.time);
      }
      formData.append("training_id", data.training_id);
      formData.append("trainee_id", edit.trainee_id);

      // for (var pair of formData.entries()) {
      //   console.log(pair[0] + ", " + pair[1]);
      // }

      //update
      if (datas.attd_id) {
        const res = await CRUDFunction.update(
          `${URL_STAGING}attendance/summary/${datas.attd_id}`,
          formData
        );
        if (res) {
          reset();
          setActive(false);
          dataAttendance(res);

          editModal.onClose();
        }
      } else {
        //create

        const res = await CRUDFunction.create(
          `${URL_STAGING}attdsummary`,
          formData
        );
        if (res) {
          reset();
          setActive(false);
          dataAttendance(res);

          editModal.onClose();
        }
      }
    }
  };

  return (
    <>
      <div id="printSummary">
        {print && (
          <>
            <div
              id="header"
              style={{ textAlign: "center", marginLeft: "120px" }}
            >
              <div>
                <img
                  src={hl_logo}
                  style={{ marginLeft: "360px", width: "25%" }}
                />
                <span>
                  {course === 1
                    ? "PROFESSIONAL CERTIFICATE FOR HALAL EXECUTIVE (PCHE)"
                    : course === 2
                    ? "PROFESSIONAL CERTIFICATE FOR INTERNAL HALAL AUDITORS (PCIHA)"
                    : course === 3
                    ? "HALAL COMPETENCY"
                    : course === 4
                    ? "HALAL AWARENESS"
                    : "SEMINAR"}
                </span>
                <p>{training}</p>
              </div>
            </div>
            <div style={{ float: "left", width: "250px" }}>
              * <span style={{ color: "red" }}>A</span>
              <span> = Absent</span>
              <span style={{ color: "#D4AC0D" }}> W</span>
              <span> = Withdrew</span>
            </div>
          </>
        )}

        <Styles>
          <table>
            <tr>
              <th>#</th>
              <th>{print ? "NAME" : "TRAINEE"}</th>
              {date.map((date) => (
                <th>{format(new Date(date), "dd/LL/y")}</th>
              ))}
            </tr>
            {tableLoader && (
              <>
                <tr>
                  <td colspan={2 + date.length}>
                    <Skeleton height="50px" />
                  </td>
                </tr>
                <tr>
                  <td colspan={2 + date.length}>
                    <Skeleton height="50px" />
                  </td>
                </tr>
                <tr>
                  <td colspan={2 + date.length}>
                    <Skeleton height="50px" />
                  </td>
                </tr>
              </>
            )}
            {data.trainees &&
              data.trainees.map((item, i) => (
                <tr>
                  <td>{i + 1}</td>
                  <td>
                    <Text
                      noOfLines={1}
                      color={item.trainee_status === "3" && "#D4AC0D"}
                    >
                      {item.trainee_name}
                    </Text>
                  </td>
                  {item.attendance.map((itm, index) => (
                    <>
                      <td>
                        {
                          <>
                            {/* punchin */}
                            <Link
                              color={
                                item.trainee_status === "3" &&
                                itm.attendance_status === 1
                                  ? "red"
                                  : item.trainee_status === "3"
                                  ? "#D4AC0D"
                                  : itm.attendance_status === 1 && "red"
                              }
                              onClick={() => {
                                editModal.onOpen();
                                setEdit({
                                  ...edit,
                                  id: itm.attendance_id,
                                  trainee_id: item.trainee_id,
                                  time: itm.attendance_punchin,
                                  date: date[index],
                                  punch: "in",
                                });
                                if (itm.attendance_status === 1) {
                                  setActive(true);
                                }
                              }}
                            >
                              {itm.attendance_status === 1
                                ? "A"
                                : itm.attendance_punchin !== "--:--"
                                ? format(
                                    new Date(itm.attendance_punchin),
                                    "HH:mm"
                                  )
                                : itm.attendance_status === 3
                                ? "W"
                                : "--:--"}
                            </Link>

                            <br />
                            {/* punchout */}
                            <Link
                              color={
                                item.trainee_status === "3" &&
                                itm.attendance_status === 1
                                  ? "red"
                                  : item.trainee_status === "3"
                                  ? "#D4AC0D"
                                  : itm.attendance_status === 1 && "red"
                              }
                              onClick={() => {
                                editModal.onOpen();
                                setEdit({
                                  ...edit,
                                  id: itm.attendance_id,
                                  trainee_id: item.trainee_id,
                                  time: itm.attendance_punchout,
                                  date: date[index],
                                  punch: "out",
                                });
                                if (itm.attendance_status === 1) {
                                  setActive(true);
                                }
                              }}
                            >
                              {itm.attendance_status === 1
                                ? "A"
                                : itm.attendance_punchout !== "--:--"
                                ? format(
                                    new Date(itm.attendance_punchout),
                                    "HH:mm"
                                  )
                                : itm.attendance_status === 3
                                ? "W"
                                : "--:--"}
                            </Link>
                          </>
                        }
                      </td>
                    </>
                  ))}
                </tr>
              ))}
          </table>
        </Styles>
        {print && (
          <Stack direction="columns">
            <div
              style={{
                marginRight: "30%",
                width: "200px",
              }}
            >
              <Text fontWeight={"bold"} textDecoration={"underline"}>
                TRAINING PROVIDER
              </Text>
              <Text fontWeight={"bold"}>Signature:</Text>
              <Text fontWeight={"bold"}>Name: </Text>
              <Text fontWeight={"bold"}>Position: </Text>
              <Text fontWeight={"bold"}>Date: </Text>
              <Text fontWeight={"bold"}>Company Stamp: </Text>
            </div>
            <div style={{ width: "200px" }}>
              <Text fontWeight={"bold"} textDecoration={"underline"}>
                COMPANY
              </Text>
              <Text fontWeight={"bold"}>Signature:</Text>
              <Text fontWeight={"bold"}>Name: </Text>
              <Text fontWeight={"bold"}>Position: </Text>
              <Text fontWeight={"bold"}>Date: </Text>
              <Text fontWeight={"bold"}>Company Stamp: </Text>
            </div>
          </Stack>
        )}
      </div>
      {/* ----------------------------edit modal----------------------------------- */}
      <Modal
        isOpen={editModal.isOpen}
        onClose={() => {
          editModal.onClose();
          setActive(false);
          reset();
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <ModalHeader>Edit time</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Input
                // required
                type="time"
                defaultValue={
                  isActive
                    ? "--:--"
                    : edit.time !== "--:--"
                    ? format(new Date(edit.time), "HH:mm")
                    : "--:--"
                }
                {...register("time")}
              />
              <Input
                type="hidden"
                defaultValue={edit.punch}
                {...register("punch")}
              />
              <Input
                type="hidden"
                defaultValue={edit.id ? edit.id : ""}
                {...register("attd_id")}
              />
              <Box
                borderWidth={1}
                borderRadius={5}
                borderColor={"red"}
                cursor="pointer"
                mx="auto"
                mt="20px"
                w="150px"
                p={2}
                color={isActive ? "white" : "red"}
                bg={isActive && "red"}
                textAlign={"center"}
                onClick={() => {
                  if (edit.status !== 1) {
                    reset();
                  }
                  setActive((current) => !current);
                }}
              >
                <Text>ABSENT</Text>
              </Box>
            </ModalBody>
            <Box my="20px" textAlign={"center"}>
              <Button type="submit" mr="3">
                Save
              </Button>
              <Button
                onClick={() => {
                  editModal.onClose();
                  setActive(false);
                  reset();
                }}
              >
                Cancel
              </Button>
            </Box>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
}
