import React, { useState } from "react";
import {
  IconButton,
  Box,
  Flex,
  Icon,
  Drawer,
  DrawerContent,
  Text,
  useDisclosure,
  Divider,
  Slide,
  Spacer,
  Image,
} from "@chakra-ui/react";
import {
  FiHome,
  FiSettings,
  FiMenu,
  FiCalendar,
  FiBook,
  FiList,
  FiBookOpen,
  FiPercent,
  FiLogOut,
  FiSmile,
  FiEdit3,
  FiFileText,
} from "react-icons/fi";
import { BsGrid } from "react-icons/bs";
import { Link, useMatch } from "react-router-dom";
import htmsLogo from "../../assets/htms-logo.png";

const LinkItems = [
  { name: "Dashboard", icon: FiHome, src: "dashboard" },
  { name: "Course", icon: FiBook, src: "course" },
  { name: "Programme", icon: FiBookOpen, src: "program" },
  { name: "Company", icon: BsGrid, src: "company" },
  { name: "Training", icon: FiList, src: "training" },
  { name: "Discount", icon: FiPercent, src: "discount" },
  { name: "Calendar", icon: FiCalendar, src: "calendar" },
  { name: "Copywriting", icon: FiEdit3, src: "copywriting" },
  { name: "Feedback", icon: FiFileText, src: "feedback" },
  { name: "Settings", icon: FiSettings, src: "settings" },
  { name: "Logout", icon: FiLogOut, src: "logout" },
];

function Sidebar({ toggleNav, nav }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [navSize, changeNavSize] = useState("large");
  return (
    <Flex
      h="100%"
      pos="fixed"
      w={navSize === "small" ? "10px" : "100%"}
      alignItems={navSize === "small" ? "center" : "flex-start"}
    >
      <SidebarContent
        overflowY={navSize === "small" ? "none" : "auto"}
        onClose={onClose}
        currentSize={navSize}
        toggleSidebar={changeNavSize}
        toggleNav={toggleNav}
        nav={nav}
        display={{ base: "none", md: "block" }}
        style={{ transition: "0.3s ease" }}
      />
      <Drawer
        autoFocus={false}
        placement="left"
        returnFocusOnClose={false}
        onOverlayClick={onClose}
        size="full"
        w={navSize === "small" ? "10px" : "100%"}
      >
        <DrawerContent>
          <SidebarContent
            onClose={isOpen}
            currentSize={navSize}
            toggleSidebar={changeNavSize}
            toggleNav={toggleNav}
            nav={nav}
          />
        </DrawerContent>
      </Drawer>
      {/* mobilenav */}
      <MobileNav display={{ base: "flex", md: "none" }} onOpen={onOpen} />
      {/* <Box ml={{ base: 0, md: 60 }} p="4">
        {children}
      </Box> */}
    </Flex>
  );
}

const SidebarContent = ({
  onClose,
  currentSize,
  toggleSidebar,
  toggleNav,
  nav,
  ...rest
}) => {
  return (
    <Flex
      bg="white"
      style={{
        borderStyle: "none",
        borderWidth: "0px 1px",
        border: "1px solid #c4c4c4",
        transition: "0.5s ease",
      }}
      //   borderRight="1px"
      //   borderRightColor={useColorModeValue('gray.200', 'gray.700')}
      // w={{ base: "full", md: 60 }}
      w={currentSize == "small" ? "{base: 'full'}" : "220px"}
      h="100%"
      {...rest}
    >
      <Box h="20" align="center">
        {/* <IconButton
          size="lg"
          background="none"
          _hover={{ background: "none" }}
          icon={<FiMenu />}
          onClick={() => {
            console.log(nav);
            if (nav) {
              toggleNav(false);
              toggleSidebar("small");
            } else {
              toggleNav(true);
              toggleSidebar("large");
            }
          }}
        /> */}
        <Image
          h={{ base: "20" }}
          src={htmsLogo}
          alt="HolisticsLab Sdn Bhd"
          onClick={() => {
            if (nav) {
              toggleNav(false);
              toggleSidebar("small");
            } else {
              toggleNav(true);
              toggleSidebar("large");
            }
          }}
        />
      </Box>
      <Divider style={{ borderWidth: "1px 0px", borderColor: "#c4c4c4" }} />
      <br />
      {LinkItems.map(({ name, icon, src }, i) =>
        name == "Settings" ? (
          <div key={i}>
            <Divider
              variant="solid"
              style={{
                borderWidth: "1px 0px",
                borderColor: "#c4c4c4",
                marginTop: "20px",
                marginBottom: "20px",
              }}
            />
            <NavItem icon={icon} to={src} navSize={currentSize}>
              {name}
            </NavItem>
          </div>
        ) : (
          <div key={i}>
            <NavItem key={i} icon={icon} to={src} navSize={currentSize}>
              {name}
            </NavItem>
          </div>
        )
      )}

      {/* <CloseButton display={{ base: "flex", md: "none" }} onClick={onClose} /> */}
    </Flex>
  );
};

const NavItem = ({ icon, to, children, navSize, ...rest }) => {
  let match = useMatch(to);
  return (
    <Link
      to={to}
      style={{ textDecoration: "none" }}
      _focus={{ boxShadow: "none" }}
      w={navSize == "large" && "100%"}
    >
      <Flex
        p="4"
        mx="4"
        borderRadius="lg"
        role="group"
        cursor="pointer"
        bgColor={match && "white"}
        // dropShadow={""}
        // boxShadow={match && "base"}
        color={match && "#14804a"}
        fontWeight={match && "700"}
        _hover={{
          bgGradient: "linear(to-r, blue.400,green.400)",
          color: "white",
        }}
        alignItems={navSize === "small" ? "center" : "flex-start"}
        {...rest}
        style={{ transition: "0.2s ease-out" }}
      >
        {icon && (
          <Icon
            mr="4"
            fontSize="16"
            _groupHover={{
              color: "white",
            }}
            as={icon}
          />
        )}
        <Text display={navSize === "small" ? "none" : "flex"}>{children}</Text>
      </Flex>
    </Link>
  );
};

const MobileNav = ({ onOpen, ...rest }) => {
  return (
    <Flex
      ml={{ base: 0, md: 60 }}
      px={{ base: 4, md: 24 }}
      height="20"
      alignItems="center"
      bg="white"
      borderBottomWidth="1px"
      justifyContent="flex-start"
      {...rest}
    >
      <IconButton
        variant="outline"
        onClick={onOpen}
        aria-label="open menu"
        icon={<FiMenu />}
      />
    </Flex>
  );
};

export default Sidebar;
