import axios from "axios";

class CRUDFunctions {
  static getHeader = () => {
    // let token = localStorage.getItem("token");
    return [
      {
        headers: {
          // authorization: `Bearer ${token}`,
          ContentType: "application/json",
          Accept: "application/json",
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Credentials": "true",
        },
      },
    ];
  };

  static header = [
    {
      headers: {
        // authorization: `Bearer ${token}`,
        ContentType: "application/json",
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Credentials": "true",
      },
    },
  ];
  static create = async (URL, data) => {
    return new Promise((resolve) => {
      axios
        .post(URL, data, this.header)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          console.log(err);
          resolve(err.response.data);
        });
    });
  };

  static get = async (URL) => {
    return new Promise((resolve) => {
      axios
        .get(URL, this.header)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          console.log(err);
          resolve(err.response.data);
        });
    });
  };

  static update = async (URL, data) => {
    return new Promise((resolve) => {
      axios
        .post(URL, data, this.header)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          console.log(err);
          resolve(err.response.data);
        });
    });
  };

  static delete = async (URL) => {
    return new Promise((resolve) => {
      axios
        .delete(URL, this.header)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          console.log(err);
          resolve(err.response.data);
        });
    });
  };

  static createBill = async (URL, data) => {
    return new Promise((resolve) => {
      axios
        .post(URL, data, this.header)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          resolve(err.response.data);
        });
    });
  };

  static password = async (URL, data) => {
    return new Promise((resolve) => {
      axios
        .post(URL, data, this.header)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.log(err);
          resolve(err.response.data);
        });
    });
  };
}

export default CRUDFunctions;
