import React from "react";
import { Container, Box, Text, VStack, Button } from "@chakra-ui/react";
import { CheckIcon } from "@chakra-ui/icons";
import { FiXCircle } from "react-icons/fi";

function Failed() {
  return (
    <>
      <Container my="20">
        <Box
          p="40px 30px 20px 30px"
          style={{ boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.2)" }}
        >
          <VStack textAlign="center">
            <FiXCircle color="red" fontSize="80px" py="20px" />
            <Text fontSize="30px">Failed</Text>
            <Text fontSize="20px" color="gray" pb="20px">
              Your payment is failed and no amount is deducted. Please try
              again.
            </Text>
            <Box
              w="150px"
              p="5px"
              color="white"
              bgColor="#468c32"
              borderRadius="10px"
            >
              <a href="/client/course">Go to Home</a>
            </Box>
          </VStack>
        </Box>
      </Container>
    </>
  );
}

export { Failed };
