//training index

import React, { useState, useEffect } from "react";
import {
  Input,
  Heading,
  Container,
  Button,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  useDisclosure,
  Select,
  Icon,
  HStack,
  FormControl,
  FormLabel,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  Popover,
  PopoverTrigger,
  PopoverContent,
  Portal,
  PopoverArrow,
  PopoverBody,
  VStack,
  Center,
  Text,
  IconButton,
  Box,
  InputGroup,
  Grid,
  GridItem,
  NumberInput,
  NumberInputField,
  Textarea,
  Switch,
} from "@chakra-ui/react";
import { AddIcon, EditIcon, DeleteIcon } from "@chakra-ui/icons";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { FiDownload } from "react-icons/fi";
import { RiAddFill, RiDeleteBinLine } from "react-icons/ri";
import { BsCheckCircleFill, BsXCircleFill } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import { ReactTable, AlertPop, ErrorsDialog } from "../../../components";
import styled from "styled-components";
import { useForm, Controller } from "react-hook-form";
import CRUDFunction from "../../../functions/CRUDFunction";
import { URL_LOCAL, URL_STAGING } from "../../../constant";
import { WYSIWYGEditor } from "../../../components/rich-text-editor";
import * as XLSX from "xlsx";

const StylesTraining = styled.div`
  padding: 1rem;

  table {
    min-width: 100%;
    border-spacing: 0;
    text-align: center;

    tr {
      :hover {
          background-color: #ebebeb;
        }
      }
    }

    th {
      font-weight: bold;
      margin: 0;
      padding: 0.5rem;
      background-color: white;
      border-bottom: 1px solid #bdbdbd;
      // border-right: 1px solid black;
      :first-child {
        width: 20px;
      }
      :nth-child(2) {
        width: 150px;
      }
      :nth-child(3) {
        width: 150px;
      }
      :nth-child(4) {
        width: 10px;
      }
      :nth-child(5) {
        width: 10px;
      }
      :nth-child(6) {
        width: 50px;
      }
      :nth-child(7) {
        width: 140px;
      }
      :nth-child(8) {
        width: 100px;
      }
      :nth-child(9) {
        width: 40px;
      }
      :last-child {
        width: 10px;
      }
    }

    td {
      margin: 0;
      padding: 1rem;
      border-bottom: 1px solid #bdbdbd;
      :nth-child(3) {
        text-align: left;
      }
    }
  }
`;

function TrainingAdmin() {
  const addTraining = useDisclosure();
  const editTraining = useDisclosure();
  const dialogProps = useDisclosure();
  const navigate = useNavigate();
  const [session, setSession] = useState([{ startDate: "", endDate: "" }]);
  const [sessionStartDate, setSessionStartDate] = useState([]);
  const [sessionEndDate, setSessionEndDate] = useState([]);
  const [timeStart, setStart] = useState("");
  const [timeEnd, setEnd] = useState("");
  const time = [];
  const [data, setData] = useState({
    tableLoader: false,
    rowData: [],
    totalPages: 0,
    totalData: 0,
  });
  const [programNames, setProgramName] = useState([]);
  const [courseNames, setCourseName] = useState([]);
  const [trainingID, setTrainingId] = useState("");
  const [editData, setEditData] = useState([]);
  const [downloadData, setDownloadData] = useState([]);
  const [alert, setAlert] = useState({
    class: "hide",
    status: "",
    message: "",
  });
  const errorProps = useDisclosure();
  const downloadDialog = useDisclosure();
  const [errorAlert, setErrorAlert] = useState("");
  const [err, setErr] = useState({
    programme: false,
    course: false,
    name: false,
    mode: false,
    details: false,
    place: false,
    fee: false,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [currentRows, setCurrentRows] = useState(10);

  const resetErr = () => {
    setErr({
      programme: false,
      course: false,
      name: false,
      mode: false,
      details: false,
      place: false,
      fee: false,
    });
  };

  const clearErr = (e) => {
    if (e.target.value !== "") {
      if (e.target.id === "programme") {
        setErr((err) => ({ ...err, programme: false }));
      }
      if (e.target.id === "course") {
        setErr((err) => ({ ...err, course: false }));
      }
      if (e.target.id === "name") {
        setErr((err) => ({ ...err, name: false }));
      }
      if (e.target.id === "mode") {
        setErr((err) => ({ ...err, mode: false }));
      }
      if (e.target.id === "details") {
        setErr((err) => ({ ...err, details: false }));
      }
      if (e.target.id === "place") {
        setErr((err) => ({ ...err, place: false }));
      }
      if (e.target.id === "fee") {
        setErr((err) => ({ ...err, fee: false }));
      }
    }
  };

  const navTrainingDetail = (value) => {
    navigate("../trainee", {
      replace: false,
      state: {
        id: value[0],
        name: value[1],
        date: JSON.parse(value[2]),
        time: JSON.parse(value[3]),
        program_id: value[4],
        course_id: value[5],
      },
    });
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "#",
        accessor: "row.row.id",
        Cell: (row) => (
          <>
            <Text>{parseInt(row.row.id) + 1}</Text>
          </>
        ),
      },
      {
        Header: "PROGRAMME",
        accessor: "programme.program_name",
        Cell: ({ value }) => {
          return (
            <>
              <Text textAlign={"center"} fontSize={"14px"}>
                {value}
              </Text>
            </>
          );
        },
      },
      {
        Header: "TRAINING",
        accessor: (data) => {
          return [
            data.training_id,
            data.training_name,
            data.training_date,
            data.training_time,
            data.programme.program_id,
            data.course_id,
          ];
        },
        Cell: ({ value }) => (
          <>
            <Text
              fontSize={"14px"}
              style={{ cursor: "pointer" }}
              _hover={{ textDecoration: "underline" }}
              onClick={() => {
                navTrainingDetail(value);
              }}
            >
              {value[1]}
            </Text>
          </>
        ),
        disableSortBy: true,
      },
      {
        Header: "COHORT",
        accessor: "training_cohort",
      },
      {
        Header: "TRAINEE",
        accessor: "trainees.length",
      },
      {
        Header: "PLACE",
        accessor: "training_place",
        Cell: ({ value }) => (
          <>
            <Text textAlign={"center"} fontSize={"14px"}>
              {value}
            </Text>
          </>
        ),
      },
      {
        Header: "PERIOD",
        accessor: "training_date",
        Cell: ({ value }) => {
          let session = JSON.parse(value);
          return session.map((date, index) => (
            <>
              {" "}
              <Text textAlign={"center"} fontWeight={"700"} fontSize={"14px"}>
                Week {index + 1}
              </Text>
              <Text textAlign={"center"} fontSize={"14px"}>
                Start Date: {date.startDate}
              </Text>
              <Text textAlign={"center"} fontSize={"14px"}>
                End Date: {date.endDate}
              </Text>
              <br />
            </>
          ));
        },
      },
      {
        Header: "TIME",
        accessor: "training_time",
        Cell: ({ value }) => {
          if (value === null) {
            return <Text>----</Text>;
          } else {
            let time = JSON.parse(value);
            let start = time[0];
            let end = time[1];

            return (
              <Text textAlign={"center"} fontSize={"14px"}>
                {Number(start[0] * 10) + Number(start[1]) < 11 ? (
                  <>
                    {Number(start[0] * 10) + Number(start[1])}
                    {start.slice(2)} AM
                  </>
                ) : Number(start[0] * 10) + Number(start[1]) > 12 ? (
                  <>
                    {Number(start[0] * 10) + Number(start[1]) - 12}
                    {start.slice(2)} PM
                  </>
                ) : (
                  <>
                    {Number(start[0] * 10) + Number(start[1])}
                    {start.slice(2)} PM
                  </>
                )}{" "}
                -{" "}
                {Number(end[0] * 10) + Number(end[1]) < 11 ? (
                  <>
                    {Number(end[0] * 10) + Number(end[1])}
                    {end.slice(2)} AM
                  </>
                ) : Number(end[0] * 10) + Number(end[1]) > 12 ? (
                  <>
                    {Number(end[0] * 10) + Number(end[1]) - 12}
                    {end.slice(2)} PM
                  </>
                ) : (
                  <>
                    {Number(end[0] * 10) + Number(end[1])}
                    {end.slice(2)} PM
                  </>
                )}
              </Text>
            );
          }
        },
      },
      {
        Header: "HRDC Claim",
        accessor: "training_hrdc",
        Cell: ({ value }) => {
          return (
            <Text color={value === 1 ? "#1DB700" : "black"}>
              {value === 1 ? (
                <Center>
                  <BsCheckCircleFill color="green" />
                </Center>
              ) : (
                <Center>
                  <BsXCircleFill color="red" />
                </Center>
              )}
            </Text>
          );
        },
        disableSortBy: true,
      },
      {
        Header: "ACTIONS",
        accessor: "training_id",
        disableSortBy: true,
        Cell: ({ value }) => (
          <>
            <Menu>
              <MenuButton
                as={Button}
                bgColor="none"
                _hover={{ bgColor: "#ebebeb" }}
                _expanded={{ bgColor: "#ebebeb" }}
                _click={{ bgColor: "#ebebeb" }}
                variant="ghost"
                rightIcon={<BiDotsVerticalRounded boxsize={5} />}
              ></MenuButton>
              <MenuList minW="100px">
                <MenuItem
                  fontSize="15px"
                  icon={<EditIcon boxsize={5} color="#14804a" />}
                  onClick={async () => {
                    const res = await CRUDFunction.get(
                      `${URL_STAGING}training/${value}`
                    );
                    if (res) {
                      setTrainingId(value);
                      setEditData(res);
                      setSession(JSON.parse(res.training_date));
                      let arrStartDate = JSON.parse(res.training_date).map(
                        (i) => {
                          return i.startDate;
                        }
                      );
                      let arrEndDate = JSON.parse(res.training_date).map(
                        (i) => {
                          return i.endDate;
                        }
                      );
                      setSessionStartDate(arrStartDate);
                      setSessionEndDate(arrEndDate);
                      if (res.training_time !== null) {
                        setStart(JSON.parse(res.training_time)[0]);
                        setEnd(JSON.parse(res.training_time)[1]);
                      }
                      editTraining.onOpen();
                    }
                  }}
                >
                  Edit
                </MenuItem>
                <MenuItem
                  fontSize="15px"
                  icon={<FiDownload boxsize={5} />}
                  onClick={async () => {
                    const res = await CRUDFunction.get(
                      `${URL_STAGING}training/${value}`
                    );
                    if (res) {
                      setDownloadData(res);
                    }
                    downloadDialog.onOpen();
                  }}
                >
                  Download
                </MenuItem>
                <MenuItem
                  fontSize="15px"
                  icon={<DeleteIcon boxsize={5} color="#d12953" />}
                  onClick={() => {
                    setTrainingId(value);
                    dialogProps.onOpen();
                  }}
                >
                  Delete
                </MenuItem>
              </MenuList>
            </Menu>
          </>
        ),
      },
    ],
    [dialogProps, editTraining, navTrainingDetail]
  );

  const handleRemoveClick = (index) => {
    const list = [...session];
    const startDateList = [...sessionStartDate];
    const endDateList = [...sessionEndDate];
    list.splice(index, 1);
    startDateList.splice(index, 1);
    endDateList.splice(index, 1);
    setSession(list);
    setSessionStartDate(startDateList);
    setSessionEndDate(endDateList);
  };

  const handleAddClick = () => {
    // 1. Make a shallow copy of the items
    let items = [...session];
    // 2. Make a shallow copy of the item you want to mutate
    let item = { ...items[items.length - 1] };
    // 3. Replace the property you're intested in
    item.startDate = sessionStartDate[items.length - 1];
    item.endDate = sessionEndDate[items.length - 1];
    // 4. Put it back into our array. N.B. we *are* mutating the array here,
    //    but that's why we made a copy first
    items[items.length - 1] = item;
    // 5. Set the state to our new copy
    setSession([...items, { startDate: "", endDate: "" }]);
  };

  const resetSession = () => {
    // setSession("");
    session.length = 1;
    setSessionStartDate([]);
    setSessionEndDate([]);
    setStart("");
    setEnd("");
  };

  useEffect(() => {
    setData((prevState) => ({
      ...prevState,
      rowData: [],
      tableLoader: true,
    }));
    fetchData(currentRows, currentPage);
    fetchProgram();
    fetchCourse();
  }, [currentRows, currentPage]);

  const fetchData = async (rowsNo, pageNo) => {
    const res = await CRUDFunction.get(
      `${URL_STAGING}trainingPage/${rowsNo}?page=${pageNo}`
    );
    if (res) {
      setData({
        rowData: res.data,
        tableLoader: false,
        totalPages: res.last_page,
        totalData: res.total,
      });
    }
  };

  const fetchCourse = async () => {
    const res = await CRUDFunction.get(`${URL_STAGING}coursename`);
    if (res) {
      setCourseName(res);
    }
  };

  const fetchProgram = async () => {
    const res = await CRUDFunction.get(`${URL_STAGING}programmename`);
    if (res) {
      setProgramName(res);
    }
  };

  const { register, handleSubmit, reset, control } = useForm();

  const timer = () => {
    setTimeout(function () {
      setAlert({ ...alert, class: "hide" });
      errorProps.onClose();
    }, 5000);
  };

  const onSubmit = async (data) => {
    if (data) {
      if (data.courseID === "") {
        setErr((err) => ({ ...err, course: true }));
      } else {
        setErr((err) => ({ ...err, course: false }));
      }

      if (data.programID === "") {
        setErr((err) => ({ ...err, programme: true }));
      } else {
        setErr((err) => ({ ...err, programme: false }));
      }

      if (data.trainingDetail === "") {
        setErr((err) => ({ ...err, details: true }));
      } else {
        setErr((err) => ({ ...err, details: false }));
      }

      if (data.trainingMode === "") {
        setErr((err) => ({ ...err, mode: true }));
      } else {
        setErr((err) => ({ ...err, mode: false }));
      }

      if (data.trainingName === "") {
        setErr((err) => ({ ...err, name: true }));
      } else {
        setErr((err) => ({ ...err, name: false }));
      }

      if (data.trainingPlace === "") {
        setErr((err) => ({ ...err, place: true }));
      } else {
        setErr((err) => ({ ...err, place: false }));
      }

      if (data.trainingFee === "") {
        setErr((err) => ({ ...err, fee: true }));
      } else {
        setErr((err) => ({ ...err, fee: false }));
      }

      if (data.trainingCohort === "") {
        setErr((err) => ({ ...err, cohort: true }));
      } else {
        setErr((err) => ({ ...err, cohort: false }));
      }

      if (data.trainingPayment === "default") {
        setErr((err) => ({ ...err, pay: true }));
      } else {
        setErr((err) => ({ ...err, pay: false }));
      }

      if (data.trainingPurchase === "default") {
        setErr((err) => ({ ...err, purchase: true }));
      } else {
        setErr((err) => ({ ...err, purchase: false }));
      }

      const formData = new FormData();
      formData.append("program_id", data.programID);
      formData.append("tm_id", data.tmID);
      formData.append("course_id", data.courseID);
      formData.append("training_name", data.trainingName);
      formData.append("training_cohort", data.trainingCohort);

      if (editTraining.isOpen) {
        let trainingDate = session.map((x, index) => {
          return {
            startDate: sessionStartDate[index],
            endDate: sessionEndDate[index],
          };
        });
        formData.append("training_date", JSON.stringify(trainingDate));
      } else if (session.length > 1) {
        let items = [...session];
        let item = { ...items[items.length - 1] };
        item.startDate = sessionStartDate[items.length - 1];
        item.endDate = sessionEndDate[items.length - 1];
        items[items.length - 1] = item;
        formData.append("training_date", JSON.stringify(items));
      } else {
        let item = [
          {
            startDate: sessionStartDate[0],
            endDate: sessionEndDate[0],
          },
        ];
        formData.append("training_date", JSON.stringify(item));
      }
      time.push(timeStart);
      time.push(timeEnd);
      formData.append("training_time", JSON.stringify(time));
      formData.append("training_mode", data.trainingMode);
      formData.append("training_detail", data.trainingDetail);
      formData.append("training_place", data.trainingPlace);
      formData.append("training_fee", data.trainingFee);
      formData.append("training_payment", data.trainingPayment);
      formData.append("training_sponsorship", data.trainingSponsorship);
      formData.append("training_purchase", data.trainingPurchase);
      if (data.trainingHrdc === true) {
        formData.append("training_hrdc", 1);
      } else if (data.trainingHrdc === false) {
        formData.append("training_hrdc", 0);
      }
      // for (var pair of formData.entries()) {
      //   console.log(pair[0] + ", " + pair[1]);
      // }

      if (addTraining.isOpen) {
        const res = await CRUDFunction.create(
          `${URL_STAGING}training`,
          formData
        );

        if (res.errors) {
          setErrorAlert(res.message);
          errorProps.onOpen();
          timer();
        } else {
          setData({
            rowData: res.data,
            tableLoader: false,
            totalPages: res.last_page,
            totalData: res.total,
          });
          setSessionStartDate([]);
          setSessionEndDate([]);
          reset();
          resetErr();
          resetSession();
          setAlert({
            ...alert,
            class: "show",
            status: "success",
            message: "Data is successfully created.",
          });
          timer();
          addTraining.onClose();
        }
      } else if (editTraining.isOpen) {
        const res = await CRUDFunction.update(
          `${URL_STAGING}training/update/${trainingID}`,
          formData
        );

        if (res.errors) {
          setErrorAlert(res.message);
          errorProps.onOpen();
          timer();
        } else {
          setData({
            rowData: res.data,
            tableLoader: false,
            totalPages: res.last_page,
            totalData: res.total,
          });
          setSessionStartDate([]);
          setSessionEndDate([]);
          reset();
          resetErr();
          resetSession();
          setAlert({
            ...alert,
            class: "show",
            status: "success",
            message: "Data is successfully updated.",
          });
          timer();
          editTraining.onClose();
        }
      }
    }
  };

  return (
    <div>
      {alert.message ? (
        <AlertPop
          width="30%"
          className={alert.class}
          status={alert.status}
          title={alert.message}
        />
      ) : (
        ""
      )}
      <Heading m="25px 0px 25px 100px">TRAINING</Heading>
      <Container
        maxW={"90%"}
        style={{
          backgroundColor: "white",
          borderRadius: "10px",
        }}
      >
        <div style={{ position: "relative" }}>
          <Popover>
            {/* <PopoverTrigger>
              <Button
                pos="absolute"
                right="220"
                top="37"
                _hover={{
                  backgroundColor: "#14804a !important",
                  color: "white !important",
                }}
                style={{
                  backgroundColor: "white",
                  color: "#14804a",
                  padding: "14px",
                  fontSize: "14px",
                  border: "1px solid #14804a",
                }}
                width="200px"
              >
                <Icon fontSize="16" as={FiDownload} />
                &nbsp; Download Excel
              </Button>
            </PopoverTrigger> */}
            <Portal boxShadow="0 4px 12px rgba(55, 81, 255, 0.24)">
              <PopoverContent bg="white">
                <PopoverArrow />
                <PopoverBody>
                  <VStack>
                    <Text p="60px 40px 0px 40px" fontWeight="bold">
                      Download Excel
                    </Text>
                    <Text p="0px 40px 0px 40px">by</Text>
                    <Center>
                      <HStack p="20px 40px 60px 40px">
                        <Button
                          w="120px"
                          bgColor="#4b85fa"
                          boxShadow="xl"
                          _hover={{ bgColor: "blue.300" }}
                        >
                          Cohort
                        </Button>
                        <Button
                          w="120px"
                          bgColor="#f9c611"
                          boxShadow="xl"
                          _hover={{ bgColor: "yellow.300" }}
                        >
                          Price
                        </Button>
                      </HStack>
                    </Center>
                  </VStack>
                </PopoverBody>
              </PopoverContent>
            </Portal>
          </Popover>
          <Button
            leftIcon={<AddIcon />}
            pos="absolute"
            right="2"
            top="37"
            _hover={{
              bg: "green.800 !important",
            }}
            style={{
              backgroundColor: "#33945F",
              color: "#ffffff",
              padding: "14px",
              fontSize: "14px",
            }}
            width="200px"
            onClick={addTraining.onOpen}
          >
            Add Training
          </Button>
        </div>
        <StylesTraining>
          <ReactTable
            columns={columns}
            data={data.rowData}
            tableLoader={data.tableLoader}
            totalRows={data.totalData}
            pageChangeHandler={setCurrentPage}
            modulePage="training"
            rowsPerPage={currentRows}
            handleData={setData}
            setCurrentRows={setCurrentRows}
            fetchData={fetchData}
          />
        </StylesTraining>
      </Container>

      <AlertDialog
        isCentered
        isOpen={dialogProps.isOpen}
        // leastDestructiveRef={cancelRef}
        onClose={dialogProps.onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader
              pt="50px"
              textAlign="center"
              fontSize="lg"
              fontWeight="bold"
            >
              Delete Training
            </AlertDialogHeader>

            <AlertDialogBody textAlign="center">
              Do you want to delete this training?
            </AlertDialogBody>

            <Center>
              <AlertDialogFooter pb="50px">
                <Button
                  w="150px"
                  colorScheme="red"
                  onClick={async () => {
                    const res = await CRUDFunction.delete(
                      `${URL_STAGING}training/${trainingID}`
                    );
                    if (res) {
                      setData({
                        rowData: res.data,
                        tableLoader: false,
                        totalPages: res.last_page,
                        totalData: res.total,
                      });
                      setAlert({
                        ...alert,
                        class: "show",
                        status: "success",
                        message: "Data is deleted.",
                      });
                      timer();
                      dialogProps.onClose();
                    }
                  }}
                >
                  Delete
                </Button>
                <Button w="150px" ml={3} onClick={dialogProps.onClose}>
                  Cancel
                </Button>
              </AlertDialogFooter>
            </Center>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>

      {/* ------------------------------------start add drawer------------------------ */}
      <Drawer
        isOpen={addTraining.isOpen}
        placement="right"
        onClose={() => {
          reset();
          resetErr();
          resetSession();
          addTraining.onClose();
        }}
        size="lg"
        fontSize="14px"
      >
        <DrawerOverlay />
        <DrawerContent overflowY="auto">
          <form onSubmit={handleSubmit(onSubmit)}>
            <DrawerCloseButton />
            <DrawerHeader textAlign="center" pt="7%" fontSize="25px">
              ADD TRAINING
            </DrawerHeader>

            <DrawerBody>
              <FormControl isInvalid={err.programme} pt="20px">
                <FormLabel fontWeight="bold">
                  PROGRAMME <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Select
                  id="programme"
                  placeholder="Select programme"
                  {...register("programID")}
                  onBlur={clearErr}
                >
                  {programNames.map((elem) => (
                    <option value={elem.program_id}>{elem.program_name}</option>
                  ))}
                </Select>
              </FormControl>
              {err.programme && (
                <span style={{ color: "red" }}>This field is required</span>
              )}
              <FormControl isInvalid={err.course} pt="20px">
                <FormLabel fontWeight="bold">
                  COURSE <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Select
                  id="course"
                  placeholder="Select course"
                  {...register("courseID")}
                  onBlur={clearErr}
                >
                  {courseNames.map((elem) => (
                    <option value={elem.course_id}>{elem.course_name}</option>
                  ))}
                </Select>
              </FormControl>
              {err.course && (
                <span style={{ color: "red" }}>This field is required</span>
              )}
              <FormControl isInvalid={err.name} pt="20px">
                <FormLabel fontWeight="bold">
                  TRAINING NAME <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Input
                  id="name"
                  placeholder="Training name"
                  {...register("trainingName")}
                  onBlur={clearErr}
                ></Input>
              </FormControl>
              {err.name && (
                <span style={{ color: "red" }}>This field is required</span>
              )}

              <FormControl isInvalid={err.cohort} pt="20px">
                <FormLabel fontWeight="bold">
                  COHORT <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Input
                  id="cohort"
                  placeholder="Cohort"
                  {...register("trainingCohort")}
                  onBlur={clearErr}
                ></Input>
              </FormControl>
              {err.cohort && (
                <span style={{ color: "red" }}>This field is required</span>
              )}

              <HStack m="20px 0px 5px 0px">
                <b>TRAINING WEEK DATE</b>
                <IconButton
                  size="xs"
                  icon={<RiAddFill />}
                  onClick={handleAddClick}
                />
              </HStack>
              <Grid
                templateColumns="repeat(2, 1fr)"
                gap={5}
                // mx="20px"
                fontSize="14px"
                textAlign="center"
              >
                <GridItem w="100%" h="5" fontWeight="bold">
                  START DATE
                </GridItem>
                <GridItem w="100%" h="5" fontWeight="bold">
                  END DATE
                </GridItem>
              </Grid>
              <Box mb="20px">
                {session.map((x, index) => {
                  return (
                    <>
                      <HStack mb="5px">
                        <Text fontStyle="italic" fontSize="15px">
                          {index + 1}
                        </Text>
                        <InputGroup>
                          <Input
                            type="date"
                            onChange={(e) => {
                              let items = [...sessionStartDate];
                              items[index] = e.target.value;
                              setSessionStartDate(items);
                            }}
                          />
                        </InputGroup>
                        <InputGroup>
                          <Input
                            type="date"
                            min={sessionStartDate[index]}
                            onChange={(e) => {
                              let items = [...sessionEndDate];
                              items[index] = e.target.value;
                              setSessionEndDate(items);
                            }}
                          />
                        </InputGroup>
                        <div>
                          {session.length !== 1 && (
                            <IconButton
                              icon={<RiDeleteBinLine />}
                              variant="ghost"
                              color="red"
                              onClick={() => handleRemoveClick(index)}
                            />
                          )}
                        </div>
                      </HStack>
                    </>
                  );
                })}
              </Box>

              <FormControl>
                <FormLabel fontWeight="bold">
                  TRAINING TIME <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <HStack>
                  <Input
                    type="time"
                    onChange={(e) => {
                      setStart(e.target.value);
                    }}
                  />
                  <b>-</b>
                  <Input
                    type="time"
                    onChange={(e) => {
                      setEnd(e.target.value);
                    }}
                  />
                </HStack>
              </FormControl>

              <FormControl isInvalid={err.mode} pt="20px">
                <FormLabel fontWeight="bold">
                  TRAINING MODE <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Select
                  id="mode"
                  placeholder="Select training mode"
                  {...register("trainingMode")}
                  onBlur={clearErr}
                >
                  <option value={0}>Fulltime online inhouse</option>
                  <option value={1}>Fulltime physical inhouse</option>
                  <option value={2}>Parttime online inhouse</option>
                  <option value={3}>Parttime physical inhouse</option>
                  <option value={4}>Fulltime online public</option>
                  <option value={5}>Fulltime physical public</option>
                  <option value={6}>Parttime online public</option>
                  <option value={7}>Parttime physical public</option>
                </Select>
              </FormControl>
              {err.mode && (
                <span style={{ color: "red" }}>This field is required</span>
              )}
              <FormControl isInvalid={err.details} pt="20px">
                <FormLabel fontWeight="bold">
                  TRAINING DETAILS <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Controller
                  render={({ field }) => <WYSIWYGEditor {...field} />}
                  name="trainingDetail"
                  control={control}
                  defaultValue=""
                  {...register("trainingDetail")}
                />
                {/* <Textarea
                  id="details"
                  resize="none"
                  h="120px"
                  placeholder="Training details"
                  {...register("trainingDetail")}
                  onBlur={clearErr}
                ></Textarea> */}
              </FormControl>
              {err.details && (
                <span style={{ color: "red" }}>This field is required</span>
              )}
              <FormControl isInvalid={err.place} pt="20px">
                <FormLabel fontWeight="bold">
                  TRAINING PLACE <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Input
                  id="place"
                  placeholder="Place"
                  {...register("trainingPlace")}
                  onBlur={clearErr}
                ></Input>
              </FormControl>
              {err.place && (
                <span style={{ color: "red" }}>This field is required</span>
              )}
              <FormControl isInvalid={err.fee} pt="20px">
                <FormLabel fontWeight="bold">
                  FEE <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <NumberInput
                  id="fee"
                  precision={2}
                  step={0.2}
                  {...register("trainingFee")}
                >
                  <NumberInputField placeholder="RM" onBlur={clearErr} />
                </NumberInput>
              </FormControl>
              {err.fee && (
                <span style={{ color: "red" }}>This field is required</span>
              )}

              <FormControl>
                <FormLabel pt="20px" fontWeight="bold">
                  PAYMENT TYPE <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Select w="100%" {...register("trainingPayment")}>
                  <option value="default">Select payment type</option>
                  <option value="Paid">Paid</option>
                  <option value="Grant">Grant</option>
                </Select>
              </FormControl>

              <FormControl pt="20px">
                <FormLabel fontWeight="bold">SPONSORSHIP</FormLabel>
                <Input
                  id="sponsorship"
                  placeholder="Sponsorship"
                  {...register("trainingSponsorship")}
                  onBlur={clearErr}
                ></Input>
              </FormControl>
              {/* {err.sponsorship && (
                <span style={{ color: "red" }}>This field is required</span>
              )} */}

              <FormControl>
                <FormLabel pt="20px" fontWeight="bold">
                  PURCHASE TYPE <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Select w="100%" {...register("trainingPurchase")}>
                  <option value="default">Select purchase type</option>
                  <option value="Normal">Normal</option>
                  <option value="Bulk">Bulk</option>
                </Select>
              </FormControl>

              <FormControl
                isInvalid={err.hrdc}
                display="flex"
                alignItems="center"
                pt="20px"
              >
                <FormLabel htmlFor="hrdc" mb="0">
                  HRDC Claimable?
                </FormLabel>
                <Switch size="sm" id="hrdc" {...register("trainingHrdc")} />
              </FormControl>
            </DrawerBody>

            <Center>
              <DrawerFooter>
                <Button
                  fontWeight="medium"
                  w="200px"
                  color="white"
                  bg="#33945f"
                  _hover={{ bg: "green.700" }}
                  type="submit"
                >
                  Add
                </Button>
                <Button
                  fontWeight="medium"
                  w="200px"
                  variant="outline"
                  ml={3}
                  onClick={() => {
                    reset();
                    resetErr();
                    resetSession();
                    addTraining.onClose();
                  }}
                >
                  Cancel
                </Button>
              </DrawerFooter>
            </Center>
          </form>
        </DrawerContent>
      </Drawer>

      {/*  -----------------------------start edit drawer----------------------------- */}
      <Drawer
        isOpen={editTraining.isOpen}
        placement="right"
        onClose={() => {
          reset();
          resetErr();
          resetSession();
          editTraining.onClose();
        }}
        size="lg"
        fontSize="14px"
      >
        <DrawerOverlay />
        <DrawerContent overflowY="auto">
          <form onSubmit={handleSubmit(onSubmit)}>
            <DrawerCloseButton />
            <DrawerHeader textAlign="center" pt="7%" fontSize="25px">
              EDIT TRAINING
            </DrawerHeader>

            <DrawerBody>
              <FormControl isInvalid={err.programme} pt="20px">
                <FormLabel fontWeight="bold">
                  PROGRAMME <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Select
                  id="programme"
                  placeholder="Select programme"
                  defaultValue={editData.program_id}
                  {...register("programID")}
                >
                  {programNames.map((elem) => (
                    <option value={elem.program_id}>{elem.program_name}</option>
                  ))}
                </Select>
              </FormControl>
              {err.programme && (
                <span style={{ color: "red" }}>This field is required</span>
              )}
              <FormControl isInvalid={err.course} pt="20px">
                <FormLabel fontWeight="bold">
                  COURSE <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Select
                  id="course"
                  placeholder="Select course"
                  defaultValue={editData.course_id}
                  {...register("courseID")}
                >
                  {courseNames.map((elem) => (
                    <option value={elem.course_id}>{elem.course_name}</option>
                  ))}
                </Select>
              </FormControl>
              {err.course && (
                <span style={{ color: "red" }}>This field is required</span>
              )}
              <FormControl isInvalid={err.name} pt="20px">
                <FormLabel fontWeight="bold">
                  TRAINING NAME <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Input
                  id="name"
                  placeholder="Training name"
                  defaultValue={editData.training_name}
                  {...register("trainingName")}
                ></Input>
              </FormControl>
              {err.name && (
                <span style={{ color: "red" }}>This field is required</span>
              )}

              <FormControl isInvalid={err.cohort} pt="20px">
                <FormLabel fontWeight="bold">
                  COHORT <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Input
                  id="cohort"
                  placeholder="Cohort"
                  defaultValue={editData.training_cohort}
                  {...register("trainingCohort")}
                  onBlur={clearErr}
                ></Input>
              </FormControl>
              {err.cohort && (
                <span style={{ color: "red" }}>This field is required</span>
              )}

              <HStack m="20px 0px 5px 0px">
                <b>
                  TRAINING SESSION DATE <span style={{ color: "red" }}>*</span>
                </b>
                <IconButton
                  size="xs"
                  icon={<RiAddFill />}
                  onClick={handleAddClick}
                />
              </HStack>
              <Grid
                templateColumns="repeat(2, 1fr)"
                gap={5}
                // mx="20px"
                fontSize="14px"
                textAlign="center"
              >
                <GridItem w="100%" h="5" fontWeight="bold">
                  START DATE
                </GridItem>
                <GridItem w="100%" h="5" fontWeight="bold">
                  END DATE
                </GridItem>
              </Grid>
              <Box mb="20px">
                {session.map((item, i) => {
                  return (
                    <>
                      <HStack mb="5px">
                        <Text fontStyle="italic" fontSize="15px">
                          {i + 1}
                        </Text>
                        <InputGroup>
                          <Input
                            type="date"
                            defaultValue={item.startDate}
                            onChange={(e) => {
                              let items = [...sessionStartDate];
                              items[i] = e.target.value;
                              setSessionStartDate(items);
                            }}
                          />
                        </InputGroup>
                        <InputGroup>
                          <Input
                            type="date"
                            defaultValue={item.endDate}
                            onChange={(e) => {
                              let items = [...sessionEndDate];
                              items[i] = e.target.value;
                              setSessionEndDate(items);
                            }}
                          />
                        </InputGroup>
                        <div>
                          {session.length !== 1 && (
                            <IconButton
                              icon={<RiDeleteBinLine />}
                              variant="ghost"
                              color="red"
                              onClick={() => handleRemoveClick(i)}
                            />
                          )}
                        </div>
                      </HStack>
                    </>
                  );
                })}
              </Box>

              <FormControl>
                <FormLabel fontWeight="bold">
                  TRAINING TIME <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <HStack>
                  <Input
                    type="time"
                    defaultValue={timeStart}
                    onChange={(e) => {
                      setStart(e.target.value);
                    }}
                  />
                  <b>-</b>
                  <Input
                    type="time"
                    defaultValue={timeEnd}
                    onChange={(e) => {
                      setEnd(e.target.value);
                    }}
                  />
                </HStack>
              </FormControl>

              <FormControl isInvalid={err.mode} pt="20px">
                <FormLabel fontWeight="bold">
                  TRAINING MODE <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Select
                  id="mode"
                  placeholder="Select training mode"
                  defaultValue={editData.training_mode}
                  {...register("trainingMode")}
                >
                  <option value={0}>Fulltime online inhouse</option>
                  <option value={1}>Fulltime physical inhouse</option>
                  <option value={2}>Parttime online inhouse</option>
                  <option value={3}>Parttime physical inhouse</option>
                  <option value={4}>Fulltime online public</option>
                  <option value={5}>Fulltime physical public</option>
                  <option value={6}>Parttime online public</option>
                  <option value={7}>Parttime physical public</option>
                </Select>
              </FormControl>
              {err.mode && (
                <span style={{ color: "red" }}>This field is required</span>
              )}
              <FormControl isInvalid={err.details} pt="20px">
                <FormLabel fontWeight="bold">
                  TRAINING DETAILS <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Controller
                  render={({ field }) => <WYSIWYGEditor {...field} />}
                  name="trainingDetail"
                  control={control}
                  defaultValue={editData.training_detail}
                  {...register("trainingDetail")}
                />
                {/* <Textarea
                  id="details"
                  resize="none"
                  h="120px"
                  placeholder="Training details"
                  defaultValue={editData.training_detail}
                  {...register("trainingDetail")}
                ></Textarea> */}
              </FormControl>
              {err.details && (
                <span style={{ color: "red" }}>This field is required</span>
              )}
              <FormControl isInvalid={err.place} pt="20px">
                <FormLabel fontWeight="bold">
                  TRAINING PLACE <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Input
                  id="place"
                  placeholder="Place"
                  defaultValue={editData.training_place}
                  {...register("trainingPlace")}
                ></Input>
              </FormControl>
              {err.place && (
                <span style={{ color: "red" }}>This field is required</span>
              )}
              <FormControl isInvalid={err.fee} pt="20px">
                <FormLabel fontWeight="bold">
                  FEE <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <NumberInput
                  id="fee"
                  precision={2}
                  step={0.2}
                  defaultValue={editData.training_fee}
                  {...register("trainingFee")}
                >
                  <NumberInputField placeholder="RM" />
                </NumberInput>
              </FormControl>
              {err.fee && (
                <span style={{ color: "red" }}>This field is required</span>
              )}

              <FormControl>
                <FormLabel mt="10px" fontWeight="bold">
                  PAYMENT TYPE <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Select
                  defaultValue={editData.training_payment}
                  w="100%"
                  {...register("trainingPayment")}
                >
                  <option value="default">Select payment type</option>
                  <option value="Paid">Paid</option>
                  <option value="Grant">Grant</option>
                </Select>
              </FormControl>

              <FormControl pt="20px">
                <FormLabel fontWeight="bold">SPONSORSHIP</FormLabel>
                <Input
                  id="sponsorship"
                  placeholder="Sponsorship"
                  defaultValue={editData.training_sponsorship}
                  {...register("trainingSponsorship")}
                  onBlur={clearErr}
                ></Input>
              </FormControl>
              {/* {err.sponsorship && (
                <span style={{ color: "red" }}>This field is required</span>
              )} */}

              <FormControl>
                <FormLabel mt="10px" fontWeight="bold">
                  PURCHASE TYPE <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Select
                  defaultValue={editData.training_purchase}
                  w="100%"
                  {...register("trainingPurchase")}
                >
                  <option value="default">Select purchase type</option>
                  <option value="Normal">Normal</option>
                  <option value="Bulk">Bulk</option>
                </Select>
              </FormControl>

              <FormControl
                isInvalid={err.hrdc}
                display="flex"
                alignItems="center"
                pt="20px"
              >
                <FormLabel htmlFor="hrdc" mb="0">
                  HRDC Claimable?
                </FormLabel>
                <Switch
                  defaultChecked={editData.training_hrdc === 1 ? true : false}
                  size="sm"
                  id="hrdc"
                  {...register("trainingHrdc")}
                />
              </FormControl>
            </DrawerBody>

            <Center>
              <DrawerFooter>
                <Button
                  fontWeight="medium"
                  w="200px"
                  color="white"
                  bg="#33945f"
                  _hover={{ bg: "green.700" }}
                  type="submit"
                >
                  Save
                </Button>
                <Button
                  fontWeight="medium"
                  w="200px"
                  variant="outline"
                  ml={3}
                  onClick={() => {
                    reset();
                    resetErr();
                    setSessionStartDate([]);
                    setSessionEndDate([]);
                    editTraining.onClose();
                  }}
                >
                  Cancel
                </Button>
              </DrawerFooter>
            </Center>
          </form>
        </DrawerContent>
      </Drawer>
      <ErrorsDialog
        title={"Errors"}
        dialogProps={errorProps}
        body={errorAlert}
      />
      {/* ----------------------------------modal excel download-------------------------- */}
      <AlertDialog
        isOpen={downloadDialog.isOpen}
        onClose={downloadDialog.onClose}
      >
        <AlertDialogOverlay>
          <AlertDialogContent textAlign="center" p="15px">
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Download Excel Training?
            </AlertDialogHeader>

            <AlertDialogBody>{downloadData.training_name}</AlertDialogBody>

            <Button
              size="sm"
              mx="50px"
              onClick={() => {
                let dateArr = JSON.parse(downloadData.training_date);
                let startDate = dateArr[0].startDate.replace(/-/g, "/");
                let endDate;
                if (dateArr.length < 1) {
                  endDate = dateArr[0].endDate.replace(/-/g, "/");
                } else {
                  endDate = dateArr[dateArr.length - 1].endDate.replace(
                    /-/g,
                    "/"
                  );
                }
                let duration =
                  (new Date(endDate).getTime() -
                    new Date(startDate).getTime()) /
                  (1000 * 3600 * 24);

                let trainingMode = "";
                if (downloadData.training_mode % 2 === 0) {
                  trainingMode = "Online Class";
                } else {
                  trainingMode = "Physical Class";
                }

                let trainingType = "";
                if (downloadData.training_mode > 3) {
                  trainingType = "Public";
                } else {
                  trainingType = "In House";
                }

                let hrdc = "";
                if (downloadData.training_hrdc === 1) {
                  hrdc = "Yes";
                } else {
                  hrdc = "No";
                }

                let excelCol = [];
                excelCol.unshift([
                  startDate.slice(0, 4),
                  downloadData.course_id,
                  startDate + " - " + endDate,
                  startDate.slice(5, 7),
                  duration,
                  downloadData.training_fee,
                  downloadData.training_fee * 0.23,
                  0,
                  trainingMode,
                  trainingType,
                  downloadData.training_payment,
                  downloadData.training_sponsorship,
                  downloadData.training_purchase,
                  downloadData.training_cohort,
                  downloadData.trainees.length,
                  0, //number of participant by company
                  "Test", //company name
                  "-", //sector
                  "Johor", //state
                  "-", //total branch
                  "-", //bumi/non-bumi
                  "-", //number of employee
                  "-", //expired date
                  hrdc,
                  "-", //duration company join before expired date (in month)
                  "-", //pic name
                  "-", //pic number
                  "-", //pic email
                ]);
                excelCol.unshift([
                  "YEAR",
                  "COURSE",
                  "DATE",
                  "MONTH",
                  "DURATION",
                  "FEES (RM)",
                  "FEES (USD)",
                  "TOTAL FEES (RM)",
                  "COURSE MODE",
                  "TYPE OF TRAINING",
                  "PAYMENT TYPE",
                  "SPONSORSHIP",
                  "PURCHASE TYPE",
                  "COHORT",
                  "TOTAL PARTICIPANT",
                  "NUMBER OF PARTICIPANT",
                  "COMPANY",
                  "SECTOR",
                  "STATE",
                  "TOTAL BRANCH",
                  "BUMI/NON-BUMI",
                  "NUMBER OF EMPLOYEE",
                  "EXPIRED DATE",
                  "HRDF CLAIMABLE",
                  "DURATION COMPANY JOIN BEFORE EXPIRED DATE (IN MONTH)",
                  "PIC NAME",
                  "PIC NUMBER",
                  "PIC EMAIL",
                ]);
                const ws = XLSX.utils.json_to_sheet(excelCol);
                const wb = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(
                  wb,
                  ws,
                  `${downloadData.training_cohort}`
                );
                XLSX.writeFile(wb, `${downloadData.training_name}.xlsx`, {
                  compression: true,
                });
                downloadDialog.onClose();
              }}
            >
              Download
            </Button>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </div>
  );
}

export { TrainingAdmin };
