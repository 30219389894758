import {
  Container,
  Heading,
  HStack,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Tabs,
  TabPanel,
  TabPanels,
  TabList,
  Tab,
  Button,
  Stack,
  Menu,
  MenuButton,
  MenuList,
  useDisclosure,
  Drawer,
  DrawerOverlay,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  Center,
  VStack,
  Text,
  FormControl,
  FormLabel,
  Textarea,
  Popover,
  MenuItem,
  Flex,
  Icon,
  PopoverTrigger,
  PopoverContent,
  Portal,
  PopoverArrow,
  PopoverBody,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalFooter,
  ModalBody,
  Box,
  Input,
  InputGroup,
  InputRightElement,
  IconButton,
  Switch,
  AspectRatio,
  ModalHeader,
  ModalCloseButton,
  Select,
  Link,
  Checkbox,
  CheckboxGroup,
  useToast,
} from "@chakra-ui/react";
import { Select as ChakraSelectReact } from "chakra-react-select";
import { AddIcon, ChevronRightIcon, EditIcon } from "@chakra-ui/icons";
import ReactQuill from "react-quill";
import {
  FiPrinter,
  FiMoreVertical,
  FiEdit,
  FiPocket,
  FiTrash2,
  FiClock,
  FiFile,
  FiFileText,
  FiUpload,
  FiUserX,
  FiCheckCircle,
  FiLayers,
} from "react-icons/fi";
import { AiOutlineFileExcel } from "react-icons/ai";
import { BsCheckCircleFill } from "react-icons/bs";
import { BiTransfer } from "react-icons/bi";
import React, { useEffect, useState, useCallback, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import {
  ReactTable,
  ModalComponent,
  ChakraAlertDialog,
  AlertPop,
  ErrorsDialog,
  Excel,
  WriteExcel,
} from "../../../components";
import { useForm } from "react-hook-form";
import CRUDFunction from "../../../functions/CRUDFunction";
import print from "print-js";
import "../../../assets/css/custom.css";
import QRCode from "qrcode";
import { format } from "date-fns";
import SummTable from "../../../components/table/trainee-summary-table";
import html2canvas from "html2canvas";
import { URL_LOCAL, URL_STAGING } from "../../../constant";
import * as XLSX from "xlsx";

const StylesTrainee = styled.div`
  padding: 1rem;

  table {
    min-width: 100%;
    border-spacing: 0;
    text-align: center;

    tr {
      :hover {
        background-color: #ebebeb;
      }
      border-bottom: 1px solid #bdbdbd;
    }

    th {
      font-weight: bold;
      margin: 0;
      padding: 0.5rem;
      background-color: white;
      border-bottom: 1px solid #bdbdbd;
      :first-child {
        width: 5%;
      }
      :nth-child(2) {
        width: 350px;
      }
      :nth-child(3) {
        width: 250px;
      }
    }

    td {
      padding: 0.5rem;
      :last-child {
        margin-top: 20px;
      }
      :nth-child(3) {
        text-align: left;
      }
      :nth-child(4) {
        text-align: left;
      }
      :nth-child(5) {
        text-align: left;
      }
    }

    th {
      font-weight: bold;
      margin: 0;
      padding: 0.5rem;
      background-color: white;
      border-bottom: 1px solid #bdbdbd;
      :first-child {
        width: 5%;
      }
      :nth-child(1) {
        width: 50px;
      }
      :nth-child(2) {
        width: 250px;
      }
      :nth-child(3) {
        width: 150px;
      }
      :nth-child(4) {
        width: 150px;
      }
    }

    td {
      padding: 0.5rem;
      :last-child {
        margin-top: 20px;
      }
    }
  }
`;

const listItem = [
  { name: "Edit", icon: FiEdit },
  { name: "Invoice", icon: FiFileText },
  { name: "Receipt", icon: FiFile },
  { name: "HRDF", icon: FiPocket },
  { name: "Attendance", icon: FiClock },
  { name: "Move To", icon: BiTransfer },
  { name: "Join", icon: FiCheckCircle },
  { name: "Withdraw", icon: FiUserX },
  { name: "Delete", icon: FiTrash2 },
];

function TraineeAdmin() {
  const [bigData, setBigData] = useState([]);
  const [printForm, setPrintForm] = useState(false);
  const [data, setData] = useState([]);
  const [companies, setCompanyList] = useState([]);
  const [companyAddress, setCompanyAddress] = useState("");
  const [companyID, setCompanyID] = useState();
  const [editData, setEditData] = useState([]);
  const [content, setContent] = useState("");
  const [traineeID, setTraineeID] = useState();
  const [allowEdit, setAllowEdit] = useState(false);
  const [allowAddress, setAllowAddress] = useState(false);
  const addTrainee = useDisclosure();
  const editTrainee = useDisclosure();
  const deleteMenu = useDisclosure();
  const joinMenu = useDisclosure();
  const withdrawMenu = useDisclosure();
  const mailHRDF = useDisclosure();
  const invoiceDetail = useDisclosure();
  const receiptDetail = useDisclosure();
  const moveTraining = useDisclosure();
  const modalPunchinOut = useDisclosure();
  const groupInvoice = useDisclosure();
  const viewInvoice = useDisclosure();
  let nav = useNavigate();
  const { state } = useLocation();
  const [trainingID, setTrainingID] = useState(state.id);
  const [programID] = useState(state.program_id);
  const [excel, setExcel] = useState(false);
  const [qr, setQr] = useState("");
  const [punch, setPunch] = useState();
  const [trainingNames, setTrainingNames] = useState([]);
  const [loader, setLoader] = useState(false);
  const [tableLoader, setTableLoader] = useState(false);
  const [alert, setAlert] = useState({
    class: "hide",
    status: "",
    message: "",
  });
  const [dateRange, setDateRange] = useState([]);
  const [summary, setSummary] = useState([]);
  const [showAddress, setShowAddress] = useState({
    address: "",
    postcode: "",
    state: "",
  });
  const downloadRef = React.useRef();
  const [changeCompany, setChangeCompany] = useState(false);
  const [newCompany, setNewCompany] = useState("Select new company");
  const [promoCode, setPromoCode] = useState([]);
  const [registerStudent, setRegisterStudent] = useState(true);
  const [deleteStudent, setDeleteStudent] = useState(false);
  const [err, setErr] = useState({
    name: false,
    ic: false,
    phone: false,
    email: false,
    address: false,
    postcode: false,
    state: false,
  });
  const errorProps = useDisclosure();
  const [errorAlert, setErrorAlert] = useState("");
  const [trainingFee, setTrainingFee] = useState(0);
  const [compArr, setCompArr] = useState([]);
  const toast = useToast();
  const [testViewInvoice, setTestViewInvoice] = useState();
  const [buttonType, setButtonType] = useState("");

  useEffect(() => {
    setTableLoader(true);
    fetchData();
    fetchCompany();
    fetchPromoCode();
  }, []);

  const fetchData = async () => {
    const res = await CRUDFunction.get(
      `${URL_STAGING}registeredtrainee/training/${state.id}`
    );
    if (res) {
      setTrainingFee(parseInt(res.training_fee));
      setData(res.trainees);
      setTableLoader(false);
    }
  };

  const fetchCompany = async () => {
    const res = await CRUDFunction.get(`${URL_STAGING}companyname`);
    if (res) {
      let companyList = [];
      for (let i = 0; i < res.length; i++) {
        companyList.push({
          value: JSON.stringify({
            id: res[i].company_id,
            address: res[i].company_address,
          }),
          label: res[i].company_name,
        });
      }
      setCompanyList(companyList);
    }
  };

  const fetchPromoCode = async () => {
    const res = await CRUDFunction.get(
      `${URL_STAGING}discount/bytraining/${state.id}`
    );
    if (res) {
      let promoList = [];
      for (let i = 0; i < res.length; i++) {
        promoList.push({
          value: res[i].disc_id,
          label: res[i].disc_code,
        });
      }
      setPromoCode(promoList);
    }
  };

  const handleCompany = (e) => {
    if (JSON.parse(e.value).id === 1) {
      setCompanyID(1);
      resetField("traineeAddress", {
        defaultValue: "",
      });
      resetField("traineePostcode", {
        defaultValue: "",
      });
      resetField("traineeState", {
        defaultValue: "",
      });
      setAllowAddress(false);
    } else {
      resetField("traineeAddress", {
        defaultValue: JSON.parse(JSON.parse(e.value).address)[0].address,
      });
      resetField("traineePostcode", {
        defaultValue: JSON.parse(JSON.parse(e.value).address)[0].postcode,
      });
      resetField("traineeState", {
        defaultValue: JSON.parse(JSON.parse(e.value).address)[0].state,
      });
      setCompanyID(JSON.parse(e.value).id);
      setCompanyAddress(JSON.parse(JSON.parse(e.value).address));
      setAllowAddress(true);
    }
  };

  const editCompany = (e) => {
    if (JSON.parse(e.value).id === 1) {
      setCompanyID(1);
      setNewCompany(e.label);
      resetField("traineeAddress", {
        defaultValue: "",
      });
      resetField("traineePostcode", {
        defaultValue: "",
      });
      resetField("traineeState", {
        defaultValue: "",
      });
      setAllowAddress(false);
    } else {
      setCompanyID(JSON.parse(e.value).id);

      resetField("traineeAddress", {
        defaultValue: JSON.parse(JSON.parse(e.value).address)[0].address,
      });
      resetField("traineePostcode", {
        defaultValue: JSON.parse(JSON.parse(e.value).address)[0].postcode,
      });
      resetField("traineeState", {
        defaultValue: JSON.parse(JSON.parse(e.value).address)[0].state,
      });
      setCompanyAddress(JSON.parse(JSON.parse(e.value).address));
      setNewCompany(e.label);
      setAllowAddress(true);
    }
  };

  const handleDownloadImage = async () => {
    const element = downloadRef.current;
    const canvas = await html2canvas(element);

    const img_data = canvas.toDataURL("image/jpg");
    const link = document.createElement("a");

    if (typeof link.download === "string") {
      link.href = img_data;
      link.download =
        punch === "in"
          ? state.name + " (PUNCH IN)"
          : // eslint-disable-next-line no-useless-concat
            state.name + " (PUNCH OUT)" + ".jpg";

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      window.open(img_data);
    }
  };

  const { register, handleSubmit, reset, resetField } = useForm();

  const timer = () => {
    setTimeout(function () {
      setAlert({ ...alert, class: "hide" });
      errorProps.onClose();
    }, 5000);
  };

  const resetErr = () => {
    setErr({
      name: false,
      ic: false,
      phone: false,
      email: false,
      address: false,
      postcode: false,
      state: false,
    });
  };

  const clearErr = (e) => {
    if (e.target.value !== "") {
      if (e.target.id === "name") {
        setErr((err) => ({ ...err, name: false }));
      }
      if (e.target.id === "ic") {
        setErr((err) => ({ ...err, ic: false }));
      }
      if (e.target.id === "phone") {
        setErr((err) => ({ ...err, phone: false }));
      }
      if (e.target.id === "email") {
        setErr((err) => ({ ...err, email: false }));
      }
      if (e.target.id === "address") {
        setErr((err) => ({ ...err, address: false }));
      }
      if (e.target.id === "postcode") {
        setErr((err) => ({ ...err, postcode: false }));
      }
      if (e.target.id === "state") {
        setErr((err) => ({ ...err, state: false }));
      }
    }
  };

  const onSubmit = async (formInput) => {
    try {
      if (formInput) {
        if (formInput.traineeAddress === "") {
          setErr((err) => ({ ...err, address: true }));
        } else {
          setErr((err) => ({ ...err, address: false }));
        }

        if (formInput.traineeEmail === "") {
          setErr((err) => ({ ...err, email: true }));
        } else {
          setErr((err) => ({ ...err, email: false }));
        }

        if (formInput.traineeIC === "") {
          setErr((err) => ({ ...err, ic: true }));
        } else {
          setErr((err) => ({ ...err, ic: false }));
        }

        if (formInput.traineeName === "") {
          setErr((err) => ({ ...err, name: true }));
        } else {
          setErr((err) => ({ ...err, name: false }));
        }

        if (formInput.traineePhone === "") {
          setErr((err) => ({ ...err, phone: true }));
        } else {
          setErr((err) => ({ ...err, phone: false }));
        }

        if (formInput.traineePostcode === "") {
          setErr((err) => ({ ...err, postcode: true }));
        } else {
          setErr((err) => ({ ...err, postcode: false }));
        }

        if (formInput.traineeState === "") {
          setErr((err) => ({ ...err, state: true }));
        } else {
          setErr((err) => ({ ...err, state: false }));
        }

        // setLoader(true);
        const formData = new FormData();
        formData.append("training_id", state.id);
        formData.append("program_id", programID);
        if (editTrainee.isOpen) {
          if (changeCompany === true) {
            formData.append("company_id", companyID);
          } else {
            formData.append("company_id", editData.company.company_id);
          }
        } else {
          formData.append("company_id", companyID);
        }
        let combineAddress = [
          {
            address: formInput.traineeAddress,
            postcode: formInput.traineePostcode,
            state: formInput.traineeState,
          },
        ];
        if (companyID > 1) {
          formData.append("trainee_address", JSON.stringify(companyAddress));
        } else {
          formData.append("trainee_address", JSON.stringify(combineAddress));
        }
        if (addTrainee.isOpen || editTrainee.isOpen) {
          if (companyID === 1) {
            formData.append("trainee_position", "Self-Employed");
          } else {
            formData.append("trainee_position", formInput.traineePosition);
          }

          formData.append("trainee_name", formInput.traineeName);
          formData.append("trainee_email", formInput.traineeEmail);
          formInput.traineeHRDF
            ? formData.append("trainee_hrdf", 1)
            : formData.append("trainee_hrdf", 0);
          formData.append("trainee_allergies", formInput.traineeAllergies);
          formData.append("trainee_referrer", formInput.traineeReferrer);
          formData.append("trainee_payment", formInput.traineePayment);
          formData.append("trainee_ic", formInput.traineeIC.toString());
          formData.append("trainee_phone", formInput.traineePhone.toString());
          if (formInput.discID) {
            formData.append("disc_id", formInput.discID);
          }
        }

        let res;
        if (addTrainee.isOpen) {
          if (excel) {
            let traineeName = [],
              traineeIC = [],
              traineePhone = [],
              traineeEmail = [],
              traineeAddress = [],
              traineePostcode = [],
              traineeState = [],
              combineAddress = [],
              traineePayment = [];
            for (let i = 0; i < bigData.length; i++) {
              traineeName.push(bigData[i]["NAMA"]);
              traineeIC.push(bigData[i]["NO KAD PENGENALAN"]);
              traineePhone.push(bigData[i]["NOMBOR TELEFON"]);
              traineeEmail.push(bigData[i]["ALAMAT EMEL"]);
              traineeAddress.push(bigData[i]["ALAMAT BERMASTAUTIN DI JOHOR"]);
              traineePostcode.push(bigData[i]["POSKOD"]);
              traineeState.push(bigData[i]["NEGERI"]);
              traineePayment.push(bigData[i]["PAYMENT"]);
            }
            for (let i = 0; i < traineeAddress.length; i++) {
              combineAddress[i] = JSON.stringify([
                {
                  address: traineeAddress[i],
                  postcode: traineePostcode[i],
                  state: traineeState[i],
                },
              ]);
            }
            const formData = new FormData();
            formData.append("trainee_name", JSON.stringify(traineeName));
            formData.append("trainee_ic", JSON.stringify(traineeIC));
            formData.append("trainee_phone", JSON.stringify(traineePhone));
            formData.append("trainee_email", JSON.stringify(traineeEmail));
            formData.append("trainee_address", JSON.stringify(combineAddress));
            formData.append("trainee_payment", JSON.stringify(traineePayment));
            res = await CRUDFunction.create(
              `${URL_STAGING}traineeExcel/${trainingID}`,
              formData
            );
          } else {
            res = await CRUDFunction.create(`${URL_STAGING}trainee`, formData);
          }

          if (res.errors || res.message) {
            setErrorAlert(res.message);
            errorProps.onOpen();
            timer();
          } else {
            setExcel(false);
            setData(res.trainees);
            reset();
            resetErr();
            setAlert({
              ...alert,
              class: "show",
              status: "success",
              message: "Data is successfully created.",
            });
            timer();
            addTrainee.onClose();
          }
        } else if (editTrainee.isOpen) {
          const res = await CRUDFunction.update(
            `${URL_STAGING}trainee/update/${traineeID}`,
            formData
          );

          if (res.errors) {
            setErrorAlert(res.message);
            errorProps.onOpen();
            timer();
          } else {
            setExcel(false);
            setData(res.trainees);
            reset();
            resetErr();
            setNewCompany("");
            setChangeCompany(false);
            setAlert({
              ...alert,
              class: "show",
              status: "success",
              message: "Data is successfully updated.",
            });
            timer();
            editTrainee.onClose();
          }
        } else if (invoiceDetail.isOpen) {
          setLoader(false);
          const formData = new FormData();
          formData.append("inv_num", formInput.invoiceNum);
          formData.append("inv_date", formInput.invoiceDate);
          formData.append("trainee_id", traineeID);
          formData.append("training_id", trainingID);

          if (formInput.invoiceNum && formInput.invoiceDate) {
            if (buttonType === "Send") {
              const res = await CRUDFunction.update(
                `${URL_STAGING}invoice/create`,
                formData
              );

              if (res) {
                reset();
                invoiceDetail.onClose();
                setLoader(false);
              }
            } else if (buttonType === "View") {
              const res2 = await CRUDFunction.update(
                `${URL_STAGING}invoice/view`,
                formData
              );
              if (res2) {
                setTestViewInvoice(res2);
                viewInvoice.onOpen();
              }
            }
          } else {
            toast({
              title: "Invoice error",
              description: "Enter invoice number and select invoice date",
              status: "error",
              duration: 9000,
              isClosable: true,
            });
          }
        } else if (receiptDetail.isOpen) {
          const formData = new FormData();
          formData.append("receipt_num", formInput.receiptNum);
          formData.append("receipt_date", formInput.receiptDate);
          formData.append("receipt_amount", formInput.receiptAmount);
          formData.append("trainee_id", traineeID);
          formData.append("training_id", trainingID);

          const res = await CRUDFunction.update(
            `${URL_STAGING}receipt/create`,
            formData
          );

          if (res) {
            reset();
            setLoader(false);
            receiptDetail.onClose();
          }
        } else if (moveTraining.isOpen) {
          const formData = new FormData();
          formData.append("training_id_new", formInput.trainingID);
          formData.append("company_id", companyID);
          formData.append("training_id_old", trainingID);

          const res = await CRUDFunction.update(
            `${URL_STAGING}trainee/moveto/${traineeID}`,
            formData
          );

          if (res) {
            reset();
            setLoader(false);
            setData(res.trainees);
            moveTraining.onClose();
          }
        }
      }
    } catch (err) {
      moveTraining.onClose();
      receiptDetail.onClose();
      editTrainee.onClose();
      addTrainee.onClose();
      invoiceDetail.onClose();
      setLoader(false);
    }
  };

  const groupSubmit = async (data) => {
    if (
      data.groupInvCheckbox.length > 1 &&
      data.groupInvNo &&
      data.groupInvDate
    ) {
      const arrTrainee = [];
      for (let i = 0; i < data.groupInvCheckbox.length; i++) {
        arrTrainee.push(data.groupInvCheckbox[i]);
      }
      const formData = new FormData();
      const res1 = await CRUDFunction.get(
        `${URL_STAGING}trainee/${data.groupInvCheckbox[0]}`
      );
      if (res1.company.company_id !== 1) {
        formData.append("companyName", res1.company.company_name);
        formData.append("companyAddress", res1.company.company_address);
        formData.append("picName", res1.company.company_picname);
        formData.append("picNo", res1.company.company_picphone);
        formData.append("picEmail", res1.company.company_picemail);
        formData.append("invNo", data.groupInvNo);
        formData.append("invDate", data.groupInvDate);
        formData.append("trainingID", state.id);
        formData.append("quantity", data.groupInvCheckbox.length);
        formData.append("traineesID", arrTrainee);
      }

      if (buttonType === "view") {
        const res2 = await CRUDFunction.update(
          `${URL_STAGING}invoice/viewGroup`,
          formData
        );
        if (res2) {
          // groupInvoice.onClose();
          setTestViewInvoice(res2);
          viewInvoice.onOpen();
        }
      } else if (buttonType === "send") {
        const res3 = await CRUDFunction.update(
          `${URL_STAGING}invoice/createGroup`,
          formData
        );
        if (res3) {
          console.log(res3);
          reset();
          setButtonType("");
          viewInvoice.onClose();
          setLoader(false);
        }
      }
    } else {
      toast({
        title: "Group invoice error",
        description:
          "Select more than 1 trainee for group invoice, or fill in the required information",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const handleEdit = useCallback(
    async (name, value) => {
      if (name === "Edit") {
        const res = await CRUDFunction.get(`${URL_STAGING}trainee/${value}`);
        if (res) {
          setTrainingID(res.training_id);

          setEditData(res);
          if (res.company_id === 1) {
            setShowAddress(JSON.parse(res.trainee_address)[0]);
          } else {
            setShowAddress(JSON.parse(res.company.company_address)[0]);
          }
          setTraineeID(value);
          editTrainee.onOpen();
        }
      } else if (name === "Invoice") {
        setTraineeID(value);
        invoiceDetail.onOpen();
      } else if (name === "Receipt") {
        setTraineeID(value);
        receiptDetail.onOpen();
      } else if (name === "HRDF") {
        mailHRDF.onOpen();
      } else if (name === "Attendance") {
        return nav("/admin/attendance/individual", {
          replace: false,
          state: {
            trainee_id: value,
            training_id: state.id,
            training_name: state.name,
          },
        });
      } else if (name === "Move To") {
        const res = await CRUDFunction.get(
          `${URL_STAGING}trainingcourse/${state.course_id}`
        );
        // const res = await CRUDFunction.get(`${URL_STAGING}trainingname`);
        if (res) {
          setTraineeID(value.traineeID);
          setCompanyID(value.companyID);
          setTrainingNames(res);
          moveTraining.onOpen();
        }
      } else if (name === "Withdraw") {
        setTraineeID(value);
        withdrawMenu.onOpen();
      } else if (name === "Join") {
        setTraineeID(value);
        joinMenu.onOpen();
      } else if (name === "Delete") {
        setTraineeID(value);
        deleteMenu.onOpen();
      }
    },
    [
      deleteMenu,
      editTrainee,
      invoiceDetail,
      mailHRDF,
      moveTraining,
      nav,
      receiptDetail,
      state.id,
      state.name,
      withdrawMenu,
    ]
  );

  const qrCode = (url) => {
    QRCode.toDataURL(
      url,
      {
        width: 500,
        margin: 1,
      },
      (err, url) => {
        if (err) return console.error(err);
        setQr(url);
      }
    );
  };

  const getDatesRange = async (start, end) => {
    return new Promise((resolve, reject) => {
      try {
        const date = new Date(start.getTime());
        const dates = [];

        while (date <= end) {
          dates.push(format(new Date(date), "y-LL-dd"));
          date.setDate(date.getDate() + 1);
        }
        const dateFilterred = dates.filter((date) => {
          return (
            new Date(date).getDay() !== 0 &&
            new Date(date).getDay() !== 5 &&
            new Date(date).getDay() !== 6
          );
        });
        if (dateFilterred) {
          resolve(dateFilterred);
        }
      } catch (err) {
        reject(err);
      }
    });
  };

  const traineeSummary = async (resdata) => {
    setSummary([]);
    var res = "";
    if (!resdata) {
      res = await CRUDFunction.get(
        `${URL_STAGING}traineeattendetails/training/${state.id}`
      );
    } else {
      res = resdata;
    }

    const res2 = await CRUDFunction.get(
      `${URL_STAGING}company/training/${state.id}`
    );

    if (res2) {
      setCompArr(res2);
    }

    if (res) {
      setDeleteStudent(false);
      const d1 = new Date(JSON.parse(res.training_date)[0].startDate);
      const d2 =
        JSON.parse(res.training_date).length > 1
          ? new Date(JSON.parse(res.training_date)[1].endDate)
          : new Date(JSON.parse(res.training_date)[0].endDate);
      const dates = await getDatesRange(d1, d2);

      if (dates) {
        setDateRange(dates);
        res.trainees.map((item, index) => {
          //if trainee's attendance data is 0
          if (item.attendance.length === 0) {
            for (let i = 0; i < dates.length; i++) {
              if (!item.attendance[i]) {
                item.attendance.push({
                  attendance_punchin: "--:--",
                });
              }

              item.attendance[i].attendance_punchout = "--:--";
              if (item.trainee_status === "3") {
                item.attendance[i].attendance_status = 3;
              }
            }
          } else {
            for (let i = 0; i < dates.length; i++) {
              var temp = "--:--";
              var temp2 = "--:--";

              if (item.attendance[i] !== undefined) {
                if (item.attendance[i].attendance_punchin !== null) {
                  temp = item.attendance[i].attendance_punchin;
                }
                if (item.attendance[i].attendance_punchout !== null) {
                  temp2 = item.attendance[i].attendance_punchout;
                }

                if (!temp.includes(dates[i]) && !temp2.includes(dates[i])) {
                  if (item.trainee_status === "3") {
                    item.attendance.splice(i, 0, {
                      attendance_punchin: "--:--",
                      attendance_punchout: "--:--",
                      attendance_status: 3,
                    });
                  } else {
                    item.attendance.splice(i, 0, {
                      attendance_punchin: "--:--",
                      attendance_punchout: "--:--",
                    });
                  }
                } else if (!temp.includes(dates[i])) {
                  item.attendance[i].attendance_punchin = "--:--";
                } else if (!temp2.includes(dates[i])) {
                  item.attendance[i].attendance_punchout = "--:--";
                }
              } else {
                if (item.trainee_status === "3") {
                  item.attendance.splice(i, 0, {
                    attendance_punchin: "--:--",
                    attendance_punchout: "--:--",
                    attendance_status: 3,
                  });
                } else {
                  item.attendance.splice(i, 0, {
                    attendance_punchin: "--:--",
                    attendance_punchout: "--:--",
                  });
                }
              }
            }
          }
        });

        setSummary(res);
        setTableLoader(false);
      }
    }
  };

  const NavItem = ({ icon, onClick, children }) => {
    return (
      <MenuItem onClick={onClick}>
        <Flex p="2" mx="2" borderRadius="lg" role="group" cursor="pointer">
          {
            <Icon
              mr="4"
              fontSize="16"
              _groupHover={{
                color: "green",
              }}
              as={icon}
            />
          }
          <Text>{children}</Text>
        </Flex>
      </MenuItem>
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "#",
        Cell: (row) => (
          <>
            <Text>{parseInt(row.row.id) + 1}</Text>
          </>
        ),
      },
      {
        Header: "Trainee",
        accessor: (data) => {
          return {
            name: data.trainee_name,
            ic: data.trainee_ic,
            position: data.trainee_position,
          };
        },
        Cell: ({ value }) => {
          return (
            <ul
              style={{
                listStyleType: "none",
                textAlign: "left",
                textTransform: "capitalize",
              }}
            >
              <li style={{ textTransform: "capitalize" }}>
                <b>{value.name.toLowerCase()}</b>
              </li>
              <li>{value.ic}</li>
              <li>
                {value.position !== "Self-Employed" ? value.position : ""}
              </li>
            </ul>
          );
        },
      },
      {
        Header: "Company",
        accessor: "company.company_name",
        Cell: ({ value }) => {
          if (value) {
            return (
              <>
                <Text>{value}</Text>
              </>
            );
          } else {
            return (
              <>
                <Text>Self-Employed</Text>
              </>
            );
          }
        },
      },
      {
        Header: "Contact",
        accessor: (data) => {
          return {
            email: data.trainee_email,
            phone: data.trainee_phone,
          };
        },
        disableSortBy: true,
        Cell: ({ value }) => {
          return (
            <ul style={{ listStyleType: "none" }}>
              <li>{value.email}</li>
              <li>{value.phone}</li>
            </ul>
          );
        },
      },
      {
        Header: "Training",
        accessor: (data) => {
          return {
            promo: data.discount,
            allergy: data.trainee_allergies,
            referrer: data.trainee_referrer,
          };
        },
        disableSortBy: true,
        Cell: ({ value }) => {
          return (
            <ul style={{ listStyleType: "none" }}>
              <li>
                Fee:{" "}
                <b>
                  RM{" "}
                  {value.promo
                    ? trainingFee -
                      (trainingFee * value.promo.disc_percent) / 100
                    : trainingFee}
                </b>
              </li>
              <li>
                Promo Code:{" "}
                <b>{value.promo ? value.promo.disc_code : "None"}</b>
              </li>
              <li>
                Special Diet / Allergy: <b>{value.allergy}</b>
              </li>
              <li>
                Referrer: <b>{value.referrer}</b>
              </li>
            </ul>
          );
        },
      },
      {
        Header: "Payment",
        accessor: "trainee_payment",
        Cell: ({ value }) => {
          return (
            <Text
              color={
                value === "0"
                  ? "orange.400"
                  : value === "1"
                  ? "yellow.400"
                  : value === "2"
                  ? "green.400"
                  : "orange.400"
              }
            >
              {value === "0"
                ? "Pending"
                : value === "1"
                ? "Processing"
                : value === "2"
                ? "Completed"
                : "Pending"}
            </Text>
          );
        },
      },
      {
        Header: "HRDF",
        accessor: "trainee_hrdf",
        Cell: ({ value }) => {
          return (
            <Text color={value === 1 ? "#1DB700" : "black"}>
              {value === 1 ? (
                <Center>
                  <BsCheckCircleFill color="green" />
                </Center>
              ) : (
                "-"
              )}
            </Text>
          );
        },
        disableSortBy: true,
      },
      {
        Header: "Action",
        accessor: (data) => {
          return {
            traineeID: data.trainee_id,
            companyID: data.company.company_id,
          };
        },
        Cell: ({ value }) => {
          return (
            <Menu>
              <MenuButton
                as={Button}
                my="30px"
                _hover={{ bgColor: "none" }}
                _expanded={{ bgColor: "#ebebeb" }}
                _click={{ bgColor: "#ebebeb" }}
                variant="ghost"
                rightIcon={<FiMoreVertical />}
              ></MenuButton>
              <MenuList bgColor="white">
                {listItem.map(({ icon, name, src }) => {
                  if (deleteStudent) {
                    if (name === "Delete") {
                      return (
                        <NavItem
                          icon={icon}
                          onClick={() => {
                            handleEdit(name, value.traineeID);
                          }}
                        >
                          {name}
                        </NavItem>
                      );
                    } else {
                      return "";
                    }
                  } else if (registerStudent) {
                    if (name === "Join") {
                      return (
                        <NavItem
                          icon={icon}
                          onClick={() => {
                            handleEdit(name, value.traineeID);
                          }}
                        >
                          {name}
                        </NavItem>
                      );
                    }
                  } else {
                    if (name === "Move To") {
                      return (
                        <NavItem
                          icon={icon}
                          onClick={() => {
                            handleEdit(name, {
                              traineeID: value.traineeID,
                              companyID: value.companyID,
                            });
                          }}
                        >
                          {name}
                        </NavItem>
                      );
                    } else if (name !== "Join") {
                      return (
                        <NavItem
                          icon={icon}
                          onClick={() => {
                            handleEdit(name, value.traineeID);
                          }}
                        >
                          {name}
                        </NavItem>
                      );
                    }
                  }
                })}
              </MenuList>
            </Menu>
          );
        },
        disableSortBy: true,
      },
    ],
    [handleEdit, registerStudent, deleteStudent]
  );

  const columnsPrint = React.useMemo(
    () => [
      {
        Header: "#",
        Cell: (row) => (
          <>
            <Text>{parseInt(row.row.id) + 1}</Text>
          </>
        ),
      },
      {
        Header: "Trainee",
        accessor: (data) => {
          return {
            name: data.trainee_name,
            ic: data.trainee_ic,
            position: data.trainee_position,
          };
        },
        Cell: ({ value }) => {
          return (
            <ul style={{ listStyleType: "none", textAlign: "left" }}>
              <li>{value.name}</li>
              <li>{value.ic}</li>
              <li>{value.position}</li>
            </ul>
          );
        },
      },
      {
        Header: "Company",
        accessor: "company.company_name",
        Cell: ({ value }) => {
          if (value) {
            return (
              <>
                <Text>{value}</Text>
              </>
            );
          } else {
            return (
              <>
                <Text>Self-Employed</Text>
              </>
            );
          }
        },
      },
      {
        Header: "Contact",
        accessor: (data) => {
          return {
            email: data.trainee_email,
            phone: data.trainee_phone,
          };
        },
        disableSortBy: true,
        Cell: ({ value }) => {
          return (
            <ul style={{ listStyleType: "none" }}>
              <li>{value.email}</li>
              <li>{value.phone}</li>
            </ul>
          );
        },
      },
      {
        Header: "Training",
        accessor: (data) => {
          return {
            promo: "COMING SOON",
            allergy: data.trainee_allergies,
            referrer: data.trainee_referrer,
          };
        },
        disableSortBy: true,
        Cell: ({ value }) => {
          return (
            <ul style={{ listStyleType: "none" }}>
              <li>
                Fee: RM{" "}
                {value.promo
                  ? trainingFee - (trainingFee * value.promo.disc_percent) / 100
                  : trainingFee}
              </li>
              <li>
                Promo Code: {value.promo ? value.promo.disc_code : "None"}
              </li>
              <li>Special Diet / Allergy: {value.allergy}</li>
              <li>Referrer: {value.referrer}</li>
            </ul>
          );
        },
      },
      {
        Header: "Payment",
        accessor: "trainee_payment",
        Cell: ({ value }) => {
          return (
            <Text
              color={
                value === "0"
                  ? "orange.400"
                  : value === "1"
                  ? "yellow.400"
                  : value === "2"
                  ? "green.400"
                  : "orange.400"
              }
            >
              {value === "0"
                ? "Pending"
                : value === "1"
                ? "Processing"
                : value === "2"
                ? "Completed"
                : "Pending"}
            </Text>
          );
        },
        disableSortBy: true,
      },
      {
        Header: "HRDF",
        accessor: "trainee_hrdf",
        Cell: ({ value }) => {
          return (
            <Text color={value === 1 ? "#1DB700" : "black"}>
              {value === 1 ? (
                <Center>
                  <BsCheckCircleFill color="green" />
                </Center>
              ) : (
                "-"
              )}
            </Text>
          );
        },
        disableSortBy: true,
      },
    ],
    []
  );

  const WriteExcel = () => {
    const ws = XLSX.utils.json_to_sheet(data);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Responses");
    XLSX.writeFile(wb, "sampleData.export.xlsx", { compression: true });
  };

  return (
    <div>
      {alert.message ? (
        <AlertPop
          width="30%"
          className={alert.class}
          status={alert.status}
          title={alert.message}
        />
      ) : (
        ""
      )}
      <Breadcrumb
        as={Heading}
        separator={<ChevronRightIcon />}
        m="25px 0px 25px 100px"
        style={{ color: "black", textTransform: "uppercase" }}
      >
        <BreadcrumbItem>
          <BreadcrumbLink onClick={() => nav(-1, { replace: false })}>
            <Text>TRAINING</Text>
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem isCurrentPage>
          <BreadcrumbLink w="100%" maxW="500px">
            <Text noOfLines={1}>{state.name}</Text>
          </BreadcrumbLink>
        </BreadcrumbItem>
      </Breadcrumb>

      <Tabs variant="unstyled">
        <Container maxW={"90%"} py="10px">
          <TabList>
            <Tab
              borderLeftRadius="10px"
              borderRightRadius="0px"
              p="10px"
              mr="10px"
              w="25%"
              fontSize="20px"
              bg="#f4f8f1"
              _selected={{
                color: "black",
                bg: "#C1E4A0",
                borderBottom: "inherit",
              }}
              onClick={async () => {
                setData([]);
                setTableLoader(true);

                const res = await CRUDFunction.get(
                  `${URL_STAGING}registeredtrainee/training/${state.id}`
                );
                if (res) {
                  setDeleteStudent(false);
                  setRegisterStudent(true);
                  setData(res.trainees);
                  setTableLoader(false);
                }
              }}
            >
              Register
            </Tab>
            <Tab
              borderRightRadius="0px"
              p="10px"
              mr="10px"
              w="25%"
              fontSize="20px"
              bg="#f4f8f1"
              _selected={{
                color: "black",
                bg: "#C1E4A0",
                borderBottom: "inherit",
              }}
              onClick={async () => {
                setData([]);
                setTableLoader(true);

                const res = await CRUDFunction.get(
                  `${URL_STAGING}joinedtrainee/training/${state.id}`
                );

                if (res) {
                  setDeleteStudent(false);
                  setRegisterStudent(false);
                  setData(res.trainees);
                  setTableLoader(false);
                }
              }}
            >
              Join
            </Tab>
            <Tab
              borderRadius="0px"
              p="10px"
              mr="10px"
              w="25%"
              fontSize="20px"
              bg="#f4f8f1"
              _selected={{
                color: "black",
                bg: "#C1E4A0",
                borderBottom: "inherit",
              }}
              onClick={async () => {
                setData([]);
                setTableLoader(true);
                const res = await CRUDFunction.get(
                  `${URL_STAGING}withdrawtrainee/training/${state.id}`
                );
                if (res) {
                  setDeleteStudent(true);
                  setRegisterStudent(false);
                  setData(res.trainees);
                  setTableLoader(false);
                }
              }}
            >
              Withdrawal
            </Tab>
            <Tab
              borderLeftRadius="0px"
              borderRightRadius="10px"
              p="10px"
              w="25%"
              fontSize="20px"
              bg="#f4f8f1"
              _selected={{
                color: "black",
                bg: "#C1E4A0",
                borderBottom: "inherit",
              }}
              onClick={() => {
                traineeSummary();
                setTableLoader(true);
              }}
            >
              Summary
            </Tab>
          </TabList>
        </Container>
        <Container
          maxW={"88.5%"}
          style={{ backgroundColor: "white", borderRadius: "10px" }}
        >
          <TabPanels>
            <TabPanel>
              <div style={{ position: "relative" }}>
                <Stack
                  direction={"row"}
                  position="absolute"
                  right="2%"
                  bottom="-80px"
                >
                  <Button
                    leftIcon={<AddIcon />}
                    bg="#33945f"
                    color="white"
                    variant="solid"
                    boxShadow="0 4px 12px rgba(55, 81, 255, 0.24)"
                    width="200px"
                    _hover={{ bg: "green.600" }}
                    p="14px"
                    fontSize="14px"
                    onClick={addTrainee.onOpen}
                  >
                    Add Trainee
                  </Button>
                </Stack>
              </div>

              <StylesTrainee>
                <ReactTable
                  columns={columns}
                  data={data}
                  modulePage="trainee"
                  tableLoader={tableLoader}
                />
              </StylesTrainee>
            </TabPanel>
            <TabPanel>
              <div
                style={{
                  position: "relative",
                }}
              >
                <Stack
                  direction="row"
                  style={{ position: "absolute", right: "2%", bottom: "-80px" }}
                >
                  <IconButton
                    variant="outline"
                    colorScheme="green"
                    aria-label="Download Excel"
                    icon={<AiOutlineFileExcel />}
                    onClick={() => {
                      let excelCol = [];
                      for (let i = 0; i < data.length; i++) {
                        excelCol.unshift([
                          data[i].trainee_name,
                          JSON.parse(data[i].trainee_address)[0].address +
                            ", " +
                            JSON.parse(data[i].trainee_address)[0].postcode +
                            ", " +
                            JSON.parse(data[i].trainee_address)[0].state,
                          data[i].trainee_ic,
                          data[i].trainee_email,
                          data[i].trainee_phone,
                          data[i].company.company_name,
                          data[i].company.company_address,
                        ]);
                      }
                      excelCol.unshift([
                        "name",
                        "address",
                        "ic",
                        "email",
                        "phone",
                        "company_name",
                        "company_address",
                      ]);
                      const ws = XLSX.utils.json_to_sheet(excelCol);
                      const wb = XLSX.utils.book_new();
                      XLSX.utils.book_append_sheet(wb, ws, "Responses");
                      XLSX.writeFile(wb, `${state.name}_trainee.xlsx`, {
                        compression: true,
                      });
                    }}
                  />
                  <IconButton
                    variant="outline"
                    colorScheme="green"
                    aria-label="Print trainees"
                    icon={<FiPrinter />}
                    onClick={() => {
                      let focuser = setInterval(
                        () => window.dispatchEvent(new Event("focus")),
                        500
                      );
                      setPrintForm(true);
                      setTimeout(() => {
                        print({
                          printable: "printJS-form",
                          type: "html",
                          documentTitle: "Trainee List",
                          showModal: true,
                          onPrintDialogClose: function () {
                            clearInterval(focuser);
                            setPrintForm(false);
                          },
                          targetStyles: ["*"],
                          style:
                            ".table-training { } .table-head { width: 100%; } .table-list { word-wrap: break-word; } #hashtag { background-color: red !important;}",
                        });
                      }, 500);
                    }}
                  />
                  <Popover>
                    <PopoverTrigger>
                      <IconButton
                        variant="outline"
                        colorScheme="green"
                        aria-label="Punch attendance"
                        icon={<FiClock />}
                      />
                    </PopoverTrigger>
                    <Portal boxShadow="0 4px 12px rgba(55, 81, 255, 0.24)">
                      <PopoverContent bg="white">
                        <PopoverArrow />
                        <PopoverBody my="40px">
                          <VStack>
                            <Text fontWeight="bold">Attendance</Text>
                            <Center>
                              <HStack p="0px 40px 0px 40px">
                                <Button
                                  disabled={
                                    state.date &&
                                    new Date() >
                                      new Date(
                                        state.date[0].startDate + " " + "08:00"
                                      )
                                      ? (
                                          state.date.length > 1
                                            ? new Date() <
                                              new Date(
                                                state.date[1].endDate +
                                                  " " +
                                                  "18:00"
                                              )
                                            : new Date() <
                                              new Date(
                                                state.date[0].endDate +
                                                  " " +
                                                  "18:00"
                                              )
                                        )
                                        ? false
                                        : true
                                      : true
                                  }
                                  w="120px"
                                  bgColor="#4b85fa"
                                  boxShadow="xl"
                                  _hover={{ bgColor: "blue.300" }}
                                  onClick={() => {
                                    setPunch("in");
                                    qrCode(
                                      `https://registerbeta.holisticslab.my/punchin/id/${trainingID}`
                                    );
                                    modalPunchinOut.onOpen();
                                  }}
                                >
                                  Punch In
                                </Button>
                                <Button
                                  disabled={
                                    state.date &&
                                    new Date() >
                                      new Date(
                                        state.date[0].startDate + " " + "08:00"
                                      )
                                      ? (
                                          state.date.length > 1
                                            ? new Date() <
                                              new Date(
                                                state.date[1].endDate +
                                                  " " +
                                                  "18:00"
                                              )
                                            : new Date() <
                                              new Date(
                                                state.date[0].endDate +
                                                  " " +
                                                  "18:00"
                                              )
                                        )
                                        ? false
                                        : true
                                      : true
                                  }
                                  w="120px"
                                  bgColor="#f9c611"
                                  boxShadow="xl"
                                  _hover={{ bgColor: "yellow.300" }}
                                  onClick={() => {
                                    setPunch("out");
                                    qrCode(
                                      `https://registerbeta.holisticslab.my/punchout/id/${trainingID}`
                                    );
                                    modalPunchinOut.onOpen();
                                  }}
                                >
                                  Punch Out
                                </Button>
                              </HStack>
                            </Center>
                            <Text color="red" textAlign={"center"}>
                              {state.date &&
                              new Date() >
                                new Date(
                                  state.date[0].startDate + " " + "08:00"
                                )
                                ? (state.date.length > 1
                                    ? new Date() >
                                      new Date(
                                        state.date[1].endDate + " " + "18:00"
                                      )
                                    : new Date() >
                                      new Date(
                                        state.date[0].endDate + " " + "18:00"
                                      )) &&
                                  "*The training has finished. Attendance punch was closed at 6:00pm, " +
                                    format(
                                      new Date(
                                        state.date.length > 1
                                          ? state.date[1].endDate
                                          : state.date[0].endDate
                                      ),
                                      "dd MMM y"
                                    ) +
                                    "*"
                                : "*The training is not started yet. Attendance punch will open at 8:00am, " +
                                  format(
                                    new Date(state.date[0].startDate),
                                    "dd MMM y."
                                  ) +
                                  "*"}
                            </Text>
                          </VStack>
                        </PopoverBody>
                      </PopoverContent>
                    </Portal>
                  </Popover>
                  <IconButton
                    variant="outline"
                    colorScheme="green"
                    icon={<FiLayers />}
                    onClick={groupInvoice.onOpen}
                  />
                </Stack>
              </div>
              <StylesTrainee>
                {printForm ? (
                  <ReactTable
                    columns={columnsPrint}
                    data={data}
                    tableLoader={tableLoader}
                    modulePage="print"
                  />
                ) : (
                  <>
                    <ReactTable
                      columns={columns}
                      data={data}
                      modulePage="trainee"
                      tableLoader={tableLoader}
                    />
                  </>
                )}
              </StylesTrainee>
            </TabPanel>
            <TabPanel>
              <StylesTrainee>
                <ReactTable
                  columns={columns}
                  data={data}
                  modulePage="trainee"
                  tableLoader={tableLoader}
                />
              </StylesTrainee>
            </TabPanel>
            <TabPanel>
              <div style={{ position: "relative", marginBottom: "80px" }}>
                <Stack
                  direction="row"
                  w="100%"
                  mt="40px"
                  mb="20"
                  right="0"
                  pos="absolute"
                >
                  <Box w="50%">
                    <Select
                      w="60%"
                      defaultValue=""
                      onChange={async (e) => {
                        const res = await CRUDFunction.get(
                          `${URL_STAGING}traineeattendetails/comp/${e.target.value}/training/${state.id}`
                        );
                        if (res) {
                          setTableLoader(true);
                          traineeSummary(res);
                        }
                      }}
                    >
                      <option value="0">All</option>
                      {compArr.map((item) => (
                        <option value={item.company_id}>
                          {item.company_name}
                        </option>
                      ))}
                    </Select>
                  </Box>
                  <Box w="50%" textAlign="right">
                    <span style={{ color: "red" }}>A</span>
                    <span> - Absent </span>
                    <span style={{ color: "#D4AC0D", marginLeft: "5px" }}>
                      W
                    </span>
                    <span> - Withdrew</span>

                    <Button
                      colorScheme="#000000"
                      variant="outline"
                      boxShadow="0 4px 12px rgba(55, 81, 255, 0.24)"
                      width="200px"
                      p="14px"
                      ml="3"
                      fontSize="14px"
                      onClick={() => {
                        let focuser = setInterval(
                          () => window.dispatchEvent(new Event("focus")),
                          500
                        );
                        setPrintForm(true);
                        setTimeout(() => {
                          print({
                            printable: "printSummary",
                            type: "html",
                            documentTitle: state.name + " Attendance",
                            showModal: true,
                            maxWidth: 1123,
                            onPrintDialogClose: function () {
                              clearInterval(focuser);
                              setPrintForm(false);
                            },
                            targetStyles: ["*"],
                            style:
                              ".footer2{ margin-left:350px; } .footer-container{ margin-top:20px; } #printSummary{ font-family:Arial,sans-serif; border:1px solid;} #header img{text-align:center;} td:nth-child(2){text-align:left; width:60px;} td{padding:5px;text-align:center;}",
                          });
                        }, 500);
                      }}
                    >
                      <FiPrinter />
                      &nbsp; Print
                    </Button>
                  </Box>
                </Stack>
              </div>
              <SummTable
                date={dateRange}
                data={summary}
                setData={setSummary}
                course={state.course_id}
                training={state.name}
                tableLoader={tableLoader}
                print={printForm}
              />
            </TabPanel>
          </TabPanels>
        </Container>
      </Tabs>

      {/* -----------------------------------start add drawer-------------------------- */}
      <Drawer
        isOpen={addTrainee.isOpen}
        placement="right"
        onClose={() => {
          reset();
          resetErr();
          setExcel(false);
          addTrainee.onClose();
        }}
        size="lg"
      >
        <DrawerOverlay />
        <DrawerContent overflowY={"auto"}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <DrawerCloseButton />
            <DrawerHeader textAlign="center" pt="7%" fontSize="25px">
              ADD TRAINEE
            </DrawerHeader>

            <DrawerBody>
              <Center>
                <VStack>
                  <b>UPLOAD EXCEL FILE</b>
                  <Excel handleBigData={setBigData} handleExcel={setExcel} />
                </VStack>
              </Center>

              {excel === false ? (
                <div>
                  <Text align="center" fontWeight="bold" mt="10px">
                    OR
                  </Text>
                  <FormControl isInvalid={err.name}>
                    <FormLabel mt="10px" fontWeight="bold">
                      NAME <span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Input
                      id="name"
                      placeholder="Name"
                      {...register("traineeName")}
                      onBlur={clearErr}
                    ></Input>
                  </FormControl>
                  {err.name && (
                    <span style={{ color: "red" }}>This field is required</span>
                  )}
                  <FormControl isInvalid={err.company}>
                    <FormLabel mt="10px" fontWeight="bold">
                      COMPANY <span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <ChakraSelectReact
                      id="company"
                      fontSize={{ md: "14px", base: "12px" }}
                      options={companies}
                      placeholder="Select company"
                      onChange={handleCompany}
                      closeMenuOnSelect={true}
                    />
                  </FormControl>
                  {err.company && (
                    <span style={{ color: "red" }}>This field is required</span>
                  )}
                  <FormControl
                    isInvalid={err.position}
                    isDisabled={!allowAddress}
                  >
                    <FormLabel mt="10px" fontWeight="bold">
                      POSITION <span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Input
                      id="position"
                      placeholder="Position"
                      {...register("traineePosition")}
                      onBlur={clearErr}
                    ></Input>
                  </FormControl>
                  {err.position && (
                    <span style={{ color: "red" }}>This field is required</span>
                  )}
                  <HStack>
                    <FormControl isInvalid={err.ic}>
                      <FormLabel mt="10px" fontWeight="bold">
                        IDENTIFICATION NUMBER / PASSPORT NUMBER
                        <span style={{ color: "red" }}>*</span>
                      </FormLabel>
                      <Input
                        id="ic"
                        type="text"
                        placeholder="IC"
                        {...register("traineeIC")}
                        onBlur={clearErr}
                      ></Input>
                    </FormControl>
                    <FormControl isInvalid={err.phone}>
                      <FormLabel mt="10px" fontWeight="bold">
                        PHONE NUMBER <span style={{ color: "red" }}>*</span>
                      </FormLabel>
                      <Input
                        id="phone"
                        type="number"
                        placeholder="Phone number"
                        {...register("traineePhone")}
                        onBlur={clearErr}
                      ></Input>
                    </FormControl>
                  </HStack>
                  <FormControl isInvalid={err.email}>
                    <FormLabel mt="10px" fontWeight="bold">
                      EMAIL <span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Input
                      id="email"
                      type="email"
                      placeholder="Email"
                      {...register("traineeEmail")}
                      onBlur={clearErr}
                    ></Input>
                  </FormControl>
                  {err.email && (
                    <span style={{ color: "red" }}>This field is required</span>
                  )}
                  <FormControl isInvalid={err.address}>
                    <FormLabel mt="10px" fontWeight="bold">
                      ADDRESS <span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Textarea
                      id="address"
                      resize="none"
                      style={{ height: "120px" }}
                      placeholder="Address"
                      defaultValue={
                        allowAddress ? companyAddress[0].address : ""
                      }
                      isDisabled={allowAddress}
                      {...register("traineeAddress")}
                      onBlur={clearErr}
                    ></Textarea>
                  </FormControl>
                  {err.address && (
                    <span style={{ color: "red" }}>This field is required</span>
                  )}
                  <HStack>
                    <Box w="30%">
                      <FormControl isInvalid={err.postcode}>
                        <FormLabel mt="10px" fontWeight="bold">
                          Postcode <span style={{ color: "red" }}>*</span>
                        </FormLabel>
                        <Input
                          id="postcode"
                          placeholder="Postcode"
                          defaultValue={
                            allowAddress ? companyAddress[0].postcode : ""
                          }
                          isDisabled={allowAddress}
                          {...register("traineePostcode")}
                          onBlur={clearErr}
                        />
                      </FormControl>
                    </Box>
                    <Box w="70%">
                      <FormControl isInvalid={err.state}>
                        <FormLabel mt="10px" fontWeight="bold">
                          State <span style={{ color: "red" }}>*</span>
                        </FormLabel>
                        <Input
                          id="state"
                          placeholder="State"
                          defaultValue={
                            allowAddress ? companyAddress[0].state : ""
                          }
                          {...allowAddress}
                          isDisabled={allowAddress}
                          {...register("traineeState")}
                          onBlur={clearErr}
                        />
                      </FormControl>
                    </Box>
                  </HStack>
                  <FormControl>
                    <FormLabel mt="10px" fontWeight="bold">
                      HRDC Claimed{" "}
                    </FormLabel>
                    <Checkbox value="1" {...register("traineeHRDF")}>
                      Yes
                    </Checkbox>
                  </FormControl>
                  <FormControl>
                    <FormLabel mt="10px" fontWeight="bold">
                      SPECIAL DIETARY / ALLERGIES
                    </FormLabel>
                    <Input
                      placeholder="Fill if applicable"
                      {...register("traineeAllergies")}
                    ></Input>
                  </FormControl>
                  <HStack>
                    <FormControl>
                      <FormLabel mt="10px" fontWeight="bold">
                        REFERRER
                      </FormLabel>
                      <Input
                        placeholder="Referrer"
                        {...register("traineeReferrer")}
                      ></Input>
                    </FormControl>
                    <FormControl>
                      <FormLabel mt="10px" fontWeight="bold">
                        PROMO CODE
                      </FormLabel>
                      {/* <ChakraSelectReact
                        fontSize={{ md: "14px", base: "12px" }}
                        options={promoCode}
                        placeholder="Select promo code"
                        closeMenuOnSelect={true}
                      /> */}
                      <Select w="100%" {...register("discID")}>
                        <option value="">Select promo code</option>
                        {promoCode.map((elem) => (
                          <option value={elem.value}>{elem.label}</option>
                        ))}
                      </Select>
                    </FormControl>
                  </HStack>
                </div>
              ) : (
                ""
              )}
            </DrawerBody>

            <Center>
              <DrawerFooter>
                <Button
                  fontWeight="medium"
                  w="200px"
                  color="white"
                  bg="#33945f"
                  _hover={{ bg: "green.700" }}
                  type="submit"
                >
                  Add
                </Button>
                <Button
                  fontWeight="medium"
                  w="200px"
                  variant="outline"
                  ml={3}
                  onClick={() => {
                    reset();
                    resetErr();
                    setExcel(false);
                    addTrainee.onClose();
                  }}
                >
                  Cancel
                </Button>
              </DrawerFooter>
            </Center>
          </form>
        </DrawerContent>
      </Drawer>
      {/* -----------------------------------end add drawer-------------------------- */}
      {/* -----------------------------------start edit drawer-------------------------- */}
      <Drawer
        isOpen={editTrainee.isOpen}
        placement="right"
        onClose={() => {
          reset();
          resetErr();
          setChangeCompany(false);
          setAllowAddress(false);
          setNewCompany("");
          editTrainee.onClose();
        }}
        size="lg"
        fontSize="14px"
      >
        <DrawerOverlay />
        <DrawerContent overflowY={"auto"}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <DrawerCloseButton />
            <DrawerHeader textAlign="center" pt="7%" fontSize="25px">
              EDIT TRAINEE
            </DrawerHeader>

            <DrawerBody>
              <FormControl isInvalid={err.name} pt="20px">
                <FormLabel fontWeight="bold">
                  TRAINEE NAME <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Input
                  placeholder="Trainee name"
                  defaultValue={editData.trainee_name}
                  {...register("traineeName")}
                ></Input>
              </FormControl>
              <FormControl isInvalid={err.company}>
                <FormLabel mt="10px" fontWeight="bold">
                  COMPANY <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Box
                  borderRadius="10px"
                  borderWidth="2px"
                  mb="7px"
                  pl="15px"
                  py="7px"
                  fontSize="17px"
                >
                  {editData.company &&
                    (changeCompany
                      ? newCompany
                      : editData.company.company_name)}
                </Box>
                {changeCompany === true ? (
                  <ChakraSelectReact
                    disabled
                    fontSize={{ md: "14px", base: "12px" }}
                    options={companies}
                    placeholder="Select company"
                    onChange={editCompany}
                    closeMenuOnSelect={true}
                  />
                ) : (
                  <Link
                    color="blue"
                    fontSize="15px"
                    onClick={() => setChangeCompany(true)}
                  >
                    Click here to change company
                  </Link>
                )}
              </FormControl>
              <HStack>
                <FormControl isInvalid={err.ic}>
                  <FormLabel mt="10px" fontWeight="bold">
                    IDENTIFICATION NUMBER / PASSPORT NUMBER
                    <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <Input
                    type="text"
                    placeholder="IC"
                    defaultValue={editData.trainee_ic}
                    {...register("traineeIC")}
                  ></Input>
                </FormControl>
                <FormControl isInvalid={err.phone} pt="20px">
                  <FormLabel fontWeight="bold">
                    TRAINEE PHONE NUMBER <span style={{ color: "red" }}>*</span>
                  </FormLabel>
                  <Input
                    type="number"
                    placeholder="Trainee phone number"
                    defaultValue={editData.trainee_phone}
                    {...register("traineePhone")}
                  ></Input>
                </FormControl>
              </HStack>
              <FormControl isInvalid={err.position}>
                <FormLabel mt="10px" fontWeight="bold">
                  POSITION <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Input
                  placeholder="Position"
                  defaultValue={editData.trainee_position}
                  {...register("traineePosition")}
                ></Input>
              </FormControl>
              <FormControl isInvalid={err.email}>
                <FormLabel mt="10px" fontWeight="bold">
                  EMAIL <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Input
                  type="email"
                  placeholder="Email"
                  defaultValue={editData.trainee_email}
                  {...register("traineeEmail")}
                ></Input>
              </FormControl>
              <FormControl isInvalid={err.address}>
                <FormLabel mt="10px" fontWeight="bold">
                  ADDRESS <span style={{ color: "red" }}>*</span>
                </FormLabel>
                {changeCompany ? (
                  <Textarea
                    resize="none"
                    style={{ height: "120px" }}
                    placeholder="Address"
                    defaultValue={allowAddress ? companyAddress[0].address : ""}
                    isDisabled={allowAddress}
                    {...register("traineeAddress")}
                  ></Textarea>
                ) : editData.company_id > 1 ? (
                  <Box
                    _hover={{ cursor: "not-allowed" }}
                    borderRadius="10px"
                    borderWidth="2px"
                    mb="7px"
                    pl="15px"
                    py="7px"
                    fontSize="17px"
                    h="120px"
                  >
                    {showAddress.address}
                  </Box>
                ) : (
                  <Textarea
                    resize="none"
                    style={{ height: "120px" }}
                    placeholder="Address"
                    defaultValue={showAddress.address}
                    isDisabled={editData.company_id > 1 ? true : false}
                    {...register("traineeAddress")}
                  ></Textarea>
                )}
              </FormControl>

              <HStack>
                <Box w="30%">
                  <FormControl isInvalid={err.postcode} mb="20px">
                    <FormLabel fontWeight="bold">
                      Postcode <span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    {changeCompany ? (
                      <Input
                        mt="5px"
                        resize="none"
                        style={{ height: "50px" }}
                        placeholder="Postcode"
                        defaultValue={
                          allowAddress ? companyAddress[0].postcode : ""
                        }
                        isDisabled={allowAddress}
                        {...register("traineePostcode")}
                      ></Input>
                    ) : editData.company_id > 1 ? (
                      <Box
                        _hover={{ cursor: "not-allowed" }}
                        borderRadius="10px"
                        borderWidth="2px"
                        mb="7px"
                        pl="15px"
                        py="7px"
                        fontSize="17px"
                      >
                        {showAddress.postcode}
                      </Box>
                    ) : (
                      <Input
                        mt="5px"
                        resize="none"
                        style={{ height: "50px" }}
                        placeholder="Postcode"
                        defaultValue={showAddress.postcode}
                        isDisabled={editData.company_id > 1 ? true : false}
                        {...register("traineePostcode")}
                      ></Input>
                    )}
                  </FormControl>
                </Box>
                <Box w="70%">
                  <FormControl isInvalid={err.state} mb="20px">
                    <FormLabel fontWeight="bold">
                      State <span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    {changeCompany ? (
                      <Input
                        mt="5px"
                        resize="none"
                        style={{ height: "50px" }}
                        placeholder="State"
                        defaultValue={
                          allowAddress ? companyAddress[0].state : ""
                        }
                        isDisabled={allowAddress}
                        {...register("traineeState")}
                      ></Input>
                    ) : editData.company_id > 1 ? (
                      <Box
                        _hover={{ cursor: "not-allowed" }}
                        borderRadius="10px"
                        borderWidth="2px"
                        mb="7px"
                        pl="15px"
                        py="7px"
                        fontSize="17px"
                      >
                        {showAddress.state}
                      </Box>
                    ) : (
                      <Input
                        mt="5px"
                        resize="none"
                        style={{ height: "50px" }}
                        placeholder="State"
                        defaultValue={showAddress.state}
                        isDisabled={editData.company_id > 1 ? true : false}
                        {...register("traineeState")}
                      ></Input>
                    )}
                  </FormControl>
                </Box>
              </HStack>
              <HStack>
                <Box w="50%">
                  <FormControl>
                    <FormLabel mt="10px" fontWeight="bold">
                      Payment Status
                    </FormLabel>
                    <Select
                      w="100%"
                      defaultValue={editData.trainee_payment}
                      {...register("traineePayment")}
                    >
                      <option value="" disabled>
                        Select payment status
                      </option>
                      <option value={0}>Pending</option>{" "}
                      <option value={1}>Processing</option>{" "}
                      <option value={2}>Completed</option>
                    </Select>
                  </FormControl>
                </Box>
                <Box w="50%">
                  <FormControl>
                    <FormLabel mt="10px" fontWeight="bold">
                      HRDF Claimed
                    </FormLabel>
                    <CheckboxGroup
                      colorScheme="green"
                      defaultValue={[editData.trainee_hrdf]}
                    >
                      <Checkbox value="1" {...register("traineeHRDF")}>
                        Yes
                      </Checkbox>
                    </CheckboxGroup>
                  </FormControl>
                </Box>
              </HStack>

              <FormControl>
                <FormLabel mt="10px" fontWeight="bold">
                  SPECIAL DIETARY / ALLERGIES
                </FormLabel>
                <Input
                  placeholder="Fill if applicable"
                  defaultValue={editData.trainee_allergies}
                  {...register("traineeAllergies")}
                ></Input>
              </FormControl>
              <HStack>
                <FormControl>
                  <FormLabel mt="10px" fontWeight="bold">
                    REFERRER
                  </FormLabel>
                  <Input
                    placeholder="Referrer"
                    defaultValue={editData.trainee_referrer}
                    {...register("traineeReferrer")}
                  ></Input>
                </FormControl>
                <FormControl>
                  <FormLabel mt="10px" fontWeight="bold">
                    PROMO CODE
                  </FormLabel>
                  {/* <ChakraSelectReact
                    fontSize={{ md: "14px", base: "12px" }}
                    options={promoCode}
                    placeholder="Select promo code"
                    closeMenuOnSelect={true}
                  /> */}
                  {/* <Select
                    placeholder="Select promo code"
                    defaultValue={editData.trainee_promo}
                    {...register("traineePromo")}
                  >
                    {promoCode.map((elem) => (
                      <option value={elem.discount_id}>
                        {elem.discount_name}
                      </option>
                    ))}
                  </Select> */}
                  <Select
                    w="100%"
                    defaultValue={editData.disc_id}
                    {...register("discID")}
                  >
                    <option value="">Select promo code</option>
                    {promoCode.map((elem) => (
                      <option value={elem.value}>{elem.label}</option>
                    ))}
                  </Select>
                </FormControl>
              </HStack>
            </DrawerBody>

            <Center>
              <DrawerFooter>
                <Button
                  fontWeight="medium"
                  w="200px"
                  color="white"
                  bg="#33945f"
                  _hover={{ bg: "green.700" }}
                  type="submit"
                >
                  Edit
                </Button>
                <Button
                  fontWeight="medium"
                  w="200px"
                  variant="outline"
                  ml={3}
                  onClick={() => {
                    reset();
                    resetErr();
                    setChangeCompany(false);
                    setAllowAddress(false);
                    setNewCompany("");
                    editTrainee.onClose();
                  }}
                >
                  Cancel
                </Button>
              </DrawerFooter>
            </Center>
          </form>
        </DrawerContent>
      </Drawer>
      {/* -----------------------------------end edit drawer-------------------------- */}
      {/* -----------------------------------------punch in modal------------------------------------------- */}
      <Modal
        isOpen={modalPunchinOut.isOpen}
        onClose={modalPunchinOut.onClose}
        size="3xl"
        display="grid"
      >
        <ModalOverlay />
        <ModalContent height="75%" alignItems="center">
          <ModalHeader textAlign="center" fontSize="25px" py="5">
            Copy the QR Code to punch {punch === "in" ? "in" : "out"}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <div
              ref={downloadRef}
              style={{ display: "flex", maxWidth: "400px", maxHeight: "400px" }}
            >
              <img src={qr} />
            </div>
          </ModalBody>

          <ModalFooter>
            {qr && (
              <>
                <Button
                  mr={3}
                  bgColor="#33945F"
                  color="white"
                  onClick={handleDownloadImage}
                >
                  Download
                </Button>
              </>
            )}
            <Button variant="outline" onClick={modalPunchinOut.onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {/* -----------------------------------------punch in modal end------------------------------------------- */}
      {/* -----------------------------------------hrdf modal--------------------------------------------------- */}
      <Modal isOpen={mailHRDF.isOpen} onClose={mailHRDF.onClose} size="6xl">
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <Stack spacing={4} direction="row">
              <Box width={"60%"} height={"100%"}>
                <FormControl mt={4}>
                  <FormLabel>From</FormLabel>
                  <InputGroup>
                    <Input
                      value="info1@holisticslab.my"
                      disabled={!allowEdit}
                    />
                    <InputRightElement>
                      <IconButton
                        colorScheme="gray"
                        fontSize="20px"
                        icon={<EditIcon color="black" />}
                        onClick={() => setAllowEdit(!allowEdit)}
                      />
                    </InputRightElement>
                  </InputGroup>
                </FormControl>
                <FormControl mt={4}>
                  <FormLabel>To</FormLabel>
                  <Input />
                </FormControl>
                <FormControl mt={4}>
                  <FormLabel>Subject</FormLabel>
                  <Input />
                </FormControl>
                <FormControl mt={4}>
                  <ReactQuill
                    theme="snow"
                    value={content}
                    onChange={setContent}
                  ></ReactQuill>
                </FormControl>
              </Box>
              <Box w="40%">
                <VStack>
                  <AspectRatio width="100%" h="348px">
                    <Box
                      my="20px"
                      borderColor="gray.300"
                      borderStyle="dashed"
                      borderWidth="2px"
                      rounded="md"
                      shadow="sm"
                      role="group"
                      _hover={{
                        shadow: "md",
                      }}
                      whileHover="hover"
                    >
                      <Box position="relative" height="100%" width="100%">
                        <Box
                          position="absolute"
                          top="0"
                          left="0"
                          height="100%"
                          width="100%"
                          display="flex"
                          flexDirection="column"
                        >
                          <Stack
                            height="100%"
                            width="100%"
                            display="flex"
                            alignItems="center"
                            justify="center"
                            spacing="4"
                          >
                            <Stack p="8" textAlign="center" spacing="1">
                              <Heading
                                fontSize="lg"
                                color="gray.700"
                                fontWeight="bold"
                              >
                                Click to upload
                              </Heading>
                              <Text fontWeight="light">or drop images</Text>
                            </Stack>
                            <Box>
                              <FiUpload color="#9fa2b4" size="40px" />
                            </Box>
                          </Stack>
                        </Box>
                        <Input
                          type="file"
                          height="100%"
                          width="100%"
                          position="absolute"
                          top="0"
                          left="0"
                          opacity="0"
                          aria-hidden="true"
                          accept="image/*"
                        />
                      </Box>
                    </Box>
                  </AspectRatio>
                  <br />
                  <FormControl display="flex" alignItems="center">
                    <FormLabel w="50%">Return receipt</FormLabel>
                    <Switch id="email-alerts" />
                  </FormControl>
                  <FormControl display="flex" alignItems="center">
                    <FormLabel w="50%">Delivery status notification</FormLabel>
                    <Switch id="email-alerts" />
                  </FormControl>
                  <FormControl display="flex" alignItems="center">
                    <FormLabel w="50%">Priority</FormLabel>
                    <Select w="50%" size="sm" placeholder="Select">
                      <option>Low</option>
                      <option>Normal</option>
                      <option>High</option>
                    </Select>
                  </FormControl>
                  <FormControl display="flex" alignItems="center">
                    <FormLabel w="50%">Save sent messages in </FormLabel>
                    <Select w="50%" size="sm" placeholder="Select">
                      <option>Drafts</option>
                      <option>Sent</option>
                      <option>Trash</option>
                      <option>Inbox</option>
                    </Select>
                  </FormControl>
                </VStack>
              </Box>
            </Stack>
          </ModalBody>

          <ModalFooter>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <Button
                bg="#33945f"
                color="white"
                mr={3}
                fontSize={"14px"}
                w={"10%"}
              >
                Send
              </Button>
              <Button
                variant="outline"
                onClick={mailHRDF.onClose}
                fontSize={"14px"}
                w={"10%"}
              >
                Cancel
              </Button>
            </div>
          </ModalFooter>
        </ModalContent>
      </Modal>
      {/* --------------------------------hrdf modal end------------------------------------------------------ */}

      {/* --------------------------------groupinvoice modal start------------------------------------------------------ */}
      <Modal
        size="4xl"
        isOpen={groupInvoice.isOpen}
        onClose={() => {
          reset();
          groupInvoice.onClose();
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <form onSubmit={handleSubmit(groupSubmit)}>
            <ModalHeader
              bg="#f1f1f1"
              borderTopRadius={10}
              alignContent="center"
            >
              Group Invoice
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody p="30px">
              <Box p="5px">
                <b>Invoice Number: </b>
                <Input
                  mb="10px"
                  placeholder="Insert invoice num"
                  {...register("groupInvNo")}
                ></Input>
                <b>Invoice Date: </b>
                <Input mb="10px" type="date" {...register("groupInvDate")} />
              </Box>
              <div style={{ position: "absolute", right: "20px" }}>
                <Box borderRadius="5px" px="10px" bg="#f1f1f1">
                  <span style={{ color: "green" }}>☑</span> HRDC Claim{" "}
                  <span style={{ color: "red" }}>☒</span> Non-claimable
                </Box>
              </div>
              <br />
              {data &&
                data.map(
                  (data) =>
                    data.company_id !== 1 && (
                      <Stack>
                        <Checkbox
                          style={{ textTransform: "capitalize" }}
                          {...register("groupInvCheckbox")}
                          value={data.trainee_id}
                        >
                          {data.trainee_hrdf === 1 ? (
                            <span style={{ color: "green" }}>☑</span>
                          ) : (
                            <span style={{ color: "red" }}>☒</span>
                          )}{" "}
                          <span style={{ fontWeight: "bold" }}>
                            {data.company.company_name}
                          </span>
                          {": "}
                          {data.trainee_name.toLowerCase()}
                        </Checkbox>
                      </Stack>
                    )
                  // )
                )}
            </ModalBody>

            <ModalFooter>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <Button
                  onClick={() => {
                    setButtonType("view");
                  }}
                  type="submit"
                  variant="ghost"
                  mr={3}
                >
                  View Invoice
                </Button>
                &nbsp;
                <Button
                  onClick={() => {
                    setButtonType("send");
                  }}
                  type="submit"
                  bg="#33945f"
                  color="white"
                >
                  Send Invoice
                </Button>
              </div>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
      {/* --------------------------------groupinvoice modal end------------------------------------------------------ */}

      {/* --------------------------------viwe invoice modal start------------------------------------------------------ */}
      <Modal
        size="4xl"
        isOpen={viewInvoice.isOpen}
        onClose={viewInvoice.onClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader bg="#f1f1f1" borderTopRadius={10} alignContent="center">
            View Invoice
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody p="30px">
            <div
              dangerouslySetInnerHTML={{
                __html: testViewInvoice,
              }}
            />
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" onClick={viewInvoice.onClose}>
              Close
            </Button>
            {/* <Button
              colorScheme="green"
              mr={3}
              onClick={handleSubmit(groupSubmit)}
            >
              Send Invoice
            </Button> */}
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* --------------------------------viwe invoice modal start------------------------------------------------------ */}

      {/* -----------------------------------------punch in modal end------------------------------------------- */}
      {/* -----------------------------------------invoice modal--------------------------------------------------- */}
      <ModalComponent
        trigger={invoiceDetail}
        loader={loader}
        onSubmit={onSubmit}
        register={register}
        handleSubmit={handleSubmit}
        inputArr={[
          {
            type: "text",
            label: "Invoice Num",
            param: "invoiceNum",
            select: [],
          },
          {
            type: "date",
            label: "Invoice Date",
            param: "invoiceDate",
            select: [],
          },
        ]}
        buttonName={"Send"}
        buttonName2={"View"}
        setButtonType={setButtonType}
      />

      {/* --------------------------------invoice modal end------------------------------------------------------ */}
      {/* -----------------------------------------punch in modal end------------------------------------------- */}
      {/* -----------------------------------------receipt modal--------------------------------------------------- */}

      <ModalComponent
        trigger={receiptDetail}
        loader={loader}
        onSubmit={onSubmit}
        register={register}
        handleSubmit={handleSubmit}
        inputArr={[
          {
            type: "text",
            label: "Receipt Num",
            param: "receiptNum",
            select: [],
          },
          {
            type: "date",
            label: "Receipt Date",
            param: "receiptDate",
            select: [],
          },
          {
            type: "text",
            label: "Receipt Amount",
            param: "receiptAmount",
            select: [],
          },
        ]}
        buttonName={"Send"}
      />
      {/* --------------------------------receipt modal end------------------------------------------------------ */}
      {/* -----------------------------------------punch in modal end------------------------------------------- */}
      {/* -----------------------------------------move to modal--------------------------------------------------- */}
      <ModalComponent
        trigger={moveTraining}
        loader={loader}
        onSubmit={onSubmit}
        register={register}
        handleSubmit={handleSubmit}
        inputArr={[
          {
            type: "text",
            label: "Training",
            param: "trainingID",
            select: trainingNames,
          },
        ]}
        buttonName={"Move"}
      />
      {/* --------------------------------move to modal end------------------------------------------------------ */}

      <ChakraAlertDialog
        title={"Delete Trainee"}
        dialogProps={deleteMenu}
        url={`${URL_STAGING}trainee/delete/${traineeID}/${state.id}`}
        alert={alert}
        setAlert={setAlert}
        setData={setData}
        body={"Are you sure? You can't undo this action afterwards."}
        button={"Delete"}
      />

      <ChakraAlertDialog
        title={"Withdraw Trainee"}
        dialogProps={withdrawMenu}
        url={[
          `${URL_STAGING}withdrawtrainee/${traineeID}`,
          JSON.stringify({ trainee_status: "3", training_id: state.id }),
        ]}
        alert={alert}
        setAlert={setAlert}
        setData={setData}
        body={
          "Are you sure to withdraw this candidate? You can't undo this action afterwards."
        }
        button={"Withdraw"}
      />

      <ChakraAlertDialog
        title={"Register Trainee"}
        dialogProps={joinMenu}
        url={[
          `${URL_STAGING}registerstudent/${traineeID}`,
          JSON.stringify({ trainee_status: "2", training_id: state.id }),
        ]}
        alert={alert}
        setAlert={setAlert}
        setData={setData}
        body={
          "Are you sure to register this participant? You can't undo this action afterwards."
        }
        button={"Register"}
      />

      <ErrorsDialog
        title={"Errors"}
        dialogProps={errorProps}
        body={errorAlert}
      />
    </div>
  );
}

export { TraineeAdmin };
