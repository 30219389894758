export const BaseURL = "http://127.0.0.1:8000/api";
// export const BaseURL = "http://halal_tms2.0-backend.myhalalgig.com/public/api";
export const login = BaseURL + "/login";
export const register = BaseURL + "/register";
export const course = BaseURL + "/course";
export const cohort = BaseURL + "/cohort";
export const trainee = BaseURL + "/trainee";
export const company = BaseURL + "/company";
export const invoice = BaseURL + "/invoice";
export const setting = BaseURL + "/setting";
export const state = BaseURL + "/state";
export const discount = BaseURL + "/discount";
export const billing = BaseURL + "/billing";
export const staff = BaseURL + "/staff";
export const profile = BaseURL + "/profile";
export const bill = BaseURL + "/bill";
export const mail = BaseURL + "/mail";
export const forgotpassword = BaseURL + "/forgotpassword";
export const resetpassword = BaseURL + "/resetpassword";
export const URL_STAGING = "https://myhalalgig.com/htms-api/public/api/";
export const URL_LOCAL = "http://localhost:8000/api/";
export const CAPTCHA_SITE_KEY = "6LcNU4ojAAAAALMhTx2u2u3VfqPB9Ntbfttkm3wu";
