import {
  Container,
  Box,
  Input,
  Heading,
  Text,
  Button,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  useDisclosure,
  Stack,
  Radio,
  RadioGroup,
  Center,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Divider,
  ModalFooter,
  HStack,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import CRUDFunction from "../../../functions/CRUDFunction";
import { format } from "date-fns";
import { URL_LOCAL, URL_STAGING } from "../../../constant";

function Individu() {
  let navigate = useNavigate();
  const { state } = useLocation();
  const onlineModal = useDisclosure();
  const physicalModal = useDisclosure();
  const confirmModal = useDisclosure();
  const [radioId, setRadioId] = useState();
  const [course, setCourse] = useState([
    {
      course_about: "",
      course_eligibility: "",
      course_id: "",
      course_image: "",
      course_name: "",
      course_outline: "",
      trainings: [],
    },
  ]);
  const trainingModes = [
    "Fulltime online inhouse",
    "Fulltime physical inhouse",
    "Parttime online inhouse",
    "Parttime physical inhouse",
    "Fulltime online public",
    "Fulltime physical public",
    "Parttime online public",
    "Parttime physical public",
  ];
  const displayModes = [
    "Full Time Online Inhouse",
    "Full Time Physical Inhouse",
    "Part Time Online Inhouse",
    "Part Time Physical Inhouse",
    "Full Time Online",
    "Full Time Physical",
    "Part Time Online",
    "Part Time Physical",
  ];
  const [trainingDetails, setTrainingDetails] = useState([]);
  const [date, setDate] = useState([]);
  const [confirm, setConfirm] = useState();
  const confirms = "";
  const [userdata, setUserData] = useState([
    {
      trainee_name: "",
      trainee_ic: "",
      trainee_email: "",
      trainee_position: "",
      trainee_phone: "",
      trainee_address: "",
      trainee_allergies: "",
      trainee_promo: "",
      training_id: "",
    },
  ]);
  const [oldAddr, setOldAddr] = useState([]);

  useEffect(() => {
    fetchCourse();
  }, []);

  const fetchCourse = async () => {
    const res = await CRUDFunction.get(
      `${URL_STAGING}coursedetails/${state.id}`
    );
    if (res) {
      let trainings = [];
      if (res[0].training_id != null) {
        res.map(
          (i) =>
            new Date(JSON.parse(i.training_date)[0].startDate).getTime() >
              new Date().getTime() &&
            trainings.push({
              training_id: i.training_id,
              training_name: i.training_name,
              training_date: JSON.parse(i.training_date),
              training_detail: i.training_detail,
              training_fee: i.training_fee,
              training_place: i.training_place,
              training_mode: i.training_mode,
            })
        );
      }
      setCourse({
        course_about: res[0].course_about,
        course_eligibility: res[0].course_eligibility,
        course_id: res[0].course_id,
        course_image: res[0].course_image,
        course_name: res[0].course_name.toUpperCase(),
        course_outline: res[0].course_outline,
        trainings: trainings,
      });
    }
  };

  const modeSplitter = (mode) => {
    return mode.split(" ");
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm();

  const resetAll = () => {
    reset();
    setTrainingDetails([]);
    setDate([]);
  };

  const toast = useToast();

  const verifyTraineeIC = async (data) => {
    return new Promise((resolve) => {
      const res = CRUDFunction.create(`${URL_STAGING}traineeVerify`, {
        trainee_ic: data,
      });
      resolve(res);
    });
  };

  const modalConfirm = async (confirm) => {
    resetAll();
    navigate("../checkout", {
      replace: false,
      state: {
        company: {
          company_id: 1,
          company_image: "",
          company_name: "",
          company_ssm: "",
          company_address: "",
          company_picname: "",
          company_picphone: "",
          company_picic: "",
          company_picemail: "",
          company_picposition: "",
        },
        participants: {
          trainee_name: userdata.trainee_name,
          trainee_ic: userdata.trainee_ic,
          trainee_email: userdata.trainee_email,
          trainee_position: "Self-Employed",
          trainee_phone: userdata.trainee_phone,
          trainee_address:
            confirm === "true" ? userdata.trainee_address : oldAddr,
          trainee_allergies: userdata.trainee_allergies,
          trainee_promo: userdata.trainee_promo.toUpperCase(),
        },
        training: trainingDetails,
        course_id: state.id,
        total_trainee: 1,
        training_id: userdata.training_id,
        login: "individu",
        update_address: confirm === "true" ? true : false,
      },
    });
  };
  const onSubmit = async (data) => {
    if (data) {
      console.log(data);
      let combineAddress = [
        {
          address: data.traineeAddress,
          postcode: data.traineePostcode,
          state: data.traineeState,
        },
      ];
      setUserData({
        trainee_name: data.traineeName,
        trainee_ic: data.traineeIC,
        trainee_email: data.traineeEmail,
        trainee_phone: data.traineePhone,
        trainee_address: JSON.stringify(combineAddress),
        trainee_allergies: data.traineeAllergies,
        trainee_promo: data.traineePromo,
        training_id: data.trainingId,
      });
      //validating promo code
      if (data.traineePromo.toUpperCase()) {
        const res = await CRUDFunction.get(
          `${URL_STAGING}checkdiscount/training/${
            trainingDetails.training_id
          }/${data.traineePromo.toUpperCase()}`
        );
        if (res === "invalid") {
          toast({
            title: "Failed",
            description: "Invalid promo code.",
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        } else if (res === "expired") {
          toast({
            title: "Failed",
            description: "The promo code has expired.",
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        } else if (res === "not active") {
          toast({
            title: "Failed",
            description: "The promo code is not active yet.",
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        } else if (res === "zero") {
          toast({
            title: "Failed",
            description: "The promo code has reached its redeem limit.",
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        }
      } else {
        const verify = await verifyTraineeIC(data.traineeIC);
        if (verify) {
          confirmModal.onOpen();
          setOldAddr(verify.trainee_address);
        } else {
          resetAll();
          navigate("../checkout", {
            replace: false,
            state: {
              company: {
                company_id: 1,
                company_image: "",
                company_name: "",
                company_ssm: "",
                company_address: "",
                company_picname: "",
                company_picphone: "",
                company_picic: "",
                company_picemail: "",
                company_picposition: "",
              },
              participants: {
                trainee_name: data.traineeName,
                trainee_ic: data.traineeIC,
                trainee_email: data.traineeEmail,
                trainee_position: "Self-Employed",
                trainee_phone: data.traineePhone,
                trainee_address: JSON.stringify(combineAddress),
                trainee_allergies: data.traineeAllergies,
                trainee_promo: data.traineePromo.toUpperCase(),
              },
              training: trainingDetails,
              course_id: state.id,
              total_trainee: 1,
              training_id: data.trainingId,
              login: "individu",
            },
          });
        }
      }
    }
  };

  const modalSubmit = async (data) => {
    const res = await CRUDFunction.get(
      `${URL_STAGING}training/${data.trainingId}`
    );
    setTrainingDetails(res);
    setDate(JSON.parse(res.training_date));
    onlineModal.isOpen ? onlineModal.onClose() : physicalModal.onClose();
  };

  return (
    <>
      <Grid mx="100px" templateColumns="repeat(6, 1fr)" gap={3}>
        <GridItem colSpan={4}>
          <Container maxW={"auto"} maxH={"auto"}>
            <Box
              style={{ boxShadow: "0px 2px 4px #c4c4c4", display: "grid" }}
              my="10px"
            >
              <br />
              <Heading size="3xl" align="center">
                Registration
              </Heading>
              <br />
              <Heading size="md" align="center">
                {course.course_name}
              </Heading>
              <div style={{ padding: "20px 70px" }}>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <FormControl isRequired>
                    <FormLabel fontSize="13px" mt="10px" fontWeight="bold">
                      TRAINING SESSION
                    </FormLabel>
                    <InputGroup size="md" mb="8px">
                      <Input
                        fontSize="14px"
                        disabled
                        value={trainingDetails.training_name}
                        placeholder="Select session >"
                        style={{ opacity: "1", cursor: "default" }}
                      />
                      <InputRightElement width="auto">
                        <Menu>
                          <MenuButton
                            as={Button}
                            w="260px"
                            borderLeftRadius="0px"
                            borderRightRadius="10px"
                            bgColor="white"
                            borderWidth="2px"
                            borderColor="black"
                            letterSpacing={1}
                            fontSize="14px"
                          >
                            Select training session
                          </MenuButton>
                          <MenuList>
                            <MenuItem
                              pl="30px"
                              fontSize="15px"
                              onClick={() => {
                                onlineModal.onOpen();
                              }}
                            >
                              Online
                            </MenuItem>
                            <MenuItem
                              pl="30px"
                              fontSize="15px"
                              onClick={() => {
                                physicalModal.onOpen();
                              }}
                            >
                              Physical
                            </MenuItem>
                          </MenuList>
                        </Menu>
                      </InputRightElement>
                    </InputGroup>
                  </FormControl>

                  <FormControl isRequired>
                    <FormLabel fontSize="13px" mt="10px" fontWeight="bold">
                      IDENTIFICATION NUMBER
                    </FormLabel>
                    <Input
                      type="text"
                      fontSize="14px"
                      placeholder="IC"
                      {...register("traineeIC")}
                    ></Input>
                  </FormControl>

                  <FormControl isRequired>
                    <FormLabel fontSize="13px" mt="10px" fontWeight="bold">
                      NAME
                    </FormLabel>
                    <Input
                      fontSize="14px"
                      placeholder="Participant name"
                      {...register("traineeName")}
                    ></Input>
                  </FormControl>

                  <FormControl isRequired>
                    <FormLabel fontSize="13px" mt="10px" fontWeight="bold">
                      EMAIL
                    </FormLabel>
                    <Input
                      type="email"
                      fontSize="14px"
                      placeholder="Email"
                      {...register("traineeEmail")}
                    ></Input>
                  </FormControl>

                  <FormControl isRequired>
                    <FormLabel fontSize="13px" mt="10px" fontWeight="bold">
                      PHONE NUMBER
                    </FormLabel>
                    <Input
                      type="number"
                      fontSize="14px"
                      placeholder="Phone number"
                      {...register("traineePhone")}
                    ></Input>
                  </FormControl>

                  <FormControl isRequired>
                    <FormLabel fontSize="13px" mt="10px" fontWeight="bold">
                      ADDRESS
                    </FormLabel>
                    <Input
                      fontSize="14px"
                      placeholder="Address"
                      {...register("traineeAddress")}
                    ></Input>
                  </FormControl>

                  <HStack>
                    <FormControl isRequired>
                      <FormLabel fontSize="13px" mt="10px" fontWeight="bold">
                        POSTCODE
                      </FormLabel>
                      <Input
                        type="number"
                        fontSize="14px"
                        placeholder="Postcode"
                        {...register("traineePostcode")}
                      ></Input>
                    </FormControl>

                    <FormControl isRequired>
                      <FormLabel fontSize="13px" mt="10px" fontWeight="bold">
                        STATE
                      </FormLabel>
                      <Input
                        fontSize="14px"
                        placeholder="State"
                        {...register("traineeState")}
                      ></Input>
                    </FormControl>
                  </HStack>

                  <FormControl>
                    <FormLabel fontSize="13px" mt="10px" fontWeight="bold">
                      DIETARY / ALLERGIES
                    </FormLabel>
                    <Input
                      fontSize="14px"
                      placeholder="Allergies"
                      {...register("traineeAllergies")}
                    ></Input>
                  </FormControl>

                  <FormControl>
                    <FormLabel fontSize="13px" mt="10px" fontWeight="bold">
                      PROMO CODE
                    </FormLabel>
                    <Input
                      maxLength={6}
                      minLength={6}
                      fontSize="14px"
                      placeholder="Promo Code (max 6 characters)"
                      {...register("traineePromo")}
                    ></Input>
                  </FormControl>

                  <div style={{ float: "right", display: "flex" }}>
                    <Button
                      type="submit"
                      w="170px"
                      fontSize="15px"
                      bg="#33945f"
                      color="white"
                      mt="20px"
                      _hover={{
                        backgroundColor: "white",
                        color: "#33945f",
                        border: "1px solid #33945f",
                      }}
                    >
                      Register
                    </Button>
                  </div>
                </form>
              </div>
            </Box>
          </Container>
        </GridItem>
        <GridItem colSpan={2}>
          <Container maxW={"auto"} maxH={"auto"}>
            <Box
              display="grid"
              p="10px 20px 10px 20px"
              borderRadius="10px"
              boxShadow="xl"
            >
              <br />
              <Heading size="lg" align="center">
                Training Details
              </Heading>
              <br />

              <Divider
                orientation="horizontal"
                border="5px solid"
                borderColor="#33945f"
                mb="5"
              />
              <div style={{ padding: "1px 3px" }}>
                <Box borderBottomWidth="2px" borderColor="gray" mb="10">
                  <b>COURSE</b>
                  <Text fontSize="14px" py="10px">
                    {course.course_name}
                  </Text>
                </Box>
                <Box borderBottomWidth="2px" borderColor="gray" mb="10">
                  <b>TRAINING NAME</b>
                  <Text fontSize="14px" py="10px">
                    {trainingDetails.training_name}
                  </Text>
                </Box>
                <Box
                  borderBottomWidth="2px"
                  borderColor="gray"
                  mb="10"
                  pb="10px"
                >
                  <b>TRAINING DATE</b>
                  {trainingDetails &&
                    date.map((item, index) => (
                      <>
                        {item.endDate === item.startDate ? (
                          <Text fontSize="14px">
                            {format(new Date(item.startDate), "dd LLLL y")}
                          </Text>
                        ) : (
                          <Text fontSize="14px">
                            Week {index + 1}:{" "}
                            {format(new Date(item.startDate), "dd LLLL y")} -{" "}
                            {format(new Date(item.endDate), "dd LLLL y")}
                          </Text>
                        )}
                      </>
                    ))}
                </Box>
                <Box borderBottomWidth="2px" borderColor="gray" mb="10">
                  <b>TRAINING TIME</b>
                  <Text fontSize="14px" py="10px">
                    {trainingDetails.training_time && (
                      <Text>
                        {Number(
                          JSON.parse(trainingDetails.training_time)[0][0] * 10
                        ) +
                          Number(
                            JSON.parse(trainingDetails.training_time)[0][1]
                          ) <
                        11 ? (
                          <>
                            {Number(
                              JSON.parse(trainingDetails.training_time)[0][0] *
                                10
                            ) +
                              Number(
                                JSON.parse(trainingDetails.training_time)[0][1]
                              )}
                            {JSON.parse(trainingDetails.training_time)[0].slice(
                              2
                            )}{" "}
                            AM
                          </>
                        ) : Number(
                            JSON.parse(trainingDetails.training_time)[0][0] * 10
                          ) +
                            Number(
                              JSON.parse(trainingDetails.training_time)[0][1]
                            ) >
                          12 ? (
                          <>
                            {Number(
                              JSON.parse(trainingDetails.training_time)[0][0] *
                                10
                            ) +
                              Number(
                                JSON.parse(trainingDetails.training_time)[0][1]
                              ) -
                              12}
                            {JSON.parse(trainingDetails.training_time)[0].slice(
                              2
                            )}{" "}
                            PM
                          </>
                        ) : (
                          <>
                            {Number(
                              JSON.parse(trainingDetails.training_time)[0][0] *
                                10
                            ) +
                              Number(
                                JSON.parse(trainingDetails.training_time)[0][1]
                              )}
                            {JSON.parse(trainingDetails.training_time)[0].slice(
                              2
                            )}{" "}
                            PM
                          </>
                        )}{" "}
                        -{" "}
                        {Number(
                          JSON.parse(trainingDetails.training_time)[1][0] * 10
                        ) +
                          Number(
                            JSON.parse(trainingDetails.training_time)[1][1]
                          ) <
                        11 ? (
                          <>
                            {Number(
                              JSON.parse(trainingDetails.training_time)[1][0] *
                                10
                            ) +
                              Number(
                                JSON.parse(trainingDetails.training_time)[1][1]
                              )}
                            {JSON.parse(trainingDetails.training_time)[1].slice(
                              2
                            )}{" "}
                            AM
                          </>
                        ) : Number(
                            JSON.parse(trainingDetails.training_time)[1][0] * 10
                          ) +
                            Number(
                              JSON.parse(trainingDetails.training_time)[1][1]
                            ) >
                          12 ? (
                          <>
                            {Number(
                              JSON.parse(trainingDetails.training_time)[1][0] *
                                10
                            ) +
                              Number(
                                JSON.parse(trainingDetails.training_time)[1][1]
                              ) -
                              12}
                            {JSON.parse(trainingDetails.training_time)[1].slice(
                              2
                            )}{" "}
                            PM
                          </>
                        ) : (
                          <>
                            {Number(
                              JSON.parse(trainingDetails.training_time)[1][0] *
                                10
                            ) +
                              Number(
                                JSON.parse(trainingDetails.training_time)[1][1]
                              )}
                            {JSON.parse(trainingDetails.training_time)[1].slice(
                              2
                            )}{" "}
                            PM
                          </>
                        )}
                      </Text>
                    )}
                  </Text>
                </Box>
                <Box borderBottomWidth="2px" borderColor="gray" mb="10">
                  <b>TRAINING PLACE</b>
                  <Text fontSize="14px" py="10px">
                    {trainingDetails.training_place}
                  </Text>
                </Box>
                <Box borderBottomWidth="2px" borderColor="gray" mb="10">
                  <b>TRAINING MODE</b>
                  <Text fontSize="14px" py="10px">
                    {displayModes[trainingDetails.training_mode]}
                  </Text>
                </Box>
                <Box borderBottomWidth="2px" borderColor="gray" mb="10">
                  <b>TRAINING DETAILS</b>
                  <Text fontSize="14px" py="10px">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: trainingDetails.training_detail,
                      }}
                    />
                  </Text>
                </Box>
                <Box borderBottomWidth="2px" borderColor="gray" mb="10">
                  <b>TRAINING FEE</b>
                  <Text fontSize="14px" py="10px">
                    RM {trainingDetails.training_fee}
                  </Text>
                </Box>
              </div>
            </Box>
          </Container>
        </GridItem>
      </Grid>

      {/* -----------------------------------ONLINE MODAL----------------------------------- */}
      <Modal
        size="2xl"
        isOpen={onlineModal.isOpen}
        onClose={() => {
          reset();
          onlineModal.onClose();
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <form onSubmit={handleSubmit(modalSubmit)}>
            <Box my="20px">
              <ModalHeader textAlign="center">
                Select training session
              </ModalHeader>
            </Box>
            <ModalBody>
              <RadioGroup
                value={radioId}
                onChange={(event) => {
                  const choiceId = Number(event);
                  setRadioId(choiceId);
                }}
              >
                <Text ml="30px">Full time</Text>
                <Box my="10px" mx="50px">
                  <Stack direction="column">
                    {course.trainings &&
                      (course.trainings.length > 0 ? (
                        course.trainings.map((item) => {
                          if (
                            modeSplitter(
                              trainingModes[item.training_mode]
                            )[0] === "Fulltime" &&
                            modeSplitter(
                              trainingModes[item.training_mode]
                            )[1] === "online"
                          ) {
                            return (
                              <Radio
                                value={item.training_id}
                                {...register("trainingId")}
                              >
                                {item.training_date[0].startDate !=
                                item.training_date[0].endDate
                                  ? format(
                                      new Date(item.training_date[0].startDate),
                                      "dd LLLL"
                                    ) +
                                    " - " +
                                    format(
                                      new Date(
                                        item.training_date[
                                          item.training_date.length - 1
                                        ].endDate
                                      ),
                                      "dd LLLL y"
                                    ) +
                                    ", " +
                                    item.training_name
                                  : format(
                                      new Date(item.training_date[0].startDate),
                                      "dd LLLL y"
                                    ) +
                                    ", " +
                                    item.training_name}
                              </Radio>
                            );
                          } else {
                          }
                        })
                      ) : (
                        <Text>adfs</Text>
                      ))}
                  </Stack>
                </Box>
                <Text ml="30px">Part time</Text>
                <Box my="10px" mx="50px">
                  <Stack direction="column">
                    {course.trainings &&
                      (course.trainings.length > 0 ? (
                        course.trainings.map((item) => {
                          if (
                            modeSplitter(
                              trainingModes[item.training_mode]
                            )[0] === "Parttime" &&
                            modeSplitter(
                              trainingModes[item.training_mode]
                            )[1] === "online"
                          ) {
                            return (
                              <Radio
                                value={item.training_id}
                                {...register("trainingId")}
                              >
                                {item.training_date[0].startDate !=
                                item.training_date[0].endDate
                                  ? format(
                                      new Date(item.training_date[0].startDate),
                                      "dd LLLL"
                                    ) +
                                    " - " +
                                    format(
                                      new Date(
                                        item.training_date[
                                          item.training_date.length - 1
                                        ].endDate
                                      ),
                                      "dd LLLL y"
                                    ) +
                                    ", " +
                                    item.training_name
                                  : format(
                                      new Date(item.training_date[0].startDate),
                                      "dd LLLL y"
                                    ) +
                                    ", " +
                                    item.training_name}
                              </Radio>
                            );
                          } else {
                          }
                        })
                      ) : (
                        <Text>adfs</Text>
                      ))}
                  </Stack>
                </Box>
              </RadioGroup>
            </ModalBody>
            <Center>
              <ModalFooter>
                <Button
                  type="submit"
                  w="170px"
                  mr="10px"
                  fontSize="15px"
                  bg="#33945f"
                  color="white"
                  mt="20px"
                  _hover={{
                    backgroundColor: "white",
                    color: "#33945f",
                    border: "1px solid #33945f",
                  }}
                >
                  Select
                </Button>
                <Button
                  w="170px"
                  fontSize="15px"
                  bg="white"
                  color="#33945f"
                  mt="20px"
                  onClick={() => {
                    reset();
                    onlineModal.onClose();
                  }}
                >
                  Cancel
                </Button>
              </ModalFooter>
            </Center>
          </form>
        </ModalContent>
      </Modal>
      {/* -----------------------------------END ONLINE MODAL----------------------------------- */}

      {/* -----------------------------------PHYSICAL MODAL----------------------------------- */}
      <Modal
        size="2xl"
        isOpen={physicalModal.isOpen}
        onClose={() => {
          reset();
          physicalModal.onClose();
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <form onSubmit={handleSubmit(modalSubmit)}>
            <Box my="20px">
              <ModalHeader textAlign="center">
                Select training session
              </ModalHeader>
            </Box>
            <ModalBody>
              <RadioGroup
                value={radioId}
                onChange={(event) => {
                  const choiceId = Number(event);
                  setRadioId(choiceId);
                }}
              >
                <Text ml="30px">Full time</Text>
                <Box my="10px" mx="50px">
                  <Stack direction="column">
                    {course.trainings &&
                      (course.trainings.length > 0 ? (
                        course.trainings.map((item) => {
                          if (
                            modeSplitter(
                              trainingModes[item.training_mode]
                            )[0] === "Fulltime" &&
                            modeSplitter(
                              trainingModes[item.training_mode]
                            )[1] === "physical"
                          ) {
                            return (
                              <Radio
                                value={item.training_id}
                                {...register("trainingId")}
                              >
                                {item.training_date[0].startDate !=
                                item.training_date[0].endDate
                                  ? format(
                                      new Date(item.training_date[0].startDate),
                                      "dd LLLL"
                                    ) +
                                    " - " +
                                    format(
                                      new Date(
                                        item.training_date[
                                          item.training_date.length - 1
                                        ].endDate
                                      ),
                                      "dd LLLL y"
                                    ) +
                                    ", " +
                                    item.training_name
                                  : format(
                                      new Date(item.training_date[0].startDate),
                                      "dd LLLL y"
                                    ) +
                                    ", " +
                                    item.training_name}
                              </Radio>
                            );
                          } else {
                          }
                        })
                      ) : (
                        <Text>adfs</Text>
                      ))}
                  </Stack>
                </Box>
                <Text ml="30px">Part time</Text>
                <Box my="10px" mx="50px">
                  <Stack direction="column">
                    {course.trainings &&
                      (course.trainings.length > 0 ? (
                        course.trainings.map((item) => {
                          if (
                            modeSplitter(
                              trainingModes[item.training_mode]
                            )[0] === "Parttime" &&
                            modeSplitter(
                              trainingModes[item.training_mode]
                            )[1] === "physical"
                          ) {
                            return (
                              <Radio
                                value={item.training_id}
                                {...register("trainingId")}
                              >
                                {item.training_date[0].startDate !=
                                item.training_date[0].endDate
                                  ? format(
                                      new Date(item.training_date[0].startDate),
                                      "dd LLLL"
                                    ) +
                                    " - " +
                                    format(
                                      new Date(
                                        item.training_date[
                                          item.training_date.length - 1
                                        ].endDate
                                      ),
                                      "dd LLLL y"
                                    ) +
                                    ", " +
                                    item.training_name
                                  : format(
                                      new Date(item.training_date[0].startDate),
                                      "dd LLLL y"
                                    ) +
                                    ", " +
                                    item.training_name}
                              </Radio>
                            );
                          } else {
                          }
                        })
                      ) : (
                        <Text>adfs</Text>
                      ))}
                  </Stack>
                </Box>
              </RadioGroup>
            </ModalBody>
            <Center>
              <ModalFooter>
                <Button
                  type="submit"
                  w="170px"
                  mr="10px"
                  fontSize="15px"
                  bg="#33945f"
                  color="white"
                  mt="20px"
                  _hover={{
                    backgroundColor: "white",
                    color: "#33945f",
                    border: "1px solid #33945f",
                  }}
                >
                  Select
                </Button>
                <Button
                  w="170px"
                  fontSize="15px"
                  bg="white"
                  color="#33945f"
                  mt="20px"
                  onClick={() => {
                    reset();
                    physicalModal.onClose();
                  }}
                >
                  Cancel
                </Button>
              </ModalFooter>
            </Center>
          </form>
        </ModalContent>
      </Modal>
      {/* -----------------------------------END PHYSICAL MODAL----------------------------------- */}
      {/* -----------------------------------CONFIRMATION MODAL-------------------------------------*/}
      <Modal isOpen={confirmModal.isOpen}>
        <ModalOverlay />
        <ModalContent textAlign="center">
          <ModalHeader>Confirmation</ModalHeader>
          <ModalBody>
            Your identification number has already exist in the record. Do you
            want to update your old address?
          </ModalBody>
          <Center mt="6" mb="3">
            <Button
              size="md"
              mr="3"
              bg="#33945f"
              color="white"
              w="100px"
              _hover={{ bg: "green.500" }}
              onClick={async () => {
                modalConfirm("true");
                confirmModal.onClose();
              }}
            >
              Yes
            </Button>
            <Button
              w="100px"
              onClick={async () => {
                modalConfirm("false");
                confirmModal.onClose();
              }}
            >
              No
            </Button>
          </Center>
        </ModalContent>
      </Modal>
      {/* -----------------------------------END CONFIRMATION MODAL-------------------------------------*/}
    </>
  );
}

export { Individu };
