import {
  Box,
  Button,
  Center,
  Container,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Text,
  useDisclosure,
  VStack,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Grid,
  GridItem,
  Divider,
  InputLeftElement,
  useToast,
} from "@chakra-ui/react";
import { FiLock } from "react-icons/fi";
import { RiAddFill, RiDeleteBinLine } from "react-icons/ri";
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import CRUDFunction from "../../../functions/CRUDFunction";
import { format } from "date-fns";
import { URL_LOCAL, URL_STAGING } from "../../../constant";

const Company = () => {
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...participant];
    list[index][name] = value;
    setParticipant(list);
  };

  const handleRemoveClick = (index) => {
    const list = [...participant];
    list.splice(index, 1);
    setParticipant(list);
  };

  const handleAddClick = () => {
    // setParticipant([
    //   ...participant,
    //   { name: "", ic: "", email: "", phoneNo: "" },
    // ]);
    addParticipantModal.onOpen();
  };

  const handleChange = (event) => {
    console.log(event.target.value);
  };

  const handleNewCompany = (event) => {
    setCompanySSM(event.target.value);
  };

  let navigate = useNavigate();

  const { state } = useLocation();
  const onlineModal = useDisclosure();
  const physicalModal = useDisclosure();
  const companyModal = useDisclosure();
  const addParticipantModal = useDisclosure();
  const [radioId, setRadioId] = useState();
  const [companySSM, setCompanySSM] = useState("");
  const [trainingDetails, setTrainingDetails] = useState([]);
  const [date, setDate] = useState([]);
  const [authenticated, setAuthenticated] = useState(false);
  const [companyLogin, setCompanyLogin] = useState({
    company_id: "",
    company_image: "",
    company_name: "",
    company_ssm: "",
    company_address: "",
    company_picname: "",
    company_picphone: "",
    company_picic: "",
    company_picemail: "",
    company_picposition: "",
  });
  const [selectCompany, setSelectCompany] = useState([]);
  const [course, setCourse] = useState([
    {
      course_about: "",
      course_eligibility: "",
      course_id: "",
      course_image: "",
      course_name: "",
      course_outline: "",
      trainings: [],
    },
  ]);
  const [company, setCompany] = useState();
  const trainingModes = [
    "Fulltime online inhouse",
    "Fulltime physical inhouse",
    "Parttime online inhouse",
    "Parttime physical inhouse",
    "Fulltime online public",
    "Fulltime physical public",
    "Parttime online public",
    "Parttime physical public",
  ];
  const displayModes = [
    "Full Time Online Inhouse",
    "Full Time Physical Inhouse",
    "Part Time Online Inhouse",
    "Part Time Physical Inhouse",
    "Full Time Online",
    "Full Time Physical",
    "Part Time Online",
    "Part Time Physical",
  ];
  const [participant, setParticipant] = useState([
    {
      trainee_name: "",
      trainee_ic: "",
      trainee_email: "",
      trainee_phone: "",
      trainee_position: "",
    },
  ]);

  const toast = useToast();

  useEffect(() => {
    fetchCourse();
    fetchCompany();
  }, []);

  const fetchCourse = async () => {
    const res = await CRUDFunction.get(
      `${URL_STAGING}coursedetails/${state.id}`
    );
    if (res) {
      let trainings = [];
      if (res[0].training_id != null) {
        res.map(
          (i) =>
            new Date(JSON.parse(i.training_date)[0].startDate).getTime() >
              new Date().getTime() &&
            trainings.push({
              training_id: i.training_id,
              training_name: i.training_name,
              training_date: JSON.parse(i.training_date),
              training_detail: i.training_detail,
              training_fee: i.training_fee,
              training_place: i.training_place,
              training_mode: i.training_mode,
            })
        );
      }
      setCourse({
        course_about: res[0].course_about,
        course_eligibility: res[0].course_eligibility,
        course_id: res[0].course_id,
        course_image: res[0].course_image,
        course_name: res[0].course_name.toUpperCase(),
        course_outline: res[0].course_outline,
        trainings: trainings,
      });
    }
  };

  const fetchCompany = async () => {
    const res = await CRUDFunction.get(`${URL_STAGING}companyClient`);
    if (res) {
      setCompany(res);
    }
  };

  const modeSplitter = (mode) => {
    return mode.split(" ");
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm();

  const modalSessionSubmit = async (data) => {
    const res = await CRUDFunction.get(
      `${URL_STAGING}training/${data.trainingId}`
    );
    setTrainingDetails(res);
    setDate(JSON.parse(res.training_date));
    onlineModal.isOpen ? onlineModal.onClose() : physicalModal.onClose();
  };

  const verifyCompany = async (data) => {
    if (data.company !== "") {
      const formData = new FormData();
      formData.append("company", data.company);
      const res = await CRUDFunction.create(
        `${URL_STAGING}verifycompany`,
        formData
      );

      if (res) {
        setCompanyLogin(res);
        setAuthenticated(true);
      } else {
        toast({
          title: "Account not exist.",
          description:
            "We can't find your account. Please re-enter your company ssm / phone no.",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      }
    } else {
      toast({
        title: "No input.",
        description: "Please enter your SSM number or PIC telephone number",
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const companyModalSubmit = async (data) => {
    if (data) {
      const formData = new FormData();
      formData.append("company_name", data.companyFormName);
      formData.append("company_ssm", companySSM);
      let combineAddress = [
        {
          address: data.companyFormAddress,
          postcode: data.companyFormPostcode,
          state: data.companyFormState,
        },
      ];
      formData.append("company_address", JSON.stringify(combineAddress));
      formData.append("company_picname", data.companyFormPicName);
      formData.append("company_picphone", data.companyFormPicPhone);
      formData.append("company_picic", data.companyFormPicIC);
      formData.append("company_picemail", data.companyFormPicEmail);
      formData.append("company_picposition", data.companyFormPicPosition);
      if (data.companyFormImage.length !== 0) {
        formData.append("company_image", data.companyFormImage[0]);
      }
      // const res = await CRUDFunction.create(`${URL_STAGING}company`, formData);
      if (true) {
        setCompanyLogin({
          company_name: data.companyFormName,
          company_ssm: companySSM,
          company_address: JSON.stringify(combineAddress),
          company_picname: data.companyFormPicName,
          company_picphone: data.companyFormPicPhone,
          company_picic: data.companyFormPicIC,
          company_picemail: data.companyFormPicEmail,
          company_picposition: data.companyFormPicPosition,
          company_image: data.companyFormImage[0],
        });
        setAuthenticated(true);
        reset();
        companyModal.onClose();
      }
    }
  };

  const modalAddParticipantSubmit = async (data) => {
    if (data) {
      setParticipant([
        ...participant,
        {
          trainee_name: data.name,
          trainee_ic: data.ic,
          trainee_email: data.email,
          trainee_phone: data.phoneNo,
          trainee_position: data.position,
        },
      ]);
      reset();
      addParticipantModal.onClose();
    }
  };

  const companyRegisterTraining = async (data) => {
    if (data) {
      const formData = new FormData();
      if (participant.length > 0) {
        let traineeName = [],
          traineeIC = [],
          traineePhone = [],
          traineeEmail = [],
          traineePosition = [],
          traineeAddress = [],
          traineePayment = [],
          traineeHrdf = [];
        for (let i = 0; i < participant.length; i++) {
          traineeName.push(participant[i].name);
          traineeIC.push(participant[i].ic);
          traineePhone.push(participant[i].phoneNo);
          traineeEmail.push(participant[i].email);
          traineePosition.push(participant[i].position);
          traineeAddress.push(companyLogin.company_address);
          traineePayment.push("0");
          traineeHrdf.push("0");
        }
        formData.append("training_id", trainingDetails.training_id);
        formData.append("company_id", companyLogin.company_id);
        formData.append("program_id", trainingDetails.program_id);
        formData.append("trainee_name", JSON.stringify(traineeName));
        formData.append("trainee_ic", JSON.stringify(traineeIC));
        formData.append("trainee_email", JSON.stringify(traineeEmail));
        formData.append("trainee_phone", JSON.stringify(traineePhone));
        formData.append("trainee_position", JSON.stringify(traineePosition));
        formData.append("trainee_address", JSON.stringify(traineeAddress));
        formData.append("trainee_payment", JSON.stringify(traineePayment));
        formData.append("trainee_hrdf", JSON.stringify(traineeHrdf));
      }
      // const res = await CRUDFunction.create(
      //   `${URL_STAGING}traineeCompany/${trainingDetails.training_id}`,
      //   formData
      // );
      if (true) {
        reset();
        setDate([]);
        // setSelectCompany([]);
        // navigate("../checkout", {
        //   replace: false,
        //   state: {
        //     training_id: data.trainingId,
        //     course_id: state.id,
        //     total_trainee: participant.length,
        //   },
        // });
        navigate("../checkout", {
          replace: false,
          state: {
            company: companyLogin,
            participants: participant,
            training: trainingDetails,
            course_id: state.id,
            total_trainee: participant.length - 1,
            training_id: data.trainingId,
            login: "company",
          },
        });
      }
    }
  };

  return (
    <>
      <Grid mx="100px" templateColumns="repeat(6, 1fr)" gap={3}>
        <GridItem colSpan={4}>
          <Container maxW={"auto"} maxH={"auto"} height="100%">
            <Box
              p="10px 20px 10px 20px"
              borderRadius="10px"
              boxShadow="xl"
              height="100%"
              // p="30px 70px 20px 70px"
            >
              {!authenticated && (
                <>
                  <br />
                  <Heading size="3xl" align="center">
                    Registration
                  </Heading>
                  <br />
                  <Heading size="md" align="center">
                    {course.course_name}
                  </Heading>
                  <br />
                  <Container>
                    <Tabs>
                      <TabList>
                        <Tab width={"50%"}>Existing</Tab>
                        <Tab width={"50%"}>New</Tab>
                      </TabList>
                      <TabPanels
                        style={{
                          background: "white",
                          boxShadow: "0px 46px 130px rgba(0, 0, 0, 0.15)",
                          borderRadius: "8px",
                          marginTop: "20px",
                          marginBottom: "20px",
                        }}
                      >
                        <TabPanel>
                          <form onSubmit={handleSubmit(verifyCompany)}>
                            <Text fontSize="md" textAlign={"center"} mb="8px">
                              <b>Existing Company</b>
                            </Text>
                            <br />
                            <Text mb="8px">SSM NUMBER / TELEPHONE NUMBER</Text>
                            <InputGroup>
                              <InputLeftElement>
                                <InputLeftElement
                                  pointerEvents="none"
                                  children={<FiLock color="gray.300" />}
                                />
                              </InputLeftElement>
                              <Input
                                style={{
                                  background: "#FCFDFE",
                                  border: "1px solid #F0F1F7",
                                  borderRadius: "8px",
                                  height: "40px",
                                }}
                                {...register("company")}
                                placeholder="ABCD-Y or 012-3456789"
                                size="sm"
                              />
                            </InputGroup>
                            <br />
                            <br />
                            <Button
                              background="#33945F"
                              color="white"
                              width="100%"
                              type="submit"
                            >
                              Login
                            </Button>
                          </form>
                        </TabPanel>
                        <TabPanel>
                          <Text fontSize="md" textAlign={"center"} mb="8px">
                            <b>Create a new company</b>
                          </Text>
                          <br />
                          <Text mb="8px">SSM NUMBER</Text>
                          <InputGroup>
                            <InputLeftElement>
                              <InputLeftElement
                                pointerEvents="none"
                                children={<FiLock color="gray.300" />}
                              />
                            </InputLeftElement>
                            <Input
                              style={{
                                background: "#FCFDFE",
                                border: "1px solid #F0F1F7",
                                borderRadius: "8px",
                                height: "40px",
                              }}
                              onChange={handleNewCompany}
                              placeholder="ABCD-Y"
                              size="sm"
                            />
                          </InputGroup>
                          <br />
                          <br />
                          <Button
                            background="#33945F"
                            color="white"
                            width="100%"
                            onClick={async () => {
                              const res = await CRUDFunction.create(
                                `${URL_STAGING}verifycompany`,
                                { company: companySSM }
                              );
                              if (!res) {
                                companyModal.onOpen();
                              } else {
                                toast({
                                  title: "Account already exist.",
                                  description:
                                    "Please re-enter your company ssm / phone no.",
                                  status: "error",
                                  duration: 9000,
                                  isClosable: true,
                                });
                              }
                            }}
                          >
                            Create New Company
                          </Button>
                        </TabPanel>
                      </TabPanels>
                    </Tabs>
                  </Container>
                </>
              )}
              {authenticated && (
                <div style={{ padding: "20px 70px", height: "100%" }}>
                  <form
                    onSubmit={handleSubmit(companyRegisterTraining)}
                    style={{ height: "100%" }}
                  >
                    <div style={{ height: "90%", position: "relative" }}>
                      <FormControl isRequired>
                        <FormLabel fontSize="13px" mt="10px" fontWeight="bold">
                          TRAINING SESSION
                        </FormLabel>
                        <InputGroup size="md" mb="8px">
                          <Input
                            fontSize="14px"
                            disabled
                            value={trainingDetails.training_name}
                            placeholder="Select session >"
                            style={{ opacity: "1", cursor: "default" }}
                          />
                          <InputRightElement width="auto">
                            <Menu>
                              <MenuButton
                                as={Button}
                                w="260px"
                                borderLeftRadius="0px"
                                borderRightRadius="10px"
                                bgColor="white"
                                borderWidth="2px"
                                borderColor="black"
                                letterSpacing={1}
                                fontSize="14px"
                              >
                                Select training session
                              </MenuButton>
                              <MenuList>
                                <MenuItem
                                  pl="30px"
                                  fontSize="15px"
                                  onClick={() => {
                                    onlineModal.onOpen();
                                  }}
                                >
                                  Online
                                </MenuItem>
                                <MenuItem
                                  pl="30px"
                                  fontSize="15px"
                                  onClick={() => {
                                    physicalModal.onOpen();
                                  }}
                                >
                                  Physical
                                </MenuItem>
                              </MenuList>
                            </Menu>
                          </InputRightElement>
                        </InputGroup>
                        <HStack mt="10px">
                          <Text fontSize="14px">Company's Name:</Text>
                          <Text fontSize="14px">
                            {companyLogin.company_name}
                          </Text>
                        </HStack>
                        <HStack mt="10px">
                          <Text fontSize="14px">Person in Charge (PIC):</Text>
                          <Text fontSize="14px">
                            {companyLogin.company_picname}
                          </Text>
                        </HStack>
                      </FormControl>
                      <br />
                      <FormControl>
                        <HStack>
                          <FormLabel
                            fontSize="13px"
                            mt="10px"
                            fontWeight="bold"
                          >
                            PARTICIPANT
                          </FormLabel>
                          <IconButton
                            size="xs"
                            icon={<RiAddFill />}
                            onClick={handleAddClick}
                          />
                          <Text fontStyle="italic" fontSize="15px">
                            No of participant: {participant.length - 1}
                          </Text>
                        </HStack>
                        <Box
                          mb="20px"
                          overflowY="scroll"
                          height={trainingDetails ? "35em" : "30em"}
                        >
                          <VStack align="left">
                            <VStack align="left">
                              {participant.map((x, i) => {
                                if (i > 0) {
                                  return (
                                    <>
                                      <HStack
                                        width="100%"
                                        borderBottom={"1px solid gray"}
                                        paddingY="10px"
                                        _hover={{
                                          backgroundColor: "gray.100",
                                          color: "black",
                                        }}
                                      >
                                        <Text
                                          fontStyle="italic"
                                          fontSize="15px"
                                          mb="auto"
                                        >
                                          {i}
                                        </Text>
                                        <VStack width="50%" align={"left"}>
                                          <Text>
                                            Name: <b>{x.trainee_name}</b>
                                          </Text>
                                          <Text>
                                            IC: <b>{x.trainee_ic}</b>
                                          </Text>
                                          <Text>
                                            Email: <b>{x.trainee_email}</b>
                                          </Text>
                                        </VStack>
                                        <VStack width="50%" align={"left"}>
                                          <Text>
                                            Phone No.: <b>{x.trainee_phone}</b>
                                          </Text>
                                          <Text>
                                            Position:{" "}
                                            <b>{x.trainee_position}</b>
                                          </Text>
                                        </VStack>
                                        <div>
                                          <HStack>
                                            {participant.length !== 0 && (
                                              <IconButton
                                                icon={<RiDeleteBinLine />}
                                                variant="ghost"
                                                color="red"
                                                onClick={() =>
                                                  handleRemoveClick(i)
                                                }
                                              />
                                            )}
                                          </HStack>
                                        </div>
                                      </HStack>
                                    </>
                                  );
                                }
                              })}
                            </VStack>
                          </VStack>
                        </Box>
                      </FormControl>
                    </div>
                    <div style={{ height: "10%", position: "relative" }}>
                      <div
                        style={{
                          position: "absolute",
                          width: "100%",
                        }}
                      >
                        <Button
                          type="submit"
                          w="49%"
                          p="7px"
                          fontWeight="bold"
                          color="white"
                          borderRadius="10px"
                          bgColor="#33945f"
                          _hover={{
                            backgroundColor: "white",
                            color: "#33945f",
                            border: "1px solid #33945f",
                          }}
                        >
                          Next
                        </Button>
                        <Button
                          marginLeft="5px"
                          type="submit"
                          w="49%"
                          p="7px"
                          fontWeight="bold"
                          borderRadius="10px"
                          color="#33945f"
                          border={"1px solid #33945f"}
                          bgColor="white"
                          _hover={{
                            backgroundColor: "#33945f",
                            color: "white",
                            border: "1px solid white",
                          }}
                          onClick={() => {
                            setTrainingDetails([]);
                            setDate([]);
                            setAuthenticated(false);
                          }}
                        >
                          Cancel
                        </Button>
                      </div>
                    </div>
                  </form>
                </div>
              )}
            </Box>
          </Container>
        </GridItem>
        <GridItem colSpan={2}>
          <Container maxW={"auto"} maxH={"auto"}>
            <Box
              display="grid"
              p="10px 20px 10px 20px"
              borderRadius="10px"
              boxShadow="xl"
            >
              <br />
              <Heading size="lg" align="center">
                Training Details
              </Heading>
              <br />

              <Divider
                orientation="horizontal"
                border="5px solid"
                borderColor="#33945f"
                mb="5"
              />
              <div style={{ padding: "1px 3px" }}>
                <Box borderBottomWidth="2px" borderColor="gray" mb="10">
                  <b>COURSE</b>
                  <Text fontSize="14px" py="10px">
                    {course.course_name}
                  </Text>
                </Box>
                <Box borderBottomWidth="2px" borderColor="gray" mb="10">
                  <b>TRAINING NAME</b>
                  <Text fontSize="14px" py="10px">
                    {trainingDetails.training_name}
                  </Text>
                </Box>
                <Box
                  borderBottomWidth="2px"
                  borderColor="gray"
                  mb="10"
                  pb="10px"
                >
                  <b>TRAINING DATE</b>
                  {trainingDetails &&
                    date.map((item, index) => (
                      <>
                        {item.endDate === item.startDate ? (
                          <Text fontSize="14px">
                            {format(new Date(item.startDate), "dd LLLL y")}
                          </Text>
                        ) : (
                          <Text fontSize="14px">
                            Week {index + 1}:{" "}
                            {format(new Date(item.startDate), "dd LLLL y")} -{" "}
                            {format(new Date(item.endDate), "dd LLLL y")}
                          </Text>
                        )}
                      </>
                    ))}
                </Box>
                <Box borderBottomWidth="2px" borderColor="gray" mb="10">
                  <b>TRAINING TIME</b>
                  <Text fontSize="14px" py="10px">
                    {trainingDetails.training_time && (
                      <Text>
                        {Number(
                          JSON.parse(trainingDetails.training_time)[0][0] * 10
                        ) +
                          Number(
                            JSON.parse(trainingDetails.training_time)[0][1]
                          ) <
                        11 ? (
                          <>
                            {Number(
                              JSON.parse(trainingDetails.training_time)[0][0] *
                                10
                            ) +
                              Number(
                                JSON.parse(trainingDetails.training_time)[0][1]
                              )}
                            {JSON.parse(trainingDetails.training_time)[0].slice(
                              2
                            )}{" "}
                            AM
                          </>
                        ) : Number(
                            JSON.parse(trainingDetails.training_time)[0][0] * 10
                          ) +
                            Number(
                              JSON.parse(trainingDetails.training_time)[0][1]
                            ) >
                          12 ? (
                          <>
                            {Number(
                              JSON.parse(trainingDetails.training_time)[0][0] *
                                10
                            ) +
                              Number(
                                JSON.parse(trainingDetails.training_time)[0][1]
                              ) -
                              12}
                            {JSON.parse(trainingDetails.training_time)[0].slice(
                              2
                            )}{" "}
                            PM
                          </>
                        ) : (
                          <>
                            {Number(
                              JSON.parse(trainingDetails.training_time)[0][0] *
                                10
                            ) +
                              Number(
                                JSON.parse(trainingDetails.training_time)[0][1]
                              )}
                            {JSON.parse(trainingDetails.training_time)[0].slice(
                              2
                            )}{" "}
                            PM
                          </>
                        )}{" "}
                        -{" "}
                        {Number(
                          JSON.parse(trainingDetails.training_time)[1][0] * 10
                        ) +
                          Number(
                            JSON.parse(trainingDetails.training_time)[1][1]
                          ) <
                        11 ? (
                          <>
                            {Number(
                              JSON.parse(trainingDetails.training_time)[1][0] *
                                10
                            ) +
                              Number(
                                JSON.parse(trainingDetails.training_time)[1][1]
                              )}
                            {JSON.parse(trainingDetails.training_time)[1].slice(
                              2
                            )}{" "}
                            AM
                          </>
                        ) : Number(
                            JSON.parse(trainingDetails.training_time)[1][0] * 10
                          ) +
                            Number(
                              JSON.parse(trainingDetails.training_time)[1][1]
                            ) >
                          12 ? (
                          <>
                            {Number(
                              JSON.parse(trainingDetails.training_time)[1][0] *
                                10
                            ) +
                              Number(
                                JSON.parse(trainingDetails.training_time)[1][1]
                              ) -
                              12}
                            {JSON.parse(trainingDetails.training_time)[1].slice(
                              2
                            )}{" "}
                            PM
                          </>
                        ) : (
                          <>
                            {Number(
                              JSON.parse(trainingDetails.training_time)[1][0] *
                                10
                            ) +
                              Number(
                                JSON.parse(trainingDetails.training_time)[1][1]
                              )}
                            {JSON.parse(trainingDetails.training_time)[1].slice(
                              2
                            )}{" "}
                            PM
                          </>
                        )}
                      </Text>
                    )}
                  </Text>
                </Box>
                <Box borderBottomWidth="2px" borderColor="gray" mb="10">
                  <b>TRAINING PLACE</b>
                  <Text fontSize="14px" py="10px">
                    {trainingDetails.training_place}
                  </Text>
                </Box>
                <Box borderBottomWidth="2px" borderColor="gray" mb="10">
                  <b>TRAINING MODE</b>
                  <Text fontSize="14px" py="10px">
                    {displayModes[trainingDetails.training_mode]}
                  </Text>
                </Box>
                <Box borderBottomWidth="2px" borderColor="gray" mb="10">
                  <b>TRAINING DETAILS</b>
                  <Text fontSize="14px" py="10px">
                    {trainingDetails.training_detail}
                  </Text>
                </Box>
                <Box borderBottomWidth="2px" borderColor="gray" mb="10">
                  <b>TRAINING FEE</b>
                  <Text fontSize="14px" py="10px">
                    RM {trainingDetails.training_fee}
                  </Text>
                </Box>
              </div>
            </Box>
          </Container>
        </GridItem>
      </Grid>

      {/* -----------------------------------ONLINE MODAL----------------------------------- */}
      <Modal
        size="2xl"
        isOpen={onlineModal.isOpen}
        onClose={() => {
          reset();
          onlineModal.onClose();
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <form onSubmit={handleSubmit(modalSessionSubmit)}>
            <Box my="20px">
              <ModalHeader textAlign="center">
                Select training session
              </ModalHeader>
            </Box>
            <ModalBody>
              <RadioGroup
                value={radioId}
                onChange={(event) => {
                  const choiceId = Number(event);
                  setRadioId(choiceId);
                }}
              >
                <Text ml="30px">Full time</Text>
                <Box my="10px" mx="50px">
                  <Stack direction="column">
                    {course.trainings &&
                      (course.trainings.length > 0 ? (
                        course.trainings.map((item) => {
                          if (
                            modeSplitter(
                              trainingModes[item.training_mode]
                            )[0] === "Fulltime" &&
                            modeSplitter(
                              trainingModes[item.training_mode]
                            )[1] === "online"
                          ) {
                            return (
                              <Radio
                                value={item.training_id}
                                {...register("trainingId")}
                              >
                                {item.training_date[0].startDate !=
                                item.training_date[0].endDate
                                  ? format(
                                      new Date(item.training_date[0].startDate),
                                      "dd LLLL"
                                    ) +
                                    " - " +
                                    format(
                                      new Date(
                                        item.training_date[
                                          item.training_date.length - 1
                                        ].endDate
                                      ),
                                      "dd LLLL y"
                                    ) +
                                    ", " +
                                    item.training_name
                                  : format(
                                      new Date(item.training_date[0].startDate),
                                      "dd LLLL y"
                                    ) +
                                    ", " +
                                    item.training_name}
                              </Radio>
                            );
                          } else {
                          }
                        })
                      ) : (
                        <Text>adfs</Text>
                      ))}
                  </Stack>
                </Box>
                <Text ml="30px">Part time</Text>
                <Box my="10px" mx="50px">
                  <Stack direction="column">
                    {course.trainings &&
                      (course.trainings.length > 0 ? (
                        course.trainings.map((item) => {
                          if (
                            modeSplitter(
                              trainingModes[item.training_mode]
                            )[0] === "Parttime" &&
                            modeSplitter(
                              trainingModes[item.training_mode]
                            )[1] === "online"
                          ) {
                            return (
                              <Radio
                                value={item.training_id}
                                {...register("trainingId")}
                              >
                                {item.training_date[0].startDate !=
                                item.training_date[0].endDate
                                  ? format(
                                      new Date(item.training_date[0].startDate),
                                      "dd LLLL"
                                    ) +
                                    " - " +
                                    format(
                                      new Date(
                                        item.training_date[
                                          item.training_date.length - 1
                                        ].endDate
                                      ),
                                      "dd LLLL y"
                                    ) +
                                    ", " +
                                    item.training_name
                                  : format(
                                      new Date(item.training_date[0].startDate),
                                      "dd LLLL y"
                                    ) +
                                    ", " +
                                    item.training_name}
                              </Radio>
                            );
                          } else {
                          }
                        })
                      ) : (
                        <Text>adfs</Text>
                      ))}
                  </Stack>
                </Box>
              </RadioGroup>
            </ModalBody>
            <Center>
              <ModalFooter>
                <Button
                  type="submit"
                  w="170px"
                  mr="10px"
                  fontSize="15px"
                  bg="#33945f"
                  color="white"
                  mt="20px"
                  _hover={{
                    backgroundColor: "white",
                    color: "#33945f",
                    border: "1px solid #33945f",
                  }}
                >
                  Select
                </Button>
                <Button
                  w="170px"
                  fontSize="15px"
                  bg="white"
                  color="#33945f"
                  mt="20px"
                  onClick={() => {
                    reset();
                    onlineModal.onClose();
                  }}
                >
                  Cancel
                </Button>
              </ModalFooter>
            </Center>
          </form>
        </ModalContent>
      </Modal>
      {/* -----------------------------------END ONLINE MODAL----------------------------------- */}

      {/* -----------------------------------PHYSICAL MODAL----------------------------------- */}
      <Modal
        size="2xl"
        isOpen={physicalModal.isOpen}
        onClose={() => {
          reset();
          physicalModal.onClose();
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <form onSubmit={handleSubmit(modalSessionSubmit)}>
            <Box my="20px">
              <ModalHeader textAlign="center">
                Select training session
              </ModalHeader>
            </Box>
            <ModalBody>
              <RadioGroup
                value={radioId}
                onChange={(event) => {
                  const choiceId = Number(event);
                  setRadioId(choiceId);
                }}
              >
                <Text ml="30px">Full time</Text>
                <Box my="10px" mx="50px">
                  <Stack direction="column">
                    {course.trainings &&
                      (course.trainings.length > 0 ? (
                        course.trainings.map((item) => {
                          if (
                            modeSplitter(
                              trainingModes[item.training_mode]
                            )[0] === "Fulltime" &&
                            modeSplitter(
                              trainingModes[item.training_mode]
                            )[1] === "physical"
                          ) {
                            return (
                              <Radio
                                value={item.training_id}
                                {...register("trainingId")}
                              >
                                {item.training_date[0].startDate !=
                                item.training_date[0].endDate
                                  ? format(
                                      new Date(item.training_date[0].startDate),
                                      "dd LLLL"
                                    ) +
                                    " - " +
                                    format(
                                      new Date(
                                        item.training_date[
                                          item.training_date.length - 1
                                        ].endDate
                                      ),
                                      "dd LLLL y"
                                    ) +
                                    ", " +
                                    item.training_name
                                  : format(
                                      new Date(item.training_date[0].startDate),
                                      "dd LLLL y"
                                    ) +
                                    ", " +
                                    item.training_name}
                              </Radio>
                            );
                          } else {
                          }
                        })
                      ) : (
                        <Text>adfs</Text>
                      ))}
                  </Stack>
                </Box>
                <Text ml="30px">Part time</Text>
                <Box my="10px" mx="50px">
                  <Stack direction="column">
                    {course.trainings &&
                      (course.trainings.length > 0 ? (
                        course.trainings.map((item) => {
                          if (
                            modeSplitter(
                              trainingModes[item.training_mode]
                            )[0] === "Parttime" &&
                            modeSplitter(
                              trainingModes[item.training_mode]
                            )[1] === "physical"
                          ) {
                            return (
                              <Radio
                                value={item.training_id}
                                {...register("trainingId")}
                              >
                                {item.training_date[0].startDate !=
                                item.training_date[0].endDate
                                  ? format(
                                      new Date(item.training_date[0].startDate),
                                      "dd LLLL"
                                    ) +
                                    " - " +
                                    format(
                                      new Date(
                                        item.training_date[
                                          item.training_date.length - 1
                                        ].endDate
                                      ),
                                      "dd LLLL y"
                                    ) +
                                    ", " +
                                    item.training_name
                                  : format(
                                      new Date(item.training_date[0].startDate),
                                      "dd LLLL y"
                                    ) +
                                    ", " +
                                    item.training_name}
                              </Radio>
                            );
                          } else {
                          }
                        })
                      ) : (
                        <Text>adfs</Text>
                      ))}
                  </Stack>
                </Box>
              </RadioGroup>
            </ModalBody>
            <Center>
              <ModalFooter>
                <Button
                  type="submit"
                  w="170px"
                  mr="10px"
                  fontSize="15px"
                  bg="#33945f"
                  color="white"
                  mt="20px"
                  _hover={{
                    backgroundColor: "white",
                    color: "#33945f",
                    border: "1px solid #33945f",
                  }}
                >
                  Select
                </Button>
                <Button
                  w="170px"
                  fontSize="15px"
                  bg="white"
                  color="#33945f"
                  mt="20px"
                  onClick={() => {
                    reset();
                    physicalModal.onClose();
                  }}
                >
                  Cancel
                </Button>
              </ModalFooter>
            </Center>
          </form>
        </ModalContent>
      </Modal>
      {/* -----------------------------------END PHYSICAL MODAL----------------------------------- */}

      {/* -----------------------------------ADD COMPANY MODAL----------------------------------- */}
      <Modal
        size="5xl"
        isOpen={companyModal.isOpen}
        onClose={() => {
          reset();
          companyModal.onClose();
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <Box my="20px">
            <ModalHeader textAlign="center">Add Company</ModalHeader>
          </Box>
          <form onSubmit={handleSubmit(companyModalSubmit)}>
            <ModalBody>
              <Box m="10px">
                <Heading size="md">Company</Heading>
                <Stack direction="row" mt="10px">
                  <FormControl isRequired>
                    <FormLabel fontSize="13px" mt="10px" fontWeight="bold">
                      COMPANY NAME:
                    </FormLabel>
                    <Input
                      fontSize="14px"
                      placeholder="Company Name"
                      {...register("companyFormName")}
                    ></Input>
                  </FormControl>
                  <FormControl>
                    <FormLabel fontSize="13px" mt="10px" fontWeight="bold">
                      COMPANY PICTURE
                    </FormLabel>
                    <Input
                      accept="image/*"
                      type="file"
                      aria-hidden="true"
                      {...register("companyFormImage")}
                      // onChange={imageChange}
                    />
                  </FormControl>
                </Stack>

                <FormControl mt="10px" isRequired>
                  <FormLabel fontSize="13px" mt="10px" fontWeight="bold">
                    COMPANY ADDRESS
                  </FormLabel>
                  <Input
                    fontSize="14px"
                    placeholder="Company Address"
                    {...register("companyFormAddress")}
                  ></Input>
                </FormControl>
                <HStack>
                  <Box w="30%">
                    <FormControl mt="10px" isRequired>
                      <FormLabel fontSize="13px" mt="10px" fontWeight="bold">
                        POSTCODE
                      </FormLabel>
                      <Input
                        type="number"
                        fontSize="14px"
                        placeholder="Postcode"
                        {...register("companyFormPostcode")}
                      ></Input>
                    </FormControl>
                  </Box>
                  <Box w="70%">
                    <FormControl mt="10px" isRequired>
                      <FormLabel fontSize="13px" mt="10px" fontWeight="bold">
                        STATE
                      </FormLabel>
                      <Input
                        fontSize="14px"
                        placeholder="State"
                        {...register("companyFormState")}
                      ></Input>
                    </FormControl>
                  </Box>
                </HStack>
              </Box>

              <Box m="10px" pt="10px">
                <Heading size="md">Person In Charge</Heading>
                <FormControl mt="10px" isRequired>
                  <FormLabel fontSize="13px" mt="10px" fontWeight="bold">
                    NAME
                  </FormLabel>
                  <Input
                    fontSize="14px"
                    placeholder="Name"
                    {...register("companyFormPicName")}
                  ></Input>
                </FormControl>

                <FormControl mt="10px" isRequired>
                  <FormLabel fontSize="13px" mt="10px" fontWeight="bold">
                    IDENTIFICATION NUMBER
                  </FormLabel>
                  <Input
                    type="number"
                    fontSize="14px"
                    placeholder="Identification Number"
                    {...register("companyFormPicIC")}
                  ></Input>
                </FormControl>

                <FormControl mt="10px" isRequired>
                  <FormLabel fontSize="13px" mt="10px" fontWeight="bold">
                    PHONE NUMBER
                  </FormLabel>
                  <Input
                    type="number"
                    fontSize="14px"
                    placeholder="Phone Number"
                    {...register("companyFormPicPhone")}
                  ></Input>
                </FormControl>

                <FormControl mt="10px" isRequired>
                  <FormLabel fontSize="13px" mt="10px" fontWeight="bold">
                    EMAIL
                  </FormLabel>
                  <Input
                    type="email"
                    fontSize="14px"
                    placeholder="Email"
                    {...register("companyFormPicEmail")}
                  ></Input>
                </FormControl>

                <FormControl mt="10px" isRequired>
                  <FormLabel fontSize="13px" mt="10px" fontWeight="bold">
                    POSITION
                  </FormLabel>
                  <Input
                    fontSize="14px"
                    placeholder="Position"
                    {...register("companyFormPicPosition")}
                  ></Input>
                </FormControl>
              </Box>
            </ModalBody>
            <ModalFooter>
              <Center w="100%">
                <Button
                  type="submit"
                  minW="30%"
                  bgColor="#33945f"
                  color="white"
                  _hover={{ bg: "green.700" }}
                  fontWeight="medium"
                >
                  Add
                </Button>
                <Button
                  minW="30%"
                  type="reset"
                  mx="10px"
                  fontWeight="medium"
                  onClick={() => {
                    reset();
                    companyModal.onClose();
                  }}
                >
                  Cancel
                </Button>
              </Center>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
      {/* ----------------------------------END ADD COMPANY MODAL----------------------------------- */}
      {/* ----------------------------------ADD PARTICIPANT MODAL ---------------------------------- */}
      <Modal
        size="5xl"
        isOpen={addParticipantModal.isOpen}
        onClose={() => {
          reset();
          addParticipantModal.onClose();
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <form onSubmit={handleSubmit(modalAddParticipantSubmit)}>
            <Box my="20px">
              <ModalHeader textAlign="center">ADD PARTICIPANT</ModalHeader>
            </Box>
            <ModalBody>
              <>
                <FormControl mt="10px" isRequired>
                  <FormLabel fontSize="13px" mt="10px" fontWeight="bold">
                    NAME
                  </FormLabel>
                  <Input
                    name="name"
                    {...register("name")}
                    placeholder="Enter name"
                  ></Input>
                </FormControl>
                <FormControl mt="10px" isRequired>
                  <FormLabel fontSize="13px" mt="10px" fontWeight="bold">
                    IDENTIFICATION NUMBER / PASSPORT NO.
                  </FormLabel>
                  <Input
                    name="ic"
                    type="number"
                    {...register("ic")}
                    placeholder="Enter ic (no dash)"
                  ></Input>
                </FormControl>
                <FormControl>
                  <FormLabel fontSize="13px" mt="10px" fontWeight="bold">
                    EMAIL
                  </FormLabel>
                  <Input
                    name="email"
                    type="email"
                    {...register("email")}
                    placeholder="Enter email"
                  ></Input>
                </FormControl>
                <FormControl>
                  <FormLabel fontSize="13px" mt="10px" fontWeight="bold">
                    PHONE NUMBER
                  </FormLabel>
                  <Input
                    name="phoneNo"
                    type="number"
                    {...register("phoneNo")}
                    placeholder="Enter phone number"
                  ></Input>
                </FormControl>
                <FormControl>
                  <FormLabel fontSize="13px" mt="10px" fontWeight="bold">
                    POSITION
                  </FormLabel>
                  <Input
                    name="position"
                    {...register("position")}
                    placeholder="Enter position"
                  ></Input>
                </FormControl>
              </>
            </ModalBody>

            <ModalFooter>
              <Stack direction={"row"} width="100%">
                <Box minW="50%">
                  <Button
                    w="100%"
                    type="submit"
                    fontSize="15px"
                    bg="#33945f"
                    color="white"
                    _hover={{
                      backgroundColor: "white",
                      color: "#33945f",
                      border: "1px solid #33945f",
                    }}
                  >
                    Add
                  </Button>
                </Box>
                <Box minW="50%">
                  <Button
                    w="100%"
                    fontSize="15px"
                    bg="white"
                    color="#33945f"
                    border="1px solid #33945f"
                    onClick={() => {
                      reset();
                      addParticipantModal.onClose();
                    }}
                  >
                    Cancel
                  </Button>
                </Box>
              </Stack>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
      {/* ----------------------------------END PARTICIPANT MODAL ---------------------------------- */}
    </>
  );
};

export default Company;
