import React, { useEffect, useState } from "react";
import {
  Input,
  Container,
  Button,
  Text,
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Heading,
  Center,
  FormControl,
  FormLabel,
  useDisclosure,
} from "@chakra-ui/react";
import { AddIcon, EditIcon, DeleteIcon } from "@chakra-ui/icons";
import { BiDotsVerticalRounded } from "react-icons/bi";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import CRUDFunction from "../../../functions/CRUDFunction";
import {
  ReactTable,
  ChakraAlertDialog,
  AlertPop,
  ErrorsDialog,
} from "../../../components";
import { URL_LOCAL, URL_STAGING } from "../../../constant";

const Styles = styled.div`
  padding-y: 1rem;

  table {
    min-width: 100%;
    border-spacing: 0;
    text-align: center;

    tr {
    }

    th {
      font-weight: bold;
      margin: 0;
      padding: 0.5rem;
      background-color: white;
      border-bottom: 1px solid #bdbdbd;
      // border-right: 1px solid black;
      // :nth-child(1) {
      //   width: 5%;
      // }
      // :nth-child(2) {
      //   width: 50%;
      // }
      // :nth-child(3) {
      //   width: 15%;
      // }
    }

    td {
      margin: 0;
      padding: 0.5rem;
      // border-bottom: 1px solid #bdbdbd;
      :first-child{
        width: 10%;
      }:nth-child(2){
        width: 55%;
        text-align: left;
      }
      :nth-child(3){
        width: 15%;
      }
      :nth-child(4){
        width: 10%;
      }
      :last-child(
        width: 5%;
      )
    }
    }
  }
`;

function ProgrammeAdmin() {
  const addProgramme = useDisclosure();
  const editProgramme = useDisclosure();
  const dialogProps = useDisclosure();
  const [data, setData] = useState({
    tableLoader: false,
    rowData: [],
    totalPages: 0,
    totalData: 0,
  });
  const [programID, setProgramId] = useState("");
  const [editData, setEditData] = useState([]);
  const [alert, setAlert] = useState({
    class: "hide",
    status: "",
    message: "",
  });
  const [err, setErr] = useState(false);
  const errorProps = useDisclosure();
  const [errorAlert, setErrorAlert] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const columns = React.useMemo(() => [
    {
      Header: "#",
      Cell: (row) => (
        <>
          <Text>{parseInt(row.row.id) + 1}</Text>
        </>
      ),
    },
    {
      Header: "NAME",
      accessor: "program_name",
    },
    {
      Header: "TRAININGS",
      accessor: "trainings_count",
      Cell: ({ value }) => {
        if (value) {
          return <Text>{value}</Text>;
        } else {
          return <Text>0</Text>;
        }
      },
    },
    {
      Header: "PARTICIPANT",
      accessor: "trainings",
      Cell: ({ value }) => {
        let totalTrainees = 0;
        for (let i = 0; i < value.length; i++) {
          totalTrainees += value[i].trainees_count;
        }
        if (value) {
          return (
            <>
              <Text>{totalTrainees}</Text>
            </>
          );
        }
      },
    },
    {
      Header: "ACTIONS",
      disableSortBy: true,
      accessor: "program_id",
      Cell: ({ cell }) => (
        <>
          <Menu>
            <MenuButton
              as={Button}
              bgColor="none"
              _hover={{ bgColor: "#ebebeb" }}
              _expanded={{ bgColor: "#ebebeb" }}
              _click={{ bgColor: "#ebebeb" }}
              variant="ghost"
              rightIcon={<BiDotsVerticalRounded boxsize={5} />}
            ></MenuButton>
            <MenuList minW="100px">
              <MenuItem
                fontSize="15px"
                icon={<EditIcon boxsize={5} color="#14804a" />}
                onClick={async () => {
                  const res = await CRUDFunction.get(
                    `${URL_STAGING}programme/${cell.value}`
                  );
                  if (res) {
                    setProgramId(cell.value);
                    setEditData(res);
                    editProgramme.onOpen();
                  }
                }}
              >
                Edit
              </MenuItem>
              <MenuItem
                fontSize="15px"
                icon={<DeleteIcon boxsize={5} color="#d12953" />}
                onClick={() => {
                  setProgramId(cell.value);
                  dialogProps.onOpen();
                }}
              >
                Delete
              </MenuItem>
            </MenuList>
          </Menu>
        </>
      ),
    },
  ]);

  useEffect(() => {
    setData((prevState) => ({
      ...prevState,
      rowData: [],
      tableLoader: true,
    }));
    fetchData(currentPage);
  }, [currentPage]);

  const fetchData = async (pageNo = 1) => {
    const res = await CRUDFunction.get(
      `${URL_STAGING}programme?page=${pageNo}`
    );
    if (res) {
      setData({
        rowData: res.data,
        tableLoader: false,
        totalPages: res.last_page,
        totalData: res.total,
      });
    }
  };

  const timer = () => {
    setTimeout(function () {
      setAlert({ ...alert, class: "hide" });
      errorProps.onClose();
    }, 5000);
  };

  const { register, handleSubmit, reset } = useForm();

  const onSubmit = async (data) => {
    if (data) {
      if (data.programName === "") {
        setErr(true);
      } else {
        setErr(false);
      }
      const formData = new FormData();
      formData.append("program_name", data.programName);

      if (addProgramme.isOpen) {
        const res = await CRUDFunction.create(
          `${URL_STAGING}programme`,
          formData
        );

        if (res.errors) {
          setErrorAlert(res.message);
          errorProps.onOpen();
          timer();
        } else {
          setData({
            rowData: res.data,
            tableLoader: false,
            totalPages: res.last_page,
            totalData: res.total,
          });
          reset();
          setAlert({
            ...alert,
            class: "show",
            status: "success",
            message: "Data is successfully created.",
          });
          timer();
          addProgramme.onClose();
        }
      } else if (editProgramme.isOpen) {
        const res = await CRUDFunction.update(
          `${URL_STAGING}programme/update/${programID}`,
          formData
        );

        if (res.errors) {
          setErrorAlert(res.message);
          errorProps.onOpen();
          timer();
        } else {
          setData({
            rowData: res.data,
            tableLoader: false,
            totalPages: res.last_page,
            totalData: res.total,
          });
          reset();
          setAlert({
            ...alert,
            class: "show",
            status: "success",
            message: "Data is successfully updated.",
          });
          timer();
          editProgramme.onClose();
        }
      }
    }
  };

  return (
    <div>
      {alert.message ? (
        <AlertPop
          width="30%"
          className={alert.class}
          status={alert.status}
          title={alert.message}
        />
      ) : (
        ""
      )}
      <Heading m="25px 0px 25px 100px">PROGRAMME</Heading>
      <Container
        maxW={"90%"}
        pt="10px"
        style={{
          backgroundColor: "white",
          borderRadius: "10px",
        }}
        py="20px"
      >
        <div style={{ position: "relative" }}>
          <Button
            leftIcon={<AddIcon />}
            pos="absolute"
            right="2"
            top="25"
            _hover={{
              bg: "green.800 !important",
            }}
            style={{
              backgroundColor: "#33945F",
              color: "#ffffff",
              padding: "14px",
              fontSize: "14px",
            }}
            width="200px"
            onClick={addProgramme.onOpen}
          >
            Add Programme
          </Button>
        </div>
        <Styles>
          <ReactTable
            columns={columns}
            data={data.rowData}
            tableLoader={data.tableLoader}
            totalRows={data.totalData}
            pageChangeHandler={setCurrentPage}
            rowsPerPage={10}
          />
        </Styles>
      </Container>
      {/* ------------------------------add programme----------------------------- */}
      <Drawer
        isOpen={addProgramme.isOpen}
        placement="right"
        onClose={() => {
          reset();
          setErr(false);
          addProgramme.onClose();
        }}
        size="sm"
      >
        <DrawerOverlay />
        <DrawerContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <DrawerCloseButton />
            <DrawerHeader textAlign="center" pt="7%" fontSize="25px">
              ADD PROGRAMME
            </DrawerHeader>

            <DrawerBody>
              <FormControl isInvalid={err} pt="20px">
                <FormLabel fontWeight="bold">
                  PROGRAMME NAME <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Input
                  placeholder="Programme name"
                  {...register("programName")}
                ></Input>
              </FormControl>
              {err && (
                <span style={{ color: "red" }}>This field is required</span>
              )}
            </DrawerBody>

            <Center>
              <DrawerFooter>
                <Button
                  fontWeight="medium"
                  w="200px"
                  color="white"
                  bg="#33945f"
                  _hover={{ bg: "green.700" }}
                  type="submit"
                >
                  Add
                </Button>
                <Button
                  fontWeight="medium"
                  w="200px"
                  variant="outline"
                  ml={3}
                  onClick={() => {
                    reset();
                    setErr(false);
                    addProgramme.onClose();
                  }}
                >
                  Cancel
                </Button>
              </DrawerFooter>
            </Center>
          </form>
        </DrawerContent>
      </Drawer>
      {/* -----------------------------edit programme----------------------------- */}
      <Drawer
        isOpen={editProgramme.isOpen}
        placement="right"
        onClose={() => {
          reset();
          setErr(false);
          editProgramme.onClose();
        }}
        size="sm"
      >
        <DrawerOverlay />
        <DrawerContent>
          <form onSubmit={handleSubmit(onSubmit)}>
            <DrawerCloseButton />
            <DrawerHeader textAlign="center" pt="7%" fontSize="25px">
              EDIT PROGRAMME
            </DrawerHeader>

            <DrawerBody>
              <FormControl isInvalid={err} pt="20px">
                <FormLabel fontWeight="bold">
                  PROGRAMME NAME <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Input
                  defaultValue={editData.program_name}
                  placeholder="Programme name"
                  {...register("programName")}
                ></Input>
              </FormControl>
              {err && (
                <span style={{ color: "red" }}>This field is required</span>
              )}
            </DrawerBody>

            <Center>
              <DrawerFooter>
                <Button
                  fontWeight="medium"
                  w="200px"
                  color="white"
                  bg="#33945f"
                  _hover={{ bg: "green.700" }}
                  type="submit"
                >
                  Edit
                </Button>
                <Button
                  fontWeight="medium"
                  w="200px"
                  variant="outline"
                  ml={3}
                  onClick={() => {
                    reset();
                    setErr(false);
                    editProgramme.onClose();
                  }}
                >
                  Cancel
                </Button>
              </DrawerFooter>
            </Center>
          </form>
        </DrawerContent>
      </Drawer>
      <ChakraAlertDialog
        title={"Delete"}
        dialogProps={dialogProps}
        url={[`${URL_STAGING}programme/${programID}`]}
        alert={alert}
        setAlert={setAlert}
        setData={setData}
        body={"Are you sure? You can't undo this action afterwards."}
        button={"Delete"}
      />
      <ErrorsDialog
        title={"Errors"}
        dialogProps={errorProps}
        body={errorAlert}
      />
    </div>
  );
}

export { ProgrammeAdmin };
