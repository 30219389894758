import {
  Box,
  Center,
  Heading,
  Text,
  VStack,
  FormControl,
  FormLabel,
  Button,
  useBoolean,
  FormErrorMessage,
  Input,
  useToast,
} from "@chakra-ui/react";
import { Select } from "chakra-react-select";
import { format } from "date-fns";
import moment from "moment/moment";
import React, { useEffect, useState } from "react";
import { useForm, useController } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import CRUDFunction from "../../../functions/CRUDFunction";
import { URL_LOCAL, URL_STAGING } from "../../../constant";

const month = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const defaultValues = { trainee: null };
const ControlledSelect = ({ control, name, id, label, rules, ...props }) => {
  const {
    field: { onChange, onBlur, value, ref },
    fieldState: { error },
  } = useController({ name, control, rules });

  return (
    <FormControl py={4} isInvalid={!!error} id={id}>
      <FormLabel>{label}</FormLabel>

      <Select
        name={name}
        ref={ref}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        {...props}
      />

      <FormErrorMessage>{error && error.message}</FormErrorMessage>
    </FormControl>
  );
};

function ClientPunchIn() {
  const toast = useToast();
  const { trainingId } = useParams();
  const [errIC, setErrIC] = useState(false);
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  const resetErr = () => {
    setErrIC(false);
  };

  useEffect(() => {
    fetchTraineeData();
  }, []);

  const fetchTraineeData = async () => {
    const res = await CRUDFunction.get(
      `${URL_STAGING}joinedtrainee/training/${trainingId}`
    );
    if (res) {
      setData(res);
    }
  };

  const { register, control, handleSubmit, reset, resetField } = useForm({
    defaultValues,
  });

  const [isLoading, setLoading] = useBoolean(false);

  const onSubmit = async (datas) => {
    if (datas) {
      if (datas.trainee_ic === "") {
        setErrIC(true);
      } else {
        setErrIC(false);
      }
      const formData = new FormData();
      formData.append("training_id", trainingId);
      formData.append("trainee_ic", datas.traineeIC);
      formData.append(
        "attendance_punchin",
        moment().format("YYYY-MM-DD HH:mm:ss")
      );
      formData.append("attendance_status", 2);

      const res = await CRUDFunction.create(`${URL_STAGING}punchin`, formData);

      if (res.message) {
        toast({
          title: "Participant not exist.",
          description:
            "We can't find your account. Please re-enter your identification number.",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      } else {
        navigate("/success", {
          replace: false,
          state: { name: data.training_name },
        });
      }
    }
  };

  return (
    <div>
      <Center>
        <Box
          boxShadow="0 4px 12px rgba(55, 81, 255, 0.3)"
          mt="100px"
          h="auto"
          w={{ base: "90%", lg: "70%" }}
          p="30px"
        >
          <Center textAlign="center">
            <VStack>
              <Heading
                p={{ md: "10px", base: "0px" }}
                fontSize={{ md: "48px", base: "32px" }}
              >
                Attendance
              </Heading>
              <Text
                fontWeight="bold"
                textTransform={"uppercase"}
                fontSize={{ md: "16px", base: "13px" }}
              >
                {data && data.training_name}
              </Text>
              <Text fontWeight="bold" fontSize={{ md: "16px", base: "13px" }}>
                {format(new Date(), "dd LLLL y")}
              </Text>
            </VStack>
          </Center>
          <br />
          <Text fontSize={{ md: "14px", base: "12px" }}>
            Please select your identification number (IC) to punch in:
          </Text>
          <br />
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormControl id="traineeIC" isInvalid={errIC} pt="20px">
              <FormLabel fontWeight="bold">
                TRAINEE IC <span style={{ color: "red" }}>*</span>
              </FormLabel>
              <Input
                placeholder="Please enter your IC (name) to punch in."
                {...register("traineeIC")}
              ></Input>
              <FormErrorMessage>{errIC?.message}</FormErrorMessage>
            </FormControl>
            <Button
              isLoading={isLoading}
              type="submit"
              display="flex"
              mt="80px"
              fontSize={{ md: "14px", base: "14px" }}
              float={{ md: "right", base: "none" }}
              w={{ md: "150px", base: "100%" }}
              bgColor="#4b85fa"
              boxShadow="xl"
              _hover={{ bgColor: "blue.300" }}
            >
              Punch In
            </Button>
          </form>
        </Box>
      </Center>
    </div>
  );
}
//-------------------punch out function--------------------------
function ClientPunchOut() {
  const toast = useToast();
  const { trainingId } = useParams();
  const [errIC, setErrIC] = useState(false);
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    fetchTraineeData();
  }, []);

  const { register, control, handleSubmit, reset } = useForm({ defaultValues });

  const fetchTraineeData = async () => {
    const res = await CRUDFunction.get(
      `${URL_STAGING}joinedtrainee/training/${trainingId}`
    );
    if (res) {
      setData(res);
    }
  };

  const onSubmit = async (datas) => {
    if (datas) {
      if (datas.trainee_ic === "") {
        setErrIC(true);
      } else {
        setErrIC(false);
      }
      const formData = new FormData();
      formData.append("training_id", trainingId);
      formData.append("trainee_ic", datas.traineeIC);
      formData.append(
        "attendance_punchout",
        moment().format("YYYY-MM-DD HH:mm:ss")
      );
      formData.append("attendance_status", 2);

      const res = await CRUDFunction.update(
        `${URL_STAGING}punchout/${datas.traineeIC}`,
        formData
      );
      if (res.message) {
        toast({
          title: "Participant not exist.",
          description:
            "We can't find your account. Please re-enter your identification number.",
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      } else {
        navigate("/success", {
          replace: false,
          state: { name: data && data.training_name },
        });
      }
    }
  };
  return (
    <div>
      <Center>
        <Box
          boxShadow="0 4px 12px rgba(55, 81, 255, 0.3)"
          mt="100px"
          h="auto"
          w={{ base: "90%", lg: "70%" }}
          p="30px"
        >
          <Center textAlign="center">
            <VStack>
              <Heading
                p={{ md: "10px", base: "0px" }}
                fontSize={{ md: "48px", base: "32px" }}
              >
                Attendance
              </Heading>
              <Text fontWeight="bold" fontSize={{ md: "16px", base: "13px" }}>
                {data && data.training_name}
              </Text>
              <Text fontWeight="bold" fontSize={{ md: "16px", base: "13px" }}>
                {format(new Date(), "dd LLLL y")}
              </Text>
            </VStack>
          </Center>
          <br />
          <Text fontSize={{ md: "14px", base: "12px" }}>
            Please select your identification number (IC) to punch out:
          </Text>
          <br />
          <form onSubmit={handleSubmit(onSubmit)}>
            <FormControl id="traineeIC" isInvalid={errIC} pt="20px">
              <FormLabel fontWeight="bold">
                TRAINEE IC <span style={{ color: "red" }}>*</span>
              </FormLabel>
              <Input
                placeholder="Please enter your IC (name) to punch in."
                {...register("traineeIC")}
              ></Input>
              <FormErrorMessage>{errIC?.message}</FormErrorMessage>
            </FormControl>
            <Button
              type="submit"
              display="flex"
              mt="80px"
              fontSize={{ md: "14px", base: "14px" }}
              float={{ md: "right", base: "none" }}
              w={{ md: "150px", base: "100%" }}
              bgColor="#f9c611"
              boxShadow="xl"
              _hover={{ bgColor: "yellow.300" }}
            >
              Punch Out
            </Button>
          </form>
        </Box>
      </Center>
    </div>
  );
}

export { ClientPunchIn, ClientPunchOut };
