import React, { useEffect, useState } from "react";
import { Route, Routes, Outlet, Navigate } from "react-router";
import { Login, Logout } from "../pages/admin/auth/Login";
import NotFoundPage from "../pages/NotFoundPage";
import Company from "../pages/client/enroll/company";
import TrainingRegistration from "../pages/client/training/register";
import { Checkout } from "../pages/client/checkout/checkout";
import { Successful } from "../pages/client/checkout/successful";
import { TrainingAdmin } from "../pages/admin/training";
import { TraineeAdmin } from "../pages/admin/trainee";
import { ProgrammeAdmin } from "../pages/admin/programme";
import { CourseAdmin } from "../pages/admin/course/index";
import CalendarAdmin from "../pages/admin/calendar";
import { LayoutAdmin } from "../pages/admin/layout";
import { LayoutClient } from "../pages/client/layout";
import CourseReg from "../pages/client/course";
import CourseDetails from "../pages/client/course/details";
import { Dashboard } from "../pages/admin/dashboard/index";
import { Individu } from "../pages/client/enroll/individu";
import SettingsAdmin from "../pages/admin/settings/settings";
import { Discount } from "../pages/admin/discount/discount";
import CompanyAdmin from "../pages/admin/company/index";
import { FeedbackAdmin } from "../pages/admin/feedback/index";
import {
  AttendanceIndividual,
  AttendanceTraining,
} from "../pages/admin/attendance/attendance";
import { ClientPunchIn, ClientPunchOut } from "../pages/client/attendance";
import { AttendanceSuccess } from "../pages/client/attendance/successful";
import { Failed } from "../pages/client/checkout/failed";
import { Copywriting } from "../pages/admin/copywriting";

const authtoken = localStorage.getItem("token");

const AppRoute = () => {
  //staging route

  //admin route
  if (window.location.hostname.includes("trainingadmin")) {
    return (
      <Routes>
        <Route path="login" element={<Login />} />
        <Route
          path=""
          element={
            authtoken === null ? <Navigate to="login" /> : <LayoutAdmin />
          }
        >
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="course" element={<CourseAdmin />} />
          <Route path="training">
            <Route path="" element={<TrainingAdmin />} />
            <Route path="trainee" element={<TraineeAdmin />}>
              <Route path="attendance">
                <Route path="individual" element={<AttendanceIndividual />} />
                <Route path="training" element={<AttendanceTraining />} />
              </Route>
            </Route>
          </Route>
          <Route path="attendance">
            <Route path="individual" element={<AttendanceIndividual />} />
            <Route path="training" element={<AttendanceTraining />} />
          </Route>
          <Route path="program" element={<ProgrammeAdmin />} />
          <Route path="calendar" element={<CalendarAdmin />} />
          <Route path="discount" element={<Discount />} />
          <Route path="company" element={<CompanyAdmin />} />
          <Route path="copywriting" element={<Copywriting />} />
          <Route path="feedback" element={<FeedbackAdmin />} />
          <Route path="settings" element={<SettingsAdmin />} />
          <Route path="logout" element={<Logout />} />
          <Route path="*" element={<NotFoundPage />} />
        </Route>
      </Routes>
    );
  }

  //client route
  if (window.location.hostname.includes("register")) {
    return (
      <Routes>
        <Route path="" element={<LayoutClient />}>
          <Route path="" element={<CourseReg />} />
          <Route path="details" element={<CourseDetails />} />
          <Route path="training/:courseId" element={<TrainingRegistration />} />
          <Route path="enroll">
            <Route path="company">
              <Route path="" element={<Company />} />
              <Route path="checkout" element={<Checkout />} />
            </Route>
            <Route path="individu">
              <Route path="" element={<Individu />} />
              <Route path="checkout" element={<Checkout />} />
            </Route>
          </Route>
          <Route path="successful" element={<Successful />} />
          <Route path="failed" element={<Failed />} />
          <Route path="punchin">
            <Route path="id/:trainingId" element={<ClientPunchIn />} />
            <Route path="success" element={<AttendanceSuccess />} />
          </Route>
          <Route path="punchout">
            <Route path="id/:trainingId" element={<ClientPunchOut />} />
            <Route path="success" element={<AttendanceSuccess />} />
          </Route>
        </Route>
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    );
  }

  //localhost routes
  // return (
  //   <Routes>
  //     {authtoken === null ? (
  //       <Route path="login" element={<Login />} />
  //     ) : (
  //       <Route path="admin" element={<LayoutAdmin />}>
  //         <Route path="dashboard" element={<Dashboard />} />
  //         <Route path="course" element={<CourseAdmin />} />
  //         <Route path="training">
  //           <Route path="" element={<TrainingAdmin />} />
  //           <Route path="trainee" element={<TraineeAdmin />}>
  //             <Route path="attendance">
  //               <Route path="individual" element={<AttendanceIndividual />} />
  //               <Route path="training" element={<AttendanceTraining />} />
  //             </Route>
  //           </Route>
  //         </Route>
  //         <Route path="attendance">
  //           <Route path="individual" element={<AttendanceIndividual />} />
  //           <Route path="training" element={<AttendanceTraining />} />
  //         </Route>
  //         <Route path="program" element={<ProgrammeAdmin />} />
  //         <Route path="calendar" element={<CalendarAdmin />} />
  //         <Route path="discount" element={<Discount />} />
  //         <Route path="company" element={<CompanyAdmin />} />
  //         <Route path="copywriting" element={<Copywriting />} />
  //         <Route path="feedback" element={<FeedbackAdmin />} />
  //         <Route path="settings" element={<SettingsAdmin />} />
  //         <Route path="logout" element={<Logout />} />
  //         <Route path="*" element={<NotFoundPage />} />
  //       </Route>
  //     )}
  //     <Route path="client" element={<LayoutClient />}>
  //       <Route path="course" element={<CourseReg />} />
  //       <Route path="details" element={<CourseDetails />} />
  //       <Route path="training/:courseId" element={<TrainingRegistration />} />
  //       <Route path="enroll">
  //         <Route path="company">
  //           <Route path="" element={<Company />} />
  //           <Route path="checkout" element={<Checkout />} />
  //         </Route>
  //         <Route path="individu">
  //           <Route path="" element={<Individu />} />
  //           <Route path="checkout" element={<Checkout />} />
  //         </Route>
  //       </Route>
  //       <Route path="successful" element={<Successful />} />
  //       <Route path="failed" element={<Failed />} />
  //       <Route path="punchin">
  //         <Route path="id/:trainingId" element={<ClientPunchIn />} />
  //         <Route path="success" element={<AttendanceSuccess />} />
  //       </Route>
  //       <Route path="punchout">
  //         <Route path="id/:trainingId" element={<ClientPunchOut />} />
  //         <Route path="success" element={<AttendanceSuccess />} />
  //       </Route>
  //     </Route>
  //     <Route path="*" element={<NotFoundPage />} />
  //   </Routes>
  // );
};

export { AppRoute };
