import {
  Heading,
  Container,
  Menu,
  MenuButton,
  Button,
  MenuList,
  MenuItem,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  FormControl,
  FormLabel,
  Input,
  useDisclosure,
  Text,
  Center,
  Stack,
  Textarea,
  Box,
  AspectRatio,
  Image,
  HStack,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { AddIcon, EditIcon, DeleteIcon } from "@chakra-ui/icons";
import { BiDotsVerticalRounded } from "react-icons/bi";
import styled from "styled-components";
import {
  ReactTable,
  AlertPop,
  ModalComponent,
  ChakraAlertDialog,
  ErrorsDialog,
  Excel,
} from "../../../components";
import { MdPreview } from "react-icons/md";
import { useForm } from "react-hook-form";
import CRUDFunction from "../../../functions/CRUDFunction";
import { URL_LOCAL, URL_STAGING } from "../../../constant";

const Styles = styled.div`
  padding: 1rem;

  table {
    min-width: 100%;
    border-spacing: 0;
    text-align: center;

    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }

    th {
      font-weight: bold;
      margin: 0;
      padding: 0.5rem;
      background-color: white;
      border-bottom: 1px solid #bdbdbd;
      :nth-child(1) {
        width: 80px;
      }
      :nth-child(2) {
        width: 350px;
      }
      :nth-child(3) {
        width: 50px;
      }
      :nth-child(4) {
        width: 100px;
      }
      :nth-child(5) {
        width: 100px;
      }
    }

    td {
      margin: 0;
      padding: 1rem;
      border-bottom: 1px solid #bdbdbd;
      text-align: left;
      :nth-child(1),
      :nth-child(4),
      :nth-child(5),
      :last-child {
        text-align: center;
      }
      :last-child {
        width: 120px;
        border-right: 0;
      }
    }
  }
`;

export default function CompanyAdmin() {
  const addCompany = useDisclosure();
  const editCompany = useDisclosure();
  const dialogProps = useDisclosure();
  const viewModal = useDisclosure();

  const [data, setData] = useState({
    tableLoader: false,
    rowData: [],
    totalPages: 0,
    totalData: 0,
  });
  const [editData, setEditData] = useState([]);
  const [viewData, setViewData] = useState(false);
  const [companyID, setCompanyID] = useState("");
  const [showAddress, setShowAddress] = useState({
    address: "",
    postcode: "",
    state: "",
  });
  const [selectedImage, setSelectedImage] = useState();
  const [editImage, setEditImage] = useState(false);
  const [alert, setAlert] = useState({
    class: "hide",
    status: "",
    message: "",
  });
  const btnRef = React.useRef();

  const [bigData, setBigData] = useState([]);
  const [excel, setExcel] = useState(false);
  const [err, setErr] = useState({
    name: false,
    ssm: false,
    address: false,
    postcode: false,
    state: false,
    picname: false,
    // picic: false,
    picemail: false,
    picposition: false,
    picphone: false,
  });
  const errorProps = useDisclosure();
  const [errorAlert, setErrorAlert] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    setData((prevState) => ({
      ...prevState,
      rowData: [],
      tableLoader: true,
    }));
    fetchData(currentPage);
  }, [currentPage]);

  const fetchData = async (pageNo = 1) => {
    const res = await CRUDFunction.get(`${URL_STAGING}company?page=${pageNo}`);
    if (res) {
      setData({
        rowData: res.data,
        tableLoader: false,
        totalPages: res.last_page,
        totalData: res.total,
      });
    }
  };

  const { register, handleSubmit, reset } = useForm();

  const timer = () => {
    setTimeout(function () {
      setAlert({ ...alert, class: "hide" });
      errorProps.onClose();
    }, 5000);
  };

  const resetErr = () => {
    setErr({
      name: false,
      ssm: false,
      address: false,
      postcode: false,
      state: false,
      picname: false,
      // picic: false,
      picemail: false,
      picposition: false,
      picphone: false,
    });
  };

  const clearErr = (e) => {
    if (e.target.value !== "") {
      if (e.target.id === "companyname") {
        setErr((err) => ({ ...err, name: false }));
      }
      if (e.target.id === "companyssm") {
        setErr((err) => ({ ...err, ssm: false }));
      }
      if (e.target.id === "companyaddress") {
        setErr((err) => ({ ...err, address: false }));
      }
      if (e.target.id === "companypostcode") {
        setErr((err) => ({ ...err, postcode: false }));
      }
      if (e.target.id === "companystate") {
        setErr((err) => ({ ...err, state: false }));
      }
      if (e.target.id === "picname") {
        setErr((err) => ({ ...err, picname: false }));
      }
      // if (e.target.id === "picic") {
      //   setErr((err) => ({ ...err, picic: false }));
      // }
      if (e.target.id === "picemail") {
        setErr((err) => ({ ...err, picemail: false }));
      }
      if (e.target.id === "picposition") {
        setErr((err) => ({ ...err, picposition: false }));
      }
      if (e.target.id === "picphone") {
        setErr((err) => ({ ...err, picphone: false }));
      }
    }
  };

  const onSubmit = async (data) => {
    if (data) {
      if (data.companyName === "") {
        setErr((err) => ({ ...err, name: true }));
      } else {
        setErr((err) => ({ ...err, name: false }));
      }

      if (data.companySSM === "") {
        setErr((err) => ({ ...err, ssm: true }));
      } else {
        setErr((err) => ({ ...err, ssm: false }));
      }

      if (data.companyAddress === "") {
        setErr((err) => ({ ...err, address: true }));
      } else {
        setErr((err) => ({ ...err, address: false }));
      }

      if (data.companyPostcode === "") {
        setErr((err) => ({ ...err, postcode: true }));
      } else {
        setErr((err) => ({ ...err, postcode: false }));
      }

      if (data.companyState === "") {
        setErr((err) => ({ ...err, state: true }));
      } else {
        setErr((err) => ({ ...err, state: false }));
      }

      if (data.companyPicname === "") {
        setErr((err) => ({ ...err, picname: true }));
      } else {
        setErr((err) => ({ ...err, picname: false }));
      }

      // if (data.companyPicic === "") {
      //   setErr((err) => ({ ...err, picic: true }));
      // } else {
      //   setErr((err) => ({ ...err, picic: false }));
      // }

      if (data.companyPicemail === "") {
        setErr((err) => ({ ...err, picemail: true }));
      } else {
        setErr((err) => ({ ...err, picemail: false }));
      }

      if (data.companyPicposition === "") {
        setErr((err) => ({ ...err, picposition: true }));
      } else {
        setErr((err) => ({ ...err, picposition: false }));
      }

      if (data.companyPicphone === "") {
        setErr((err) => ({ ...err, picphone: true }));
      } else {
        setErr((err) => ({ ...err, picphone: false }));
      }

      const formData = new FormData();
      formData.append("company_name", data.companyName);
      formData.append("company_ssm", data.companySSM);
      let combineAddress = [
        {
          address: data.companyAddress,
          postcode: data.companyPostcode,
          state: data.companyState,
        },
      ];
      formData.append("company_address", JSON.stringify(combineAddress));
      formData.append("company_picname", data.companyPicname);
      formData.append("company_picphone", data.companyPicphone.toString());
      // formData.append("company_picic", data.companyPicic.toString());
      formData.append("company_picemail", data.companyPicemail);
      formData.append("company_picposition", data.companyPicposition);
      if (data.companyFinanceEmail !== "") {
        formData.append("company_finance_email", data.companyFinanceEmail);
      } else {
        formData.append("company_finance_email", "-");
      }

      if (data.companyImage.length !== 0) {
        formData.append("company_image", data.companyImage[0]);
      }

      // for (var pair of formData.entries()) {
      //   console.log(pair[0] + ", " + pair[1]);
      // }

      let res;
      if (addCompany.isOpen) {
        if (excel) {
          let companyName = [],
            companySSM = [],
            companyAddress = [],
            companyPostcode = [],
            companyState = [],
            picName = [],
            // picIC = [],
            picEmail = [],
            picPosition = [],
            picPhoneNo = [],
            financeEmail = [];
          combineAddress = [];

          for (let i = 0; i < bigData.length; i++) {
            companyName.push(bigData[i]["COMPANY NAME"]);
            companySSM.push(bigData[i]["COMPANY SSM"]);
            companyAddress.push(bigData[i]["COMPANY ADDRESS"]);
            companyPostcode.push(bigData[i]["POSTCODE"]);
            companyState.push(bigData[i]["STATE"]);
            picName.push(bigData[i]["PIC NAME"]);
            // picIC.push(bigData[i]["PIC IC"]);
            picEmail.push(bigData[i]["PIC EMAIL"]);
            picPosition.push(bigData[i]["PIC POSITION"]);
            picPhoneNo.push(bigData[i]["PIC PHONE NUMBER"]);
            financeEmail.push(bigData[i]["FINANCE EMAIL"]);
          }
          for (let i = 0; i < companyAddress.length; i++) {
            combineAddress[i] = JSON.stringify([
              {
                address: companyAddress[i],
                postcode: companyPostcode[i],
                state: companyState[i],
              },
            ]);
          }
          const formData = new FormData();
          formData.append("company_name", JSON.stringify(companyName));
          formData.append("company_ssm", JSON.stringify(companySSM));
          formData.append("company_address", JSON.stringify(combineAddress));
          formData.append("company_picname", JSON.stringify(picName));
          formData.append("company_picphone", JSON.stringify(picPhoneNo));
          // formData.append("company_picic", JSON.stringify(picIC));
          formData.append("company_picemail", JSON.stringify(picEmail));
          formData.append("company_picposition", JSON.stringify(picPosition));
          formData.append(
            "company_finance_email",
            JSON.stringify(financeEmail)
          );

          res = await CRUDFunction.create(
            `${URL_STAGING}companyExcel`,
            formData
          );
        } else {
          res = await CRUDFunction.create(`${URL_STAGING}company`, formData);
        }

        if (res.errors) {
          setErrorAlert(res.message);
          errorProps.onOpen();
          timer();
        } else {
          removeSelectedImage();
          setExcel(false);
          setData({
            rowData: res.data,
            tableLoader: false,
            totalPages: res.last_page,
            totalData: res.total,
          });
          reset();
          resetErr();
          setAlert({
            ...alert,
            class: "show",
            status: "success",
            message: "Data is successfully created.",
          });
          timer();
          addCompany.onClose();
        }
      } else if (editCompany.isOpen) {
        const res = await CRUDFunction.update(
          `${URL_STAGING}company/update/${companyID}`,
          formData
        );

        if (res.errors) {
          setErrorAlert(res.message);
          errorProps.onOpen();
          timer();
        } else {
          removeSelectedImage();
          setData({
            rowData: res.data,
            tableLoader: false,
            totalPages: res.last_page,
            totalData: res.total,
          });
          reset();
          resetErr();
          setEditImage(false);
          setAlert({
            ...alert,
            class: "show",
            status: "success",
            message: "Data is successfully updated.",
          });
          timer();
          editCompany.onClose();
        }
      }
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "#",
        Cell: (row) => (
          <>
            <Text>{parseInt(row.row.id) + 1}</Text>
          </>
        ),
      },
      {
        Header: "COMPANY",
        accessor: (data) => {
          return {
            name: data.company_name,
            ssm: data.company_ssm,
          };
        },
        Cell: ({ value }) => {
          return (
            <ul style={{ listStyleType: "none" }}>
              <li style={{ fontWeight: "700" }}>{value.name}</li>
              <li>{value.ssm}</li>
            </ul>
          );
        },
      },

      {
        Header: "PIC INFORMATION",
        accessor: (data) => {
          return {
            name: data.company_picname,
            phone: data.company_picphone,
            email: data.company_picemail,
          };
        },
        Cell: ({ value }) => {
          return (
            <ul style={{ listStyleType: "none" }}>
              <li style={{ textDecoration: "underline" }}>{value.name}</li>
              <li style={{ fontStyle: "italic" }}>{value.phone}</li>
              <li>{value.email}</li>
            </ul>
          );
        },
      },
      {
        Header: "TOTAL PARTICIPANTS",
        accessor: "trainees.length",
      },
      {
        Header: "ACTIONS",
        accessor: "company_id",
        disableSortBy: true,
        Cell: ({ cell }) => (
          <>
            <Menu>
              <MenuButton
                as={Button}
                bgColor="white"
                align="center"
                rightIcon={<BiDotsVerticalRounded boxsize={5} />}
              ></MenuButton>
              <MenuList minW="100px">
                <MenuItem
                  fontSize="15px"
                  icon={<EditIcon boxsize={5} color="#14804a" />}
                  onClick={async () => {
                    const res = await CRUDFunction.get(
                      `${URL_STAGING}company/${cell.value}`
                    );
                    if (res) {
                      setEditData(res);
                      setShowAddress(JSON.parse(res.company_address)[0]);
                      setCompanyID(cell.value);
                      editCompany.onOpen();
                    }
                  }}
                >
                  Edit
                </MenuItem>
                <MenuItem
                  fontSize="15px"
                  icon={<MdPreview fontSize="20px" />}
                  onClick={async () => {
                    const res = await CRUDFunction.get(
                      `${URL_STAGING}company/${cell.value}`
                    );
                    if (res) {
                      setViewData(res);
                      setCompanyID(cell.value);
                      viewModal.onOpen();
                    }
                  }}
                >
                  View
                </MenuItem>
                <MenuItem
                  fontSize="15px"
                  icon={<DeleteIcon boxSize={5} color="#d12953" />}
                  onClick={() => {
                    setCompanyID(cell.value);
                    dialogProps.onOpen();
                  }}
                >
                  Delete
                </MenuItem>
              </MenuList>
            </Menu>
          </>
        ),
      },
    ],
    []
  );

  const imageChange = (e) => {
    if (editCompany.isOpen) {
      setEditImage(true);
    }

    if (e.target.files && e.target.files.length > 0) {
      setSelectedImage(e.target.files[0]);
    }
  };

  const removeSelectedImage = () => {
    setSelectedImage();
  };

  return (
    <div>
      {/* -----------------------modal view-------------------------------- */}
      <ModalComponent
        trigger={viewModal}
        modalHeader="Company Details"
        viewData={viewData}
        viewName={[
          "company_name",
          "company_ssm",
          "company_address",
          "company_finance_email",
          "company_picname",
          "company_picphone",
          "company_picemail",
          "company_picposition",
        ]}
      />
      {/* --------------------------modal view end-------------------------- */}

      {/* -----------------add company drawer------------------- */}
      <Drawer
        isOpen={addCompany.isOpen}
        placement="center"
        onClose={() => {
          removeSelectedImage();
          setEditImage(false);
          reset();
          resetErr();
          addCompany.onClose();
        }}
        finalFocusRef={btnRef}
        size="md"
        style={{ overflowY: "auto" }}
      >
        <DrawerOverlay />
        <DrawerContent overflowY="auto">
          <form onSubmit={handleSubmit(onSubmit)}>
            <DrawerCloseButton />
            <Stack direction={"column"} align="center">
              <DrawerHeader textAlign="center" pt="7%" fontSize="25px">
                ADD COMPANY
              </DrawerHeader>
              <b>UPLOAD EXCEL FILE</b>
              <Excel handleBigData={setBigData} handleExcel={setExcel} />
            </Stack>

            <DrawerBody>
              {excel === false ? (
                <>
                  <Text align="center" fontWeight="bold">
                    OR
                  </Text>
                  <b>Upload Company Image</b>
                  <Container>
                    <AspectRatio ratio={4 / 3}>
                      <Box
                        bgColor="gray.100"
                        borderColor="gray.300"
                        borderStyle="dashed"
                        borderWidth="2px"
                        rounded="md"
                        role="group"
                      >
                        <Stack
                          display="flex"
                          alignItems="center"
                          justify="center"
                          mb="20px"
                        >
                          {selectedImage && (
                            <div
                              style={{
                                mt: "50",
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <Image
                                height="400px"
                                width="500px"
                                src={URL.createObjectURL(selectedImage)}
                                style={{ maxWidth: "100%", maxHeight: "30%" }}
                                alt="Thumb"
                              />
                            </div>
                          )}
                        </Stack>
                      </Box>
                    </AspectRatio>
                    <br />
                    <input
                      type={"file"}
                      class="custom-image-input"
                      accept={"image/*"}
                      {...register("companyImage")}
                      onChange={imageChange}
                    />
                  </Container>

                  <FormControl isInvalid={err.name} pt="20px">
                    <FormLabel fontWeight="bold">
                      Company Name <span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Input
                      id="companyname"
                      placeholder="Company Name"
                      {...register("companyName")}
                      onBlur={clearErr}
                    />
                  </FormControl>
                  {err.name && (
                    <span style={{ color: "red" }}>This field is required</span>
                  )}
                  <FormControl isInvalid={err.ssm} pt="20px">
                    <FormLabel fontWeight="bold">
                      Company SSM <span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Input
                      id="companyssm"
                      placeholder="Company SSM"
                      {...register("companySSM")}
                      onBlur={clearErr}
                    />
                  </FormControl>
                  {err.ssm && (
                    <span style={{ color: "red" }}>This field is required</span>
                  )}
                  <FormControl isInvalid={err.address} pt="20px">
                    <FormLabel fontWeight="bold">
                      Company Address <span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Textarea
                      resize="none"
                      h="120px"
                      id="companyaddress"
                      placeholder="Company Address"
                      {...register("companyAddress")}
                      onBlur={clearErr}
                    />
                  </FormControl>
                  {err.address && (
                    <span style={{ color: "red" }}>This field is required</span>
                  )}
                  <HStack>
                    <Box w="30%">
                      <FormControl isInvalid={err.postcode} pt="20px">
                        <FormLabel fontWeight="bold">
                          Postcode <span style={{ color: "red" }}>*</span>
                        </FormLabel>
                        <Input
                          id="companypostcode"
                          type="number"
                          placeholder="Postcode"
                          {...register("companyPostcode")}
                          onBlur={clearErr}
                        />
                      </FormControl>
                      {err.postcode && (
                        <span style={{ color: "red" }}>Required</span>
                      )}
                    </Box>
                    <Box w="70%">
                      <FormControl isInvalid={err.state} pt="20px">
                        <FormLabel fontWeight="bold">
                          State <span style={{ color: "red" }}>*</span>
                        </FormLabel>
                        <Input
                          id="companystate"
                          placeholder="State"
                          {...register("companyState")}
                          onBlur={clearErr}
                        />
                      </FormControl>
                      {err.state && (
                        <span style={{ color: "red" }}>
                          This field is required
                        </span>
                      )}
                    </Box>
                  </HStack>
                  <FormControl pt="20px">
                    <FormLabel fontWeight="bold">Finance Email</FormLabel>
                    <Input
                      id="finance_email"
                      type="email"
                      placeholder="Finance Email"
                      {...register("companyFinanceEmail")}
                    />
                  </FormControl>
                  <FormControl isInvalid={err.picname} pt="20px">
                    <FormLabel fontWeight="bold">
                      PIC Name <span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Input
                      id="picname"
                      type="text"
                      placeholder="PIC Name"
                      {...register("companyPicname")}
                      onBlur={clearErr}
                    />
                  </FormControl>
                  {err.picname && (
                    <span style={{ color: "red" }}>This field is required</span>
                  )}

                  <FormControl isInvalid={err.picemail} pt="20px">
                    <FormLabel fontWeight="bold">
                      PIC Email <span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Input
                      id="picemail"
                      type="email"
                      placeholder="PIC Email"
                      {...register("companyPicemail")}
                      onBlur={clearErr}
                    />
                  </FormControl>
                  {err.picemail && (
                    <span style={{ color: "red" }}>This field is required</span>
                  )}
                  <FormControl isInvalid={err.picposition} pt="20px">
                    <FormLabel fontWeight="bold">
                      PIC Position <span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Input
                      id="picposition"
                      type="text"
                      placeholder="PIC Position"
                      {...register("companyPicposition")}
                      onBlur={clearErr}
                    />
                  </FormControl>
                  {err.picposition && (
                    <span style={{ color: "red" }}>This field is required</span>
                  )}
                  <FormControl isInvalid={err.picphone} pt="20px">
                    <FormLabel fontWeight="bold">
                      PIC Phone Number <span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Input
                      id="picphone"
                      type="number"
                      placeholder="PIC Phone Number"
                      {...register("companyPicphone")}
                      onBlur={clearErr}
                    />
                  </FormControl>
                  {err.picphone && (
                    <span style={{ color: "red" }}>This field is required</span>
                  )}
                </>
              ) : (
                ""
              )}
            </DrawerBody>

            <Center>
              <DrawerFooter>
                <Button
                  fontWeight="medium"
                  w="200px"
                  color="white"
                  bg="#33945f"
                  _hover={{ bg: "green.700" }}
                  type="submit"
                >
                  Add
                </Button>
                <Button
                  fontWeight="medium"
                  w="200px"
                  variant="outline"
                  ml={3}
                  onClick={() => {
                    removeSelectedImage();
                    reset();
                    resetErr();
                    addCompany.onClose();
                  }}
                >
                  Cancel
                </Button>
              </DrawerFooter>
            </Center>
          </form>
        </DrawerContent>
      </Drawer>
      {/* -----------------add company drawer end------------------- */}

      {/* -----------------edit company drawer------------------- */}
      <Drawer
        isOpen={editCompany.isOpen}
        placement="center"
        onClose={() => {
          setEditImage(false);
          removeSelectedImage();
          reset();
          resetErr();
          editCompany.onClose();
        }}
        finalFocusRef={btnRef}
        size="md"
      >
        <DrawerOverlay />
        <DrawerContent overflowY="auto">
          <form onSubmit={handleSubmit(onSubmit)}>
            <DrawerCloseButton />
            <DrawerHeader textAlign="center" pt="7%" fontSize="25px">
              EDIT COMPANY
            </DrawerHeader>
            <DrawerBody>
              <Container my="10px">
                <Text>Current Company Image</Text>
                <AspectRatio ratio={4 / 3}>
                  <Box
                    bgColor="gray.100"
                    borderColor="gray.300"
                    borderStyle="dashed"
                    borderWidth="2px"
                    rounded="md"
                    role="group"
                  >
                    <Stack display="flex" alignItems="center" justify="center">
                      <Image
                        height="400px"
                        width="500px"
                        src={
                          editImage
                            ? URL.createObjectURL(selectedImage)
                            : `http://localhost:8000/uploads/${editData.company_image}`
                        }
                      />
                    </Stack>
                  </Box>
                </AspectRatio>
              </Container>
              <HStack>
                <Box w="60%" align="left">
                  <Text mt="10px" color="green">
                    &nbsp;&nbsp;&nbsp;&nbsp;Upload new company picture:
                  </Text>
                  &nbsp;&nbsp;&nbsp;&nbsp;
                  <input
                    type={"file"}
                    class="custom-image-input"
                    accept={"image/*"}
                    {...register("companyImage")}
                    onChange={imageChange}
                  />
                </Box>
                {editData.company_image ? (
                  <Box w="40%" pb="35px">
                    <Popover size="sm">
                      <PopoverTrigger>
                        <Text
                          mt="10px"
                          color="red"
                          _hover={{ cursor: "pointer" }}
                        >
                          Remove company image
                        </Text>
                      </PopoverTrigger>
                      <PopoverContent p="5px" textAlign="center">
                        <PopoverArrow />
                        <PopoverCloseButton />
                        <PopoverHeader>Confirmation!</PopoverHeader>
                        <PopoverBody>
                          Are you sure? You can't undo this action afterwards.
                        </PopoverBody>
                        <Center>
                          <Button
                            w="50%"
                            bgColor="red"
                            _hover={{ bgColor: "red.500" }}
                            color="white"
                            size="sm"
                            onClick={() => {
                              setEditData({
                                ...editData,
                                company_image: false,
                              });
                              setSelectedImage("");
                            }}
                          >
                            Remove
                          </Button>
                        </Center>
                      </PopoverContent>
                    </Popover>
                  </Box>
                ) : (
                  ""
                )}
              </HStack>

              <FormControl isInvalid={err.name} mt="20px">
                <FormLabel fontWeight="bold">
                  Company Name <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Input
                  id="subject"
                  placeholder="Company Name"
                  defaultValue={editData.company_name}
                  {...register("companyName")}
                />
              </FormControl>
              {err.name && (
                <span style={{ color: "red" }}>This field is required</span>
              )}
              <FormControl isInvalid={err.ssm} mt="20px">
                <FormLabel fontWeight="bold">
                  Company SSM <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Input
                  id="subject"
                  placeholder="Company SSM"
                  defaultValue={editData.company_ssm}
                  {...register("companySSM")}
                />
              </FormControl>
              {err.ssm && (
                <span style={{ color: "red" }}>This field is required</span>
              )}
              <FormControl isInvalid={err.address} mt="20px">
                <FormLabel fontWeight="bold">
                  Company Address <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Textarea
                  resize="none"
                  h="120px"
                  id="company_address"
                  placeholder="Company Address"
                  defaultValue={showAddress.address}
                  {...register("companyAddress")}
                />
              </FormControl>
              {err.address && (
                <span style={{ color: "red" }}>This field is required</span>
              )}
              <HStack>
                <Box w="30%">
                  <FormControl isInvalid={err.postcode} mt="20px">
                    <FormLabel fontWeight="bold">
                      Postcode <span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Input
                      id="companyPostcode"
                      placeholder="Postcode"
                      defaultValue={showAddress.postcode}
                      {...register("companyPostcode")}
                    />
                  </FormControl>
                  {err.postcode && (
                    <span style={{ color: "red" }}>This field is required</span>
                  )}
                </Box>
                <Box w="70%">
                  <FormControl isInvalid={err.state} mt="20px">
                    <FormLabel fontWeight="bold">
                      State <span style={{ color: "red" }}>*</span>
                    </FormLabel>
                    <Input
                      id="companyState"
                      placeholder="State"
                      defaultValue={showAddress.state}
                      {...register("companyState")}
                    />
                  </FormControl>
                  {err.state && (
                    <span style={{ color: "red" }}>This field is required</span>
                  )}
                </Box>
              </HStack>
              <FormControl pt="20px">
                <FormLabel fontWeight="bold">Finance Email</FormLabel>
                <Input
                  id="finance_email"
                  type="email"
                  placeholder="Finance Email"
                  defaultValue={editData.company_finance_email}
                  {...register("companyFinanceEmail")}
                />
              </FormControl>
              <FormControl isInvalid={err.picname} mt="20px">
                <FormLabel fontWeight="bold">
                  PIC Name <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Input
                  id="pic_name"
                  type="text"
                  placeholder="PIC Name"
                  defaultValue={editData.company_picname}
                  {...register("companyPicname")}
                />
              </FormControl>
              {err.picname && (
                <span style={{ color: "red" }}>This field is required</span>
              )}
              <FormControl isInvalid={err.picemail} mt="20px">
                <FormLabel fontWeight="bold">
                  PIC Email <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Input
                  id="pic_email"
                  type="text"
                  placeholder="PIC Email"
                  defaultValue={editData.company_picemail}
                  {...register("companyPicemail")}
                />
              </FormControl>
              {err.picemail && (
                <span style={{ color: "red" }}>This field is required</span>
              )}
              <FormControl isInvalid={err.picposition} mt="20px">
                <FormLabel fontWeight="bold">
                  PIC Position <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Input
                  id="pic_position"
                  type="text"
                  placeholder="PIC Position"
                  defaultValue={editData.company_picposition}
                  {...register("companyPicposition")}
                />
              </FormControl>
              {err.picposition && (
                <span style={{ color: "red" }}>This field is required</span>
              )}
              <FormControl isInvalid={err.picphone} mt="20px">
                <FormLabel fontWeight="bold">
                  PIC Phone Number <span style={{ color: "red" }}>*</span>
                </FormLabel>
                <Input
                  id="pic_phoneNo"
                  type="number"
                  placeholder="PIC Phone Number"
                  defaultValue={editData.company_picphone}
                  {...register("companyPicphone")}
                />
              </FormControl>
              {err.picphone && (
                <span style={{ color: "red" }}>This field is required</span>
              )}
            </DrawerBody>

            <Center>
              <DrawerFooter>
                <Button
                  fontWeight="medium"
                  w="200px"
                  color="white"
                  bg="#33945f"
                  _hover={{ bg: "green.700" }}
                  type="submit"
                >
                  Save
                </Button>
                <Button
                  fontWeight="medium"
                  w="200px"
                  variant="outline"
                  ml={3}
                  onClick={() => {
                    setEditImage(false);
                    removeSelectedImage();
                    reset();
                    resetErr();
                    editCompany.onClose();
                  }}
                >
                  Cancel
                </Button>
              </DrawerFooter>
            </Center>
          </form>
        </DrawerContent>
      </Drawer>
      <ErrorsDialog
        title={"Errors"}
        dialogProps={errorProps}
        body={errorAlert}
      />
      {/* -----------------edit company drawer end------------------- */}
      {alert.message ? (
        <AlertPop
          width="30%"
          className={alert.class}
          status={alert.status}
          title={alert.message}
        />
      ) : (
        ""
      )}
      <Container maxW="90%" mt="20px">
        <Heading>COMPANY</Heading>
      </Container>
      <Container
        maxW="90%"
        overflowY="auto"
        borderRadius={10}
        bg="white"
        m="20px auto"
        display="grid"
      >
        <div style={{ position: "relative" }}>
          <Button
            leftIcon={<AddIcon />}
            pos="absolute"
            fontSize="14px"
            right="4"
            w="200px"
            bottom="-20"
            px="6"
            bg="#33945f"
            _hover={{ bg: "green.700" }}
            color="white"
            onClick={addCompany.onOpen}
          >
            Add Company
          </Button>
        </div>
        <ChakraAlertDialog
          title={"Delete"}
          dialogProps={dialogProps}
          url={[`${URL_STAGING}company/${companyID}`]}
          setAlert={setAlert}
          alert={alert}
          setData={setData}
          body={"Are you sure? You can't undo this action afterwards."}
          button={"Delete"}
        />
        <Styles>
          <ReactTable
            columns={columns}
            data={data.rowData}
            tableLoader={data.tableLoader}
            totalRows={data.totalData}
            pageChangeHandler={setCurrentPage}
            rowsPerPage={10}
          />
        </Styles>
      </Container>
    </div>
  );
}
