import React, { useEffect, useState } from "react";
import {
  Container,
  Grid,
  GridItem,
  Box,
  Heading,
  Text,
  Divider,
  Spinner,
  Skeleton,
} from "@chakra-ui/react";
import CalendarAdmin from "../../../components/calendar/calendar";
import CRUDFunction from "../../../functions/CRUDFunction";
import { format } from "date-fns";
import { URL_LOCAL, URL_STAGING } from "../../../constant";

function Dashboard() {
  const [totalCohort, setTotalCohort] = useState([]);
  const [loader, setLoader] = useState(true);

  useEffect(() => {
    fetchTotalCohort();
  }, []);

  const fetchTotalCohort = async () => {
    const res = await CRUDFunction.get(`${URL_STAGING}dashboard`);
    let totalHPB = 0,
      totalHLSB = 0;
    if (res) {
      setLoader(false);
      setTotalCohort(res);
      res.hpb.map((item) => {
        totalHPB += item.trainees.length;
      });
      res.hlsb.map((item) => {
        totalHLSB += item.trainees.length;
      });
    }
    setTotalCohort((existVal) => ({
      ...existVal,
      totalHPB: totalHPB,
      totalHLSB: totalHLSB,
    }));
  };
  return (
    <Container maxW={"90%"} minH={"100vh"} mt="10px">
      <Heading my="6">DASHBOARD</Heading>
      <Grid
        templateRows="repeat(2, 1fr)"
        templateColumns="repeat(4, 1fr)"
        w="100%"
        gap={10}
      >
        <GridItem rowSpan={2} colSpan={2}>
          <Box m="10px 0px">
            <Heading size="md">Number of Cohort by Course</Heading>
          </Box>
          <Grid templateColumns="repeat(2, 1fr)" gap="2">
            <GridItem colspan={1}>
              <Box
                bg="white"
                p="5"
                align="center"
                borderRadius="8px"
                m="5px"
                shadow="md"
              >
                <Text fontSize="50px" fontWeight="bold" color="#33945F">
                  {loader ? <Spinner size="xl" /> : totalCohort.total_pche}
                </Text>
                <Text fontWeight="bold">PCHE</Text>
              </Box>
              <Box
                bg="white"
                p="5"
                align="center"
                borderRadius="8px"
                m="5px"
                shadow="md"
              >
                <Text fontSize="50px" fontWeight="bold" color="#33945F">
                  {loader ? <Spinner size="xl" /> : totalCohort.total_hc}
                </Text>
                <Text fontWeight="bold">Halal Competency</Text>
              </Box>
            </GridItem>
            <GridItem colSpan={1}>
              <Box
                bg="white"
                p="5"
                align="center"
                borderRadius="8px"
                m="5px"
                shadow="md"
              >
                <Text fontSize="50px" fontWeight="bold" color="#33945F">
                  {loader ? <Spinner size="xl" /> : totalCohort.total_pciha}
                </Text>
                <Text fontWeight="bold">PCIHA</Text>
              </Box>
              <Box
                bg="white"
                p="5"
                align="center"
                borderRadius="8px"
                m="5px"
                shadow="md"
              >
                <Text fontSize="50px" fontWeight="bold" color="#33945F">
                  {loader ? <Spinner size="xl" /> : totalCohort.total_ha}
                </Text>
                <Text fontWeight="bold">Halal Awareness</Text>
              </Box>
            </GridItem>
          </Grid>
          <Box m="10px 0px">
            <Heading size="md">Total Participant by Course</Heading>
          </Box>
          <Grid templateRows="repeat(2, 1fr)" gap={5}>
            <GridItem rowSpan={1}>
              <Box
                bg="white"
                p="10"
                style={{
                  boxShadow: "0px 2px 4px rgba(0,0,0,0.25)",
                  width: "100%",
                  borderRadius: "8px",
                }}
              >
                <Grid templateColumns="repeat(5, 1fr)">
                  <GridItem colSpan={1}>
                    <Heading fontSize="5xl">
                      {loader ? <Spinner /> : totalCohort.totalHPB}
                    </Heading>
                  </GridItem>
                  <GridItem colSpan={4} my="auto">
                    <Text fontSize="2xl" color="#33945F">
                      HPB Participants
                    </Text>
                  </GridItem>
                </Grid>
              </Box>
            </GridItem>
            <GridItem rowSpan={1}>
              <Box
                bg="white"
                p="10"
                style={{
                  boxShadow: "0px 2px 4px rgba(0,0,0,0.25)",
                  width: "100%",
                  borderRadius: "8px",
                }}
              >
                <Grid templateColumns="repeat(5, 1fr)">
                  <GridItem colSpan={1}>
                    <Heading fontSize="5xl">
                      {loader ? <Spinner /> : totalCohort.totalHLSB}
                    </Heading>
                  </GridItem>
                  <GridItem colSpan={4} my="auto">
                    <Text fontSize="2xl" color="#33945F">
                      HLSB Participants
                    </Text>
                  </GridItem>
                </Grid>
              </Box>
            </GridItem>
          </Grid>
          <br />
        </GridItem>
        <GridItem rowSpan={2} colSpan={2}>
          <Box m="10px 0px">
            <Heading size="md">Calendar</Heading>
            <CalendarAdmin data={totalCohort.training} label={false} />
          </Box>
        </GridItem>
      </Grid>
      <Grid>
        <GridItem>
          <Box m="10px 0px">
            <Heading size="md">Upcoming Training</Heading>
          </Box>
          <Box
            style={{
              maxHeight: "550px",
              boxShadow: "0px 2px 4px #c4c4c4",
              padding: "20px",
              borderRadius: "8px",
              overflowY: "auto",
            }}
            bg="white"
          >
            {totalCohort.upcoming_training ? (
              totalCohort.upcoming_training.map(
                (item) =>
                  new Date(
                    JSON.parse(item.training_date)[0].startDate
                  ).getTime() > new Date().getTime() && (
                    <>
                      <Box mb="5" p="2">
                        <Text fontWeight="bold">{item.training_name}</Text>
                        <Text mt="1">
                          Date:{" "}
                          {format(
                            new Date(
                              JSON.parse(item.training_date)[0].startDate
                            ),
                            "d MMM"
                          )}
                          {" - "}
                          {format(
                            new Date(JSON.parse(item.training_date)[0].endDate),
                            "d MMM y"
                          )}
                        </Text>
                        {JSON.parse(item.training_date).length > 1 && (
                          <Text ml="43px">
                            {format(
                              new Date(
                                JSON.parse(item.training_date)[1].startDate
                              ),
                              "d MMM"
                            )}
                            {" - "}
                            {format(
                              new Date(
                                JSON.parse(item.training_date)[1].endDate
                              ),
                              "d MMM y"
                            )}
                          </Text>
                        )}
                        {item.training_time !== null && (
                          <Text>
                            Time:{" "}
                            {Number(JSON.parse(item.training_time)[0][0] * 10) +
                              Number(JSON.parse(item.training_time)[0][1]) <
                            11 ? (
                              <>
                                {Number(
                                  JSON.parse(item.training_time)[0][0] * 10
                                ) +
                                  Number(JSON.parse(item.training_time)[0][1])}
                                {JSON.parse(item.training_time)[0].slice(2)} AM
                              </>
                            ) : Number(
                                JSON.parse(item.training_time)[0][0] * 10
                              ) +
                                Number(JSON.parse(item.training_time)[0][1]) >
                              12 ? (
                              <>
                                {Number(
                                  JSON.parse(item.training_time)[0][0] * 10
                                ) +
                                  Number(JSON.parse(item.training_time)[0][1]) -
                                  12}
                                {JSON.parse(item.training_time)[0].slice(2)} PM
                              </>
                            ) : (
                              <>
                                {Number(
                                  JSON.parse(item.training_time)[0][0] * 10
                                ) +
                                  Number(JSON.parse(item.training_time)[0][1])}
                                {JSON.parse(item.training_time)[0].slice(2)} PM
                              </>
                            )}{" "}
                            -{" "}
                            {Number(JSON.parse(item.training_time)[1][0] * 10) +
                              Number(JSON.parse(item.training_time)[1][1]) <
                            11 ? (
                              <>
                                {Number(
                                  JSON.parse(item.training_time)[1][0] * 10
                                ) +
                                  Number(JSON.parse(item.training_time)[1][1])}
                                {JSON.parse(item.training_time)[1].slice(2)} AM
                              </>
                            ) : Number(
                                JSON.parse(item.training_time)[1][0] * 10
                              ) +
                                Number(JSON.parse(item.training_time)[1][1]) >
                              12 ? (
                              <>
                                {Number(
                                  JSON.parse(item.training_time)[1][0] * 10
                                ) +
                                  Number(JSON.parse(item.training_time)[1][1]) -
                                  12}
                                {JSON.parse(item.training_time)[1].slice(2)} PM
                              </>
                            ) : (
                              <>
                                {Number(
                                  JSON.parse(item.training_time)[1][0] * 10
                                ) +
                                  Number(JSON.parse(item.training_time)[1][1])}
                                {JSON.parse(item.training_time)[1].slice(2)} PM
                              </>
                            )}
                          </Text>
                        )}
                      </Box>
                    </>
                  )
              )
            ) : (
              <>
                <Skeleton h="20px" w="70%" mb="2" />
                <Skeleton h="20px" w="50%" />
              </>
            )}
          </Box>
        </GridItem>
      </Grid>
    </Container>
  );
}

export { Dashboard };
