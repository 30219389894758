import {
  Box,
  Image,
  Text,
  Center,
  FormControl,
  FormLabel,
  Input,
  Heading,
  Stack,
  Button,
  Square,
  Container,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import axios from "axios";
import { URL_LOCAL, URL_STAGING } from "../../../constant";

function Login() {
  const viewModal = useDisclosure();
  const [user, setUser] = useState();
  const [nonUser, setNonUser] = useState(false);
  let nav = useNavigate();
  var errorCheck = true;

  const [error, setError] = useState({
    username: "",
    password: "",
  });

  const [formdata, setForm] = useState({
    username: "",
    password: "",
  });

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    await axios.get(`${URL_STAGING}user`).then(({ data }) => {
      setUser(data);
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (e.target.username.value === "") {
      setError((error) => {
        return { ...error, username: "Enter your username." };
      });
      errorCheck = false;
    } else {
      setError((error) => {
        return { ...error, username: "" };
      });
      errorCheck = true;
    }

    if (e.target.password.value === "") {
      setError((error) => {
        return { ...error, password: "Enter your password." };
      });
      errorCheck = false;
    } else {
      setError((error) => {
        return { ...error, password: "" };
      });
      errorCheck = true;
    }

    if (errorCheck) {
      return new Promise((resolve, reject) => {
        axios.post(`${URL_STAGING}login`, formdata).then(
          ({ data }) => {
            resolve(data);
            // localStorage.setItem("authenticated", user);
            localStorage.setItem("token", data.token);
            window.location.pathname = "dashboard";
          },
          (err) => {
            setNonUser(true);
            // reject("err");
          }
        );
      });
    }
  };

  const handleReset = () => {
    console.log("Reset Pw");
  };

  return (
    <div>
      <Container px={12} pb={12} my={"auto"} mt={"20vh"} pos="relative">
        <Square
          size={{ base: "0px", sm: "200px" }}
          bg="green.200"
          borderRadius="3xl"
          pos="absolute"
          left="1%"
          top="-7%"
          zIndex={-1}
        ></Square>
        <Square
          size={{ base: "0px", sm: "150px" }}
          borderRadius="3xl"
          borderWidth={{ base: 0, sm: 2 }}
          borderColor="green.400"
          pos="absolute"
          left="17%"
          top="-13%"
          zIndex="-1"
        ></Square>
        <Center
          maxW="lg"
          boxShadow="2xl"
          bg="white"
          mx="auto"
          mt={0}
          p={10}
          borderRadius="lg"
        >
          <Stack>
            <Heading size="lg" align="center">
              Login
            </Heading>
            <Text color="gray.400">
              Please enter your username and password.
            </Text>

            {nonUser ? (
              <Text color="red" fontSize="12px" textAlign="center">
                Username or Password is incorrect.
              </Text>
            ) : (
              ""
            )}

            <form onSubmit={handleSubmit}>
              <FormControl pt={5}>
                <FormLabel htmlFor="username">Username</FormLabel>
                <Input
                  id="username"
                  type="text"
                  placeholder="Username"
                  onChange={(e) => {
                    setForm({ ...formdata, username: e.target.value });
                  }}
                  onBlur={(event) => {
                    if (
                      event.target.id === "username" &&
                      event.target.value !== ""
                    ) {
                      setError((error) => {
                        return { ...error, username: "" };
                      });
                    }
                  }}
                />
                <FormLabel color="red">
                  {error.username ? error.username : ""}
                </FormLabel>
              </FormControl>
              <FormControl pb={10}>
                <FormLabel htmlFor="email">Password</FormLabel>
                <Input
                  id="password"
                  type="password"
                  placeholder="Password"
                  onChange={(e) => {
                    setForm({ ...formdata, password: e.target.value });
                  }}
                  onBlur={(event) => {
                    if (
                      event.target.id === "password" &&
                      event.target.value !== ""
                    ) {
                      setError((error) => {
                        return { ...error, password: "" };
                      });
                    }
                  }}
                />
                <FormLabel color="red">
                  {error.password ? error.password : ""}
                </FormLabel>
                <Text
                  float="right"
                  w="fit-content"
                  _hover={{ cursor: "pointer" }}
                  color="green"
                  onClick={handleReset}
                >
                  Reset Password?
                </Text>
              </FormControl>
              <Button
                w="100%"
                type="submit"
                className="btn"
                colorScheme="green"
                color="white"
              >
                Login
              </Button>
            </form>
          </Stack>
        </Center>
        <Square
          size={{ base: "0px", sm: "200px" }}
          bg="green.200"
          borderRadius="3xl"
          pos="absolute"
          right="1%"
          bottom="1%"
          zIndex={-1}
        ></Square>
        <Square
          size={{ base: "0px", sm: "250px" }}
          borderRadius="3xl"
          borderWidth={2}
          borderColor="green.200"
          borderStyle="dashed"
          pos="absolute"
          right="-4%"
          bottom="-4%"
          zIndex={-1}
        ></Square>
      </Container>
    </div>
  );
}

function Logout() {
  localStorage.clear();
  // console.log(localStorage.getItem("token"));
  // nav("/login");
  window.location.pathname = "";
}

export { Login, Logout };
