import {
  Box,
  Center,
  Container,
  Heading,
  Grid,
  GridItem,
  Text,
  Stack,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Radio,
  RadioGroup,
  Image,
  Button,
  Divider,
  Input,
  FormControl,
  FormLabel,
  useToast,
} from "@chakra-ui/react";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import paymentImage from "../../../assets/payment.png";
import { useLocation, useNavigate } from "react-router-dom";
import CRUDFunction from "../../../functions/CRUDFunction";
import { format } from "date-fns";
import { URL_LOCAL, URL_STAGING, CAPTCHA_SITE_KEY } from "../../../constant";
import ReCAPTCHA from "react-google-recaptcha";

function Checkout() {
  const { state } = useLocation();
  const [date, setDate] = useState();
  const [training, setTraining] = useState();
  const [course, setCourse] = useState();
  const [isAddressExist, setAddressExist] = useState(false);
  const [trainee, setTrainee] = useState();
  const [billName, setBillName] = useState();
  const [billEmail, setBillEmail] = useState();
  const [billMobile, setBillMobile] = useState();
  const [payment, setPayment] = useState("hrdc");
  const [address, setAddress] = useState([]);
  const [group, setGroup] = useState(false);
  const navigate = useNavigate();
  const captchaRef = useRef(null);
  const [errorMsg, setErrorMsg] = useState(false);

  const displayModes = [
    "Full Time Online Inhouse",
    "Full Time Physical Inhouse",
    "Part Time Online Inhouse",
    "Part Time Physical Inhouse",
    "Full Time Online",
    "Full Time Physical",
    "Part Time Online",
    "Part Time Physical",
  ];

  useEffect(() => {
    if (state.login === "company") {
      if (
        JSON.parse(state.company.company_address)[0].address === "" ||
        JSON.parse(state.company.company_address)[0].postcode === "" ||
        JSON.parse(state.company.company_address)[0].state === ""
      ) {
        setAddressExist(false);
      } else {
        setAddressExist(true);
      }
    }
    fetchCourse();
  }, []);

  const toast = useToast();

  const fetchCourse = async () => {
    const res = await CRUDFunction.get(
      `${URL_STAGING}coursedetails/${state.course_id}`
    );
    if (res) {
      setCourse(res[0]);
    }
  };

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm();

  const onPlaceOrder = async () => {
    const formData = new FormData();
    const token = captchaRef.current.getValue();
    formData.append("captcha_token", token);
    const captchaRes = await CRUDFunction.create(
      `${URL_STAGING}captcha`,
      formData
    );

    if (!captchaRes.success) {
      setErrorMsg(true);
    } else {
      setErrorMsg(false);
      captchaRef.current.reset();
      if (state.login === "company") {
        const formData = new FormData();
        if (state.total_trainee > 0) {
          let traineeName = [],
            traineeIC = [],
            traineePhone = [],
            traineeEmail = [],
            traineePosition = [],
            traineeAddress = [],
            traineePayment = [],
            traineeHrdf = [];
          for (let i = 1; i < state.participants.length; i++) {
            traineeName.push(state.participants[i].trainee_name);
            traineeIC.push(state.participants[i].trainee_ic);
            traineePhone.push(state.participants[i].trainee_phone);
            traineeEmail.push(state.participants[i].trainee_email);
            traineePosition.push(state.participants[i].trainee_position);
            traineeAddress.push(state.company.company_address);
            traineePayment.push("0");
            traineeHrdf.push(payment === "hrdc" ? "1" : "0");
          }
          formData.append("training_id", state.training.training_id);
          formData.append("company_name", state.company.company_name);
          formData.append("program_id", state.training.program_id);
          formData.append("trainee_name", JSON.stringify(traineeName));
          formData.append("trainee_ic", JSON.stringify(traineeIC));
          formData.append("trainee_email", JSON.stringify(traineeEmail));
          formData.append("trainee_phone", JSON.stringify(traineePhone));
          formData.append("trainee_position", JSON.stringify(traineePosition));
          formData.append("trainee_address", JSON.stringify(traineeAddress));
          formData.append("trainee_payment", JSON.stringify(traineePayment));
          formData.append("trainee_hrdf", JSON.stringify(traineeHrdf));
        }
        const res = await CRUDFunction.create(
          `${URL_STAGING}traineeCompany/${state.training.training_id}`,
          formData
        );
        if (res) {
          if (payment === "secure") {
            const data = {
              email: billEmail,
              mobile: billMobile,
              name: billName,
              amount: state.training.training_fee * 100 * state.total_trainee,
              collection_id: "ox1shh_a",
              redirect_url: "http://localhost:3000/successful",
              callback_url: "http://localhost:3000/failed",
              description:
                "Payment for " +
                state.training.training_name +
                " " +
                course.course_name,
            };
            const response = await CRUDFunction.createBill(
              `${URL_STAGING}bill`,
              data
            );
            if (response) {
              window.location.href = response.data.url;
            }
          } else if (payment === "HLSB") {
            navigate("/successful", {
              replace: false,
            });
          } else {
            navigate("/successful", {
              replace: false,
            });
          }
        } else {
          navigate("/failed", {
            replace: false,
          });
        }
      } else if (state.login === "individu") {
        const formData = new FormData();
        formData.append("training_id", state.training_id);
        formData.append("program_id", state.training.program_id);
        formData.append("company_id", state.company.company_id);
        formData.append("trainee_name", state.participants.trainee_name);
        formData.append("trainee_email", state.participants.trainee_email);
        formData.append("trainee_ic", state.participants.trainee_ic);
        formData.append("trainee_phone", state.participants.trainee_phone);
        formData.append("trainee_position", "Self-Employed");
        formData.append("trainee_payment", 0);
        formData.append("trainee_hrdf", payment === "hrdc" ? "1" : "0");
        formData.append("trainee_address", state.participants.trainee_address);
        formData.append(
          "trainee_allergies",
          state.participants.trainee_allergies
        );
        formData.append(
          "trainee_promo",
          state.participants.trainee_promo.toUpperCase()
        );
        if (state.discount) {
          formData.append("disc_id", state.discount.disc_id);
        }
        if (state.update_address) {
          formData.append("update_address", state.update_address);
        }

        const res = await CRUDFunction.create(
          `${URL_STAGING}traineeClient`,
          formData
        );

        if (res) {
          if (res.message) {
            toast({
              title: "Order fail to place",
              description: res.message,
              status: "error",
              duration: 9000,
              isClosable: true,
            });
          } else {
            if (payment === "secure") {
              const data = {
                email: billEmail,
                mobile: billMobile,
                name: billName,
                amount: state.training.training_fee * 100 * state.total_trainee,
                collection_id: "ox1shh_a",
                redirect_url: "http://localhost:3000/client/successful",
                callback_url: "http://localhost:3000/client/failed",
                description:
                  "Payment for " +
                  state.training.training_name +
                  " " +
                  course.course_name,
              };
              const response = await CRUDFunction.createBill(
                `${URL_STAGING}bill`,
                data
              );
              if (response) {
                window.location.href = response.data.url;
              }
            } else if (payment === "HLSB") {
              navigate("/successful", {
                replace: false,
              });
            } else {
              navigate("/successful", {
                replace: false,
              });
            }
          }
        } else {
          navigate("/failed", {
            replace: false,
          });
        }
      }
    }
  };

  const onUpdateShippingDetails = async (data) => {
    if (data) {
      if (state.login === "company") {
        const formData = new FormData();
        let combineAddress = [
          {
            address: data.address,
            postcode: data.postcode,
            state: data.state,
          },
        ];
        formData.append("company_address", JSON.stringify(combineAddress));
        formData.append("company_name", state.company.company_name);
        const res = await CRUDFunction.update(
          `${URL_STAGING}company/updateAddress`,
          formData
        );
        if (res) {
          setAddressExist(true);
          setAddress({
            address: JSON.parse(res.company_address)[0].address,
            postcode: JSON.parse(res.company_address)[0].postcode,
            state: JSON.parse(res.company_address)[0].state,
          });
        }
      }
    }
  };

  return (
    <>
      {
        <Grid mx="100px" templateColumns="repeat(6, 1fr)" gap={3}>
          <GridItem colSpan={4}>
            <Container maxW={"auto"} maxH={"auto"}>
              <Box
                minW="100%"
                style={{ boxShadow: "0px 2px 4px #c4c4c4" }}
                my="10"
              >
                <Center>
                  <Heading size={"xl"}>Checkout</Heading>
                </Center>
                <Grid templateColumns={"repeat(7,1fr)"}>
                  <GridItem colSpan="3">
                    <form onSubmit={handleSubmit(onUpdateShippingDetails)}>
                      <Box p="5">
                        <Heading size="md" mb="8px">
                          Billing Details
                        </Heading>
                        <Text fontSize="sm" style={{ fontWeight: "700" }}>
                          PIC Name:
                        </Text>
                        <Box fontSize="18px" my="10px" pl="20px">
                          {state.login === "company"
                            ? state.company.company_picname
                            : state.participants.trainee_name}
                        </Box>
                        <Text fontSize="sm" style={{ fontWeight: "700" }}>
                          Company Name:
                        </Text>
                        <Box fontSize="18px" my="10px" pl="20px">
                          {state.login === "company"
                            ? state.company.company_name
                            : state.participants.trainee_position}
                        </Box>
                      </Box>
                      <Box pl="5">
                        <Heading size="md" mb="8px">
                          Shipping Details
                        </Heading>
                        <Text fontSize="sm" style={{ fontWeight: "700" }}>
                          Certificate Post Address:
                        </Text>
                        <Box fontSize="18px">
                          {state.login === "company" ? (
                            !isAddressExist ? (
                              <FormControl>
                                <FormLabel mt="10px">
                                  <Text
                                    fontSize="sm"
                                    style={{ fontWeight: "700" }}
                                  >
                                    Address{" "}
                                    <span style={{ color: "red" }}>*</span>
                                  </Text>
                                </FormLabel>
                                <Input
                                  fontSize="14px"
                                  placeholder={
                                    state.login === "company"
                                      ? "Enter company address"
                                      : "Enter address"
                                  }
                                  {...register("address")}
                                ></Input>
                              </FormControl>
                            ) : JSON.parse(state.company.company_address)[0]
                                .address === "" ? (
                              <>
                                <Text
                                  fontSize="sm"
                                  my="10px"
                                  style={{ fontWeight: "700" }}
                                >
                                  Address:
                                </Text>
                                <Box fontSize="18px" my="10px" pl="20px">
                                  {address.address}
                                </Box>
                              </>
                            ) : (
                              <>
                                <Text
                                  fontSize="sm"
                                  my="10px"
                                  style={{ fontWeight: "700" }}
                                >
                                  Address:
                                </Text>
                                <Box fontSize="18px" my="10px" pl="20px">
                                  {
                                    JSON.parse(state.company.company_address)[0]
                                      .address
                                  }
                                </Box>
                              </>
                            )
                          ) : (
                            <>
                              <Text
                                fontSize="sm"
                                my="10px"
                                style={{ fontWeight: "700" }}
                              >
                                Address:
                              </Text>
                              <Box fontSize="18px" my="10px" pl="20px">
                                {
                                  JSON.parse(
                                    state.participants.trainee_address
                                  )[0].address
                                }
                              </Box>
                            </>
                          )}
                        </Box>
                        <Stack direction={"row"}>
                          <Box width="40%">
                            <Box fontSize="18px" my="10px">
                              {state.login === "company" ? (
                                !isAddressExist ? (
                                  <FormControl>
                                    <FormLabel mt="10px">
                                      <Text
                                        fontSize="sm"
                                        style={{ fontWeight: "700" }}
                                      >
                                        Postcode{" "}
                                        <span style={{ color: "red" }}>*</span>
                                      </Text>
                                    </FormLabel>
                                    <Input
                                      fontSize="14px"
                                      placeholder={"Enter postcode"}
                                      {...register("postcode")}
                                    ></Input>
                                  </FormControl>
                                ) : JSON.parse(state.company.company_address)[0]
                                    .postcode === "" ? (
                                  <>
                                    <Text
                                      fontSize="sm"
                                      my="10px"
                                      style={{ fontWeight: "700" }}
                                    >
                                      Postcode:
                                    </Text>
                                    <Box fontSize="18px" my="10px" pl="20px">
                                      {address.postcode}
                                    </Box>
                                  </>
                                ) : (
                                  <>
                                    <Text
                                      fontSize="sm"
                                      my="10px"
                                      style={{ fontWeight: "700" }}
                                    >
                                      Postcode:
                                    </Text>
                                    <Box fontSize="18px" my="10px" pl="20px">
                                      {
                                        JSON.parse(
                                          state.company.company_address
                                        )[0].postcode
                                      }
                                    </Box>
                                  </>
                                )
                              ) : (
                                <>
                                  <Text
                                    fontSize="sm"
                                    my="10px"
                                    style={{ fontWeight: "700" }}
                                  >
                                    Postcode:
                                  </Text>
                                  <Box fontSize="18px" my="10px" pl="20px">
                                    {
                                      JSON.parse(
                                        state.participants.trainee_address
                                      )[0].postcode
                                    }
                                  </Box>
                                </>
                              )}
                            </Box>
                          </Box>
                          <Box width={"60%"}>
                            <Box fontSize="18px" my="10px">
                              {state.login === "company" ? (
                                !isAddressExist ? (
                                  <FormControl>
                                    <FormLabel mt="10px">
                                      <Text
                                        fontSize="sm"
                                        style={{ fontWeight: "700" }}
                                      >
                                        State{" "}
                                        <span style={{ color: "red" }}>*</span>
                                      </Text>
                                    </FormLabel>
                                    <Input
                                      fontSize="14px"
                                      placeholder={"Enter state"}
                                      {...register("state")}
                                    ></Input>
                                  </FormControl>
                                ) : JSON.parse(state.company.company_address)[0]
                                    .state === "" ? (
                                  <>
                                    <Text
                                      fontSize="sm"
                                      my="10px"
                                      style={{ fontWeight: "700" }}
                                    >
                                      State:
                                    </Text>
                                    <Box fontSize="18px" my="10px" pl="20px">
                                      {address.state}
                                    </Box>
                                  </>
                                ) : (
                                  <>
                                    <Text
                                      fontSize="sm"
                                      my="10px"
                                      style={{ fontWeight: "700" }}
                                    >
                                      State:
                                    </Text>
                                    <Box fontSize="18px" my="10px" pl="20px">
                                      {
                                        JSON.parse(
                                          state.company.company_address
                                        )[0].state
                                      }
                                    </Box>
                                  </>
                                )
                              ) : (
                                <>
                                  <Text
                                    fontSize="sm"
                                    my="10px"
                                    style={{ fontWeight: "700" }}
                                  >
                                    State:
                                  </Text>
                                  <Box fontSize="18px" my="10px" pl="20px">
                                    {
                                      JSON.parse(
                                        state.participants.trainee_address
                                      )[0].state
                                    }
                                  </Box>
                                </>
                              )}
                            </Box>
                          </Box>
                        </Stack>
                        {!isAddressExist && state.login === "company" ? (
                          <Button
                            isDisabled={isAddressExist ? true : false}
                            width="100%"
                            bg="#33945f"
                            color="white"
                            _hover={{ background: "#f9c611", color: "white" }}
                            type="submit"
                          >
                            Update Shipping Details
                          </Button>
                        ) : (
                          ""
                        )}
                      </Box>
                    </form>
                  </GridItem>
                  <GridItem colSpan="4">
                    <Box p="5">
                      <Heading size="md" mb="8px">
                        Your Order
                      </Heading>
                      <Table variant="simple">
                        <Thead>
                          <Tr>
                            <Th>Product</Th>
                            <Th textAlign="center">Pax</Th>
                            <Th textAlign="center" w="140px">
                              Package
                            </Th>
                            <Th textAlign="center" w="140px">
                              Total
                            </Th>
                          </Tr>
                        </Thead>
                        <Tbody>
                          <Tr>
                            <Td>
                              {course && course.course_name}
                              {", "}
                              <Text as="b">{state.training.training_name}</Text>
                            </Td>
                            <Td isNumeric>{state.total_trainee}</Td>
                            <Td isNumeric>
                              <Stack direction="row">
                                <div
                                  style={{
                                    textDecoration:
                                      state.discount && "line-through",
                                    fontSize: state.discount && "14px",
                                  }}
                                >{`RM${state.training.training_fee}.00`}</div>
                                <div>
                                  {state.discount &&
                                    "RM" +
                                      state.training.training_fee *
                                        ((100 - state.discount.disc_percent) /
                                          100) +
                                      ".00"}
                                </div>
                              </Stack>
                            </Td>
                            <Td isNumeric>
                              RM{" "}
                              {state.discount
                                ? state.training.training_fee *
                                  ((100 - state.discount.disc_percent) / 100) *
                                  state.total_trainee
                                : state.training.training_fee *
                                  state.total_trainee}
                              .00
                            </Td>
                          </Tr>
                          <Tr>
                            <Td>Course Materials</Td>
                            <Td textColor="#33945F">Free</Td>
                          </Tr>
                        </Tbody>
                      </Table>
                      <RadioGroup defaultValue="hrdc" onChange={setPayment}>
                        <Box
                          bg={payment === "hrdc" ? "green.200" : "gray.200"}
                          borderLeft="7px solid"
                          p="4"
                        >
                          <Stack direction="row">
                            <Radio colorScheme={"green"} value="hrdc">
                              Claim via HRDCorp
                            </Radio>
                          </Stack>
                        </Box>
                        {payment === "hrdc" && (
                          <div class="animate__animated animate__fadeIn">
                            <Text p="3">
                              Payment method under Human Resources Development
                              Corporation. Please proceed with the place order
                              button, and our designated team will contact you
                              soon.
                            </Text>
                          </div>
                        )}
                        <Box
                          bg={payment === "HLSB" ? "green.200" : "gray.200"}
                          borderLeft="7px solid"
                          p="4"
                        >
                          <Stack direction="row">
                            <Radio colorScheme={"green"} value="HLSB">
                              Direct pay with Holistics Lab
                            </Radio>
                          </Stack>
                        </Box>
                        {payment === "HLSB" && (
                          <div class="animate__animated animate__fadeIn">
                            <Text p="3">
                              Please proceed with the place order button, and
                              our designated team will contact you soon.
                            </Text>
                          </div>
                        )}
                        <Box
                          bg={payment === "secure" ? "green.200" : "gray.200"}
                          borderLeft="7px solid"
                          p="4"
                        >
                          <Stack direction="row">
                            <Radio colorScheme={"green"} value="secure">
                              Secure Pay (COMING SOON)
                            </Radio>
                          </Stack>
                        </Box>
                        <br />
                        {payment === "secure" && (
                          <div class="animate__animated animate__fadeIn">
                            <Image
                              src={paymentImage}
                              width="100%"
                              border="1px solid"
                              borderRadius={"20px"}
                            />
                            <Text
                              textAlign="justify"
                              textJustify="interWord"
                              my="5"
                            >
                              Your personal data will be used to process your
                              order, support your experience throughout this
                              website, and for other purposes described in our
                              privacy policy.
                            </Text>
                          </div>
                        )}
                      </RadioGroup>
                      <Text color="red" display={!errorMsg && "none"}>
                        Captcha is required to complete before proceed to place
                        order.
                      </Text>
                      <ReCAPTCHA sitekey={CAPTCHA_SITE_KEY} ref={captchaRef} />
                      <Button
                        isDisabled={payment === "secure" ? true : false}
                        width="100%"
                        mt="5"
                        bg="#33945f"
                        color="white"
                        _hover={{ background: "#f9c611", color: "white" }}
                        onClick={onPlaceOrder}
                      >
                        Place Order
                      </Button>

                      <br />
                    </Box>
                  </GridItem>
                </Grid>
              </Box>
              <br />
            </Container>
          </GridItem>
          <GridItem colSpan={2}>
            <Container maxW={"auto"} maxH={"auto"}>
              <Box
                display="grid"
                p="10px 20px 10px 20px"
                borderRadius="10px"
                boxShadow="xl"
              >
                <br />
                <Heading size="lg" align="center">
                  Training Details
                </Heading>
                <br />

                <Divider
                  orientation="horizontal"
                  border="5px solid"
                  borderColor="#33945f"
                  mb="5"
                />
                <div style={{ padding: "1px 3px" }}>
                  <Box borderBottomWidth="2px" borderColor="gray" mb="10">
                    <b>COURSE</b>
                    <Text fontSize="14px" py="10px">
                      {course && course.course_name}
                    </Text>
                  </Box>
                  <Box borderBottomWidth="2px" borderColor="gray" mb="10">
                    <b>TRAINING NAME</b>
                    <Text fontSize="14px" py="10px">
                      {state.training.training_name}
                    </Text>
                  </Box>
                  <Box
                    borderBottomWidth="2px"
                    borderColor="gray"
                    mb="10"
                    pb="10px"
                  >
                    <b>TRAINING DATE</b>
                    {JSON.parse(state.training.training_date).map(
                      (item, index) => (
                        <>
                          {item.endDate === item.startDate ? (
                            <Text fontSize="14px">
                              {format(new Date(item.startDate), "dd LLLL y")}
                            </Text>
                          ) : (
                            <Text fontSize="14px">
                              Week {index + 1}:{" "}
                              {format(new Date(item.startDate), "dd LLLL y")} -{" "}
                              {format(new Date(item.endDate), "dd LLLL y")}
                            </Text>
                          )}
                        </>
                      )
                    )}
                  </Box>
                  <Box borderBottomWidth="2px" borderColor="gray" mb="10">
                    <b>TRAINING PLACE</b>
                    <Text fontSize="14px" py="10px">
                      {state.training.training_place}
                    </Text>
                  </Box>
                  <Box borderBottomWidth="2px" borderColor="gray" mb="10">
                    <b>TRAINING MODE</b>
                    <Text fontSize="14px" py="10px">
                      {displayModes[state.training.training_mode]}
                    </Text>
                  </Box>
                  <Box borderBottomWidth="2px" borderColor="gray" mb="10">
                    <b>TRAINING DETAILS</b>
                    <Text fontSize="14px" py="10px">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: state.training.training_detail,
                        }}
                      />
                    </Text>
                  </Box>
                  <Box borderBottomWidth="2px" borderColor="gray" mb="10">
                    <b>TRAINING FEE</b>
                    <Text fontSize="14px" py="10px">
                      RM {state.training.training_fee}
                    </Text>
                  </Box>
                </div>
              </Box>
            </Container>
          </GridItem>
        </Grid>
      }
    </>
  );
}

export { Checkout };
