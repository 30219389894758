import React from "react";
import {
  Container,
  Box,
  Heading,
  HStack,
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionIcon,
  AccordionPanel,
  Text,
  Stack,
  Grid,
  GridItem,
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import FullCalendar from "@fullcalendar/react"; // must go before plugins
import dayGridPlugin from "@fullcalendar/daygrid"; // a plugin!
import { useState } from "react";
import { format } from "date-fns";

export default function CalendarAdmin(props) {
  const modal = useDisclosure();
  const [data, setData] = useState({
    id: "",
    title: "",
    date: "",
  });

  const event =
    props.data &&
    props.data.map((item) => ({
      id: item.course_id,
      title: item.training_name,
      train_date: JSON.parse(item.training_date),
      start: JSON.parse(item.training_date)[0].startDate + "T00:09:00",
      end:
        JSON.parse(item.training_date).length === 2
          ? JSON.parse(item.training_date)[1].endDate + "T00:17:00"
          : JSON.parse(item.training_date)[0].endDate + "T00:17:00",
      color:
        item.course_id === 1
          ? "#205072"
          : item.course_id === 2
          ? "#8AC926"
          : item.course_id === 3
          ? "#FF555E"
          : item.course_id === 4
          ? "#FFCA3A"
          : "#9B26AF",
      allDay: false,
    }));

  return (
    <>
      {props.label === true && (
        <>
          <Container maxW="90%" mt="20px">
            <Heading>CALENDAR</Heading>
          </Container>
          <Container
            maxW="90%"
            bg="white"
            borderRadius={10}
            m="20px auto"
            p={8}
          >
            <Stack
              direction={{ base: "column", xl: "row" }}
              spacing="24px"
              my={0}
            >
              <Box w={{ md: "100%", xl: "65%" }}>
                <FullCalendar
                  plugins={[dayGridPlugin]}
                  forceEventDuration={true}
                  initialView="dayGridMonth"
                  weekends={true}
                  businessHours={{
                    daysOfWeek: [1, 2, 3, 4],
                    startTime: "09:00",
                    endTime: "17:00",
                  }}
                  timeZone="local"
                  displayEventTime={false}
                  events={event}
                  eventClick={function (info) {
                    let datas = info.event;
                    setData({
                      ...data,
                      id: datas.id,
                      title: datas.title,
                      date: datas.extendedProps.train_date,
                    });
                    modal.onOpen();
                  }}
                />
              </Box>
              <Box w={{ md: "100%", xl: "35%" }}>
                <Accordion defaultIndex={[0]} allowToggle>
                  <AccordionItem boxShadow="lg" borderBottomRadius={25}>
                    <h2>
                      <AccordionButton
                        _hover={{ bg: "gray.500" }}
                        bg="black"
                        color="white"
                      >
                        <Box flex="1" textAlign="left" p="5px">
                          <Text fontWeight="bold">Training Label</Text>
                        </Box>
                        <AccordionIcon />
                      </AccordionButton>
                    </h2>
                    <AccordionPanel>
                      <Grid
                        templateRows="repeat(3, 1fr)"
                        templateColumns="repeat(3, 1fr)"
                        gap={2}
                      >
                        <GridItem colSpan={1}>
                          <Box
                            bg="#205072"
                            color="white"
                            p={2}
                            borderRadius={10}
                            textAlign="center"
                          >
                            PCHE
                          </Box>
                        </GridItem>
                        <GridItem colSpan={2} my="auto">
                          <Text fontSize="12px" w="100%">
                            PROFESSIONAL CERTIFICATES FOR HALAL EXECUTIVE (PCHE)
                          </Text>
                        </GridItem>
                        <GridItem colSpan={1}>
                          <Box
                            bg="#8AC926"
                            color="white"
                            p={2}
                            borderRadius={10}
                            textAlign="center"
                          >
                            PCIHA
                          </Box>
                        </GridItem>
                        <GridItem colSpan={2} my="auto">
                          <Text fontSize="12px" w="100%">
                            PROFESSIONAL CERTIFICATES FOR INTERNAL HALAL
                            AUDITORS (PCIHA)
                          </Text>
                        </GridItem>
                        <GridItem colSpan={1}>
                          <Box
                            bg="#FF555E"
                            color="white"
                            p={2}
                            borderRadius={10}
                            textAlign="center"
                          >
                            COMPETENCY
                          </Box>
                        </GridItem>
                        <GridItem colSpan={2} my="auto">
                          <Text fontSize="12px" w="100%">
                            HALAL COMPETENCY
                          </Text>
                        </GridItem>
                        <GridItem colSpan={1}>
                          <Box
                            bg="#FFCA3A"
                            color="white"
                            p={2}
                            borderRadius={10}
                            textAlign="center"
                          >
                            AWARE
                          </Box>
                        </GridItem>
                        <GridItem colSpan={2} my="auto">
                          <Text fontSize="12px" w="100%">
                            HALAL AWARENESS
                          </Text>
                        </GridItem>
                        <GridItem colSpan={1}>
                          <Box
                            bg="#9B26AF"
                            color="white"
                            p={2}
                            borderRadius={10}
                            textAlign="center"
                          >
                            SEMINAR
                          </Box>
                        </GridItem>
                        <GridItem colSpan={2} my="auto">
                          <Text fontSize="12px" w="100%">
                            SEMINAR
                          </Text>
                        </GridItem>
                      </Grid>
                    </AccordionPanel>
                  </AccordionItem>
                </Accordion>
              </Box>
            </Stack>
          </Container>
        </>
      )}
      {props.label === false && (
        <Box bg="white" h="30%" my="10px" p="5" borderRadius={10}>
          <FullCalendar
            plugins={[dayGridPlugin]}
            forceEventDuration={true}
            initialView="dayGridMonth"
            weekends={true}
            businessHours={{
              daysOfWeek: [1, 2, 3, 4],
              startTime: "09:00",
              endTime: "17:00",
            }}
            timeZone="local"
            displayEventTime={false}
            events={event}
            eventClick={function (info) {
              let datas = info.event;
              setData({
                ...data,
                id: datas.id,
                title: datas.title,
                date: datas.extendedProps.train_date,
              });
              modal.onOpen();
            }}
          />
        </Box>
      )}
      {/* -------------------------------------modal------------------------------------ */}
      <Modal isOpen={modal.isOpen} onClose={modal.onClose} size="2xl">
        <ModalOverlay />
        <ModalContent borderRadius={10}>
          <ModalHeader
            bg={
              data.id === "1"
                ? "#205072"
                : data.id === "2"
                ? "#8AC926"
                : data.id === "3"
                ? "#FF555E"
                : data.id === "4"
                ? "#FFCA3A"
                : "#9B26AF"
            }
            color={"white"}
            borderTopRadius={10}
          >
            Training details
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody p="10">
            <Text color="gray">
              <b>NAME</b>
            </Text>
            <Text mb={10}>{data.title}</Text>
            <Text color="gray">
              <b>DATE</b>
            </Text>
            <Text>
              <b style={{ textIndent: "50px" }}>Week 1: </b>
              {data.date &&
                format(new Date(data.date[0].startDate), "dd MMMM y")}
              {data.date
                ? data.date[0].endDate === data.date[0].startDate
                  ? ""
                  : " - " + format(new Date(data.date[0].endDate), "dd MMMM y")
                : ""}
            </Text>
            {data.date.length > 1 && (
              <Text>
                <b style={{ textIndent: "50px" }}>Week 2: </b>
                {data.date &&
                  format(new Date(data.date[1].startDate), "dd MMMM y")}
                {" - "}
                {data.date &&
                  format(new Date(data.date[1].endDate), "dd MMMM y")}
              </Text>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
